import React, { useState } from "react";
import { BoldTypo } from "../../components/Typography/BoldTypo";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Text,
  TextField,
} from "@mui/material";
import { Search } from "@material-ui/icons";
import { http } from "../../services/http";
import { LightTypo } from "../../components/Typography/LightTypo";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import DP from "../../assets/images/dashboardImgs/levelsOfStudyImg.png";
function LevelOfStudy() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const navigate = useHistory();

  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    setLoading(true);
    try {
      const res = await http.get(
        "/users/dashboard/student-preferences/levelOfStudy"
      );
      console.log(res);
      setData(res.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };


  if (loading) return <CircularProgress />;

  return (
    <div>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <ArrowBackIcon
          sx={{ color: "#D72245", cursor: "pointer", fontSize: "medium" }}
          onClick={() => navigate.goBack()}
        />
        <BoldTypo style={{ color: "#D72245", marginLeft: "0.3rem" }}>
          Level of study
        </BoldTypo>
      </Box>
      {/* <Box sx={{ width: "435px" }}>
        <SearchInput getData={getData} />
      </Box> */}
      <Grid container style={{ marginTop: 10 }} spacing={2}>
        {data?.map((item, ind) => {
          return (
            <Grid item xs={6}>
              <Paper
                p={2}
                minHeight="100px"
                elevation={2}
                style={{
                  fontWeight: "bold",
                  borderRadius: "15px",
                }}
              >
                <Box
                  sx={{
                    padding: "30px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box>
                      <img
                        src={DP}
                        style={{
                          width: "120px",
                          height: "120px",
                          borderRadius: "10px",
                          boxShadow: "0px 3px 5px #273B801A",
                          objectFit: "cover",
                        }}
                        alt={item?.title}
                      />
                    </Box>
                    <Box sx={{ marginLeft: "1rem" }}>
                      <BoldTypo color="primary">
                        {item?.title}
                      </BoldTypo>

                      <Box sx={{ marginTop: "0.3rem" }}>
                        <LightTypo>Number of Students</LightTypo>
                        <BoldTypo>{item?.studentCount}</BoldTypo>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Paper>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}

export default LevelOfStudy;
