import React from "react";
import JourneyAnalyticsTable from "../../components/JourneyAnalyticsTable";

function VisaSlotBook() {
  return (
    <JourneyAnalyticsTable status={"VISA_SLOT"} title={"Visa Slot Booking"} />
  );
}

export default VisaSlotBook;
