import { Box, CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import UniversitiesContainer from "../../components/universities/UniversitiesContainer";
import ShortlistedCourses from "../../components/courses/ShortlistedCourses";
import { http } from "../../services/http";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";

export default function ShortlistedUniversitiesAndCourses() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [univId, setUnivId] = useState();
  const univ = "university";
  const userId = new URLSearchParams(window.location.search).get("userId");
  let shortlistedUnivs;

  if (data?.length) {
    shortlistedUnivs = Object.keys(data).map((univ) => {
      return {
        value: univ,
        label: data[univ],
      };
    });
  }

  console.log(shortlistedUnivs);

  useEffect(() => {
    setLoading(true);
    http
      .get(`/shortlist/user/${userId}`)
      .then((res) => {
        setData(res?.data);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <Box mt={10} textAlign="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          marginTop: "2rem",
          flexDirection: "column",
        }}
      >
        <Box sx={{ color: "red !important", marginLeft: "96rem" }}>
          <Link to={`shortlisted-universities?userId=${userId}`}>
            <Typography style={{ color: "#d3264c" }}>
              {" "}
              Edit Shortlisted Universities
            </Typography>
          </Link>
        </Box>
        <Box sx={{ flex: 1, marginTop: "1rem" }}>
          <ShortlistedCourses
            type={"viewShortlisted"}
            univId={univId || data[0]?.id}
            courseId={1}
            userId={userId}
          />
        </Box>
      </Box>
    </>
  );
}
