import React from "react";
import JourneyAnalyticsTable from "../../components/JourneyAnalyticsTable";

function MentorAssigned() {
  return (
    <JourneyAnalyticsTable
      status={"MENTOR_ASSIGNED"}
      title={"Mentor Assigned"}
    />
  );
}

export default MentorAssigned;
