import { Box, useTheme } from "@material-ui/core";
import {
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { Add, Search } from "@mui/icons-material";
import React, { useState } from "react";
import { CardsContainer } from "../container";
import SearchField from "./SearchField";
import { UniversityCard } from "./UniversityCard";
import { http } from "../../services/http";
import EmptyScreen from "../EmptyScreen";

export default function UniversitiesContainer({
  data,
  setData,
  setUnivId,
  selectedId,
}) {
  const theme = useTheme();
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const getData = async (params) => {
    setLoading(true);
    try {
      const res = await http.get("/universities", {
        params,
      });
      setData(res.data.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    getData({ search: search });
  };
  if (loading) {
    return (
      <Box mt={10} textAlign="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <CardsContainer
        title={"Universities"}
        color={theme.palette.primary.light}
        titleColor={`${theme.palette.primary.main}`}
      >
        <form className="mb-4" onSubmit={handleSearch}>
          <TextField
            fullWidth
            color="primary"
            value={search}
            onChange={({ target }) => setSearch(target.value)}
            variant="outlined"
            size="small"
            placeholder="Search"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton type="submit" size="large">
                    <Search fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </form>

        {/* <SearchField
          value={search}
          handleSearch={handleSearch}
          placeholder={"Search for a University"}
        ></SearchField> */}

        {data?.length ? (
          data?.map((item, index) => (
            <Box
              onClick={() => {
                setUnivId(item.id);
              }}
              width="100%"
            >
              <UniversityCard key={item.id} {...item} selectedId={selectedId} />
            </Box>
          ))
        ) : (
          <EmptyScreen heading={"No records found"} />
        )}
      </CardsContainer>
    </>
  );
}
