import { Box, Button, TextField } from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { login } from "../../api";
import logo from "../../assets/images/color_logo.png";

function Login() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [data, setData] = useState({
    username: "",
    password: "",
  });

  function handleSubmit(e) {
    e.preventDefault();

    login(data)
      .then((res) => {
        localStorage.setItem("access_token", `Bearer ${res.data.access_token}`);
        window.location.href = "/";
      })
      .catch((err) => {
        enqueueSnackbar("Bad Credentials,Try again", { variant: "warning" });
      });
  }

  function handleOnchange(e) {
    setData({ ...data, [e.target.name]: e.target.value });
  }

  return (
    <Box
      display="flex"
      minHeight="100vh"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        maxWidth={500}
        width="100%"
        textAlign="center"
        py={6}
        px={4}
        borderRadius={16}
        boxShadow="0px 3px 15px #273b8014"
      >
        <form onSubmit={handleSubmit}>
          <Box>
            <img src={logo} alt="" width={"150px"} />
          </Box>
          <Box mt={3}>
            <TextField
              onChange={handleOnchange}
              margin="dense"
              variant="outlined"
              label="Email"
              name="username"
              required
              fullWidth
            />
          </Box>
          <Box mt={3}>
            <TextField
              onChange={handleOnchange}
              margin="dense"
              variant="outlined"
              label="Password"
              type="password"
              name="password"
              required
              fullWidth
            />
          </Box>
          <Box mt={3}>
            <Button
              fullWidth
              type="submit"
              margin="dense"
              variant="contained"
              color="primary"
            >
              Login
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
}

export default Login;
