import {
	Box,
	Button,
	Dialog,
	Grid,
	TextField,
	Typography,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import CustomUpload from "../../components/CustomUpload";
import { http } from "../../services/http";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import CustomSelect from "../../shared/components/CustomSelect";
import { getCountries } from "../../api";
import { TOPICS } from "../../shared/constants";

function EditNews({
	open,
	setOpen,
	selectedState,
	setSelectedState,
	getAllData,
}) {
	const router = useHistory();
	const { enqueueSnackbar } = useSnackbar();

	const [countries, setCountries] = useState([]);

	useEffect(() => {
		getData();
	}, []);

	const getData = async () => {
		let resCountries = await getCountries();
		setCountries(resCountries.data);
	};

	function handleChange(e) {
		setSelectedState({
			...selectedState,
			[e.target.name]: e.target.value,
		});
	}

	function handleSubmit() {
		http
			.patch(`/common/news/${selectedState.id}`, selectedState)
			.then((res) => {
				enqueueSnackbar("News Upadted ", {
					variant: "success",
				});
				getAllData();
				setOpen(false);
				router.push("/news");
			})
			.catch((err) => {
				console.log(err);
			});
	}

	return (
		<Dialog fullWidth open={open} onClose={() => setOpen(false)}>
			<Box p={3}>
				<Typography variant='h6' color='primary'>
					News Information
				</Typography>
				<Box>
					<TextField
						onChange={handleChange}
						fullWidth
						value={selectedState?.title}
						variant='outlined'
						margin='dense'
						label='News Title'
						name='title'
					/>
				</Box>
				<Box>
					<TextField
						onChange={handleChange}
						fullWidth
						value={selectedState?.link}
						variant='outlined'
						margin='dense'
						label='News Link'
						name='link'
					/>
				</Box>
				<Box mt={1}>
					<Typography variant='h6' gutterBottom color='primary'>
						Image
					</Typography>
					<CustomUpload
						value={selectedState?.image}
						name='image'
						onChange={(v) => setSelectedState({ ...selectedState, image: v })}
					/>
				</Box>
				<Box mt={2}>
					<Typography variant='h6' color='primary'>
						Select
					</Typography>
					<Box mt={1}>
						<CustomSelect
							onChange={handleChange}
							options={TOPICS?.map((item) => ({
								label: item.name,
								value: item.name,
							}))}
							defaultValue={selectedState?.topic}
							label='Select Topic'
							name='topic'
						/>
					</Box>
					<Box mt={1}>
						<CustomSelect
							onChange={handleChange}
							defaultValue={selectedState?.country}
							options={countries?.map((item) => ({
								label: item.name,
								value: item.name,
							}))}
							label='Select Country'
							name='country'
						/>
					</Box>
				</Box>

				<Box mt={2} textAlign='right'>
					<Button
						onClick={handleSubmit}
						size='medium'
						variant='contained'
						color='secondary'>
						Submit
					</Button>
				</Box>
			</Box>
		</Dialog>
	);
}

export default EditNews;
