import {
  Box,
  FormControl,
  FormHelperText,
  InputAdornment,
  makeStyles,
  OutlinedInput,
  TextField,
} from "@material-ui/core";
import React from "react";

export default function SearchField({ handleSearch, value, placeholder }) {
  return (
    <>
      <Box
        style={{
          position: "sticky",
          top: "0",
          background: "white",
          borderTop: " 1rem solid white",
          borderLeft: " 1rem solid white",
          borderRight: "1rem solid white",
          zIndex: 5,
        }}
      >
        <TextField
          id="outlined-basic"
          placeholder={placeholder}
          variant="outlined"
          size="small"
          style={{ width: "100%" }}
          onChange={handleSearch}
          value={value}
        />
      </Box>
    </>
  );
}
