import { Box, Button, CircularProgress } from "@material-ui/core";
import MaterialTable from "material-table";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getNews, deleteNews } from "../../api";
import EditNews from "./EditNews";
function News() {
  const router = useHistory();
  let { enqueueSnackbar } = useSnackbar();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedState, setSelectedState] = useState({});

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);

    try {
      let resNews = await getNews();
      setData(resNews.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const handleEdit = (rowData) => {
    console.log(rowData);
    router.push({ pathname: `/news/add`, state: rowData });
  };

  const handleDelete = (id) => {
    let isConfirm = window.confirm("Are you sure about Deleting it?");
    if (isConfirm) {
      deleteNews(id)
        .then((res) => {
          enqueueSnackbar("News Article Deleted", { variant: "success" });
          getData();
        })
        .catch((err) => console.log(err));
    }
    return;
  };
  console.log(data);

  if (loading) return <CircularProgress />;

  return (
    <Box>
      <Box mb={2} textAlign="right">
        <Button
          onClick={() => router.push("/news/add")}
          color="secondary"
          variant="contained"
        >
          Add
        </Button>
      </Box>
      <MaterialTable
        title="Countries"
        options={{
          actionsColumnIndex: -1,
        }}
        actions={[
          {
            icon: "edit",
            onClick: (event, rowData) => {
              console.log(rowData);
              handleEdit(rowData);
            },
          },
          //   {
          //     icon: "delete",
          //     onClick: (event, rowData) => {
          //       console.log(rowData);
          //       handleDelete(rowData.id);
          //     },
          //   },
        ]}
        data={data}
        columns={[
          { title: "Title", field: "title" },
          { title: "Link", field: "link" },
          { title: "Topic", field: "topic" },
          // { title: "Country", field: "country" },
          {
            title: "Date",
            field: "createdAt",
            render: (rowData) => moment(rowData.createdAt).format("YYYY-MM-DD"),
          },
        ]}
      />
      <EditNews
        selectedState={selectedState}
        setSelectedState={setSelectedState}
        open={open}
        setOpen={setOpen}
        getAllData={getData}
      />
    </Box>
  );
}

export default News;
