import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import Search from "@material-ui/icons/Search";
import { MenuItem } from "@mui/material";
import { useEffect, useState } from "react";
import { http } from "../../services/http";
import { LightTypo } from "../Typography/LightTypo";
import { StyledFormControl } from "./components";
// import usePagination from "./usePagination";

const SelectTypeItem = ({
  open,
  setOpen,
  setState,
  type,
  typeId,
  setTypeData,
}) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState("");
  const [groupType, setGroupType] = useState("");
  const [pageOffset, setPageOffset] = useState(0);

  // const { info, hasMore, error, isLoading } = usePagination(
  //   groupType,
  //   pageOffset
  // );

  let currentOffset = 0;
  useEffect(() => {
    getCountries();
  }, []);

  useEffect(() => {
    getTypeData({});
  }, [type]);

  console.log(type);

  const getTypeData = async (params) => {
    setLoading(true);
    try {
      const res = await http.get(`/webinars/categories/${type}`, { params });
      console.log(res);
      setData(res.data);

      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const getMoreData = async (params) => {
    setLoading(true);
    try {
      const res = await http.get(`/webinars/categories/${type}`, { params });
      console.log(res);
      setData([...data, ...res?.data]);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  console.log(data);

  const getCountries = async () => {
    try {
      const res = await http.get("/webinars/categories/country");
      setCountries(res?.data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleAdd = (item) => {
    setTypeData(item);
    console.log(item);
    setState((prev) => ({ ...prev, typeId: item?.typeId }));
    setOpen(false);
  };

  const filterByCountry = (country) => {
    setCountry(country);
    getTypeData({ country });
  };

  // const fetchMoreData = (e) => {
  //   console.log("hi");
  //   currentOffset += 20;
  //   getMoreData({ offset: currentOffset, limit: 10 });
  // };
  if (loading) return <CircularProgress />;

  return (
    <Dialog open={open} maxWidth="sm" fullWidth onClose={() => setOpen(false)}>
      <DialogContent id="scrollableDiv">
        {type === "university" && (
          <>
            <LightTypo>Filter by Country</LightTypo>
            <StyledFormControl>
              <Select
                placeholder="Filter by Country"
                value={country}
                style={{ borderRadius: "0.625rem" }}
                onChange={(e) => {
                  filterByCountry(e.target.value);
                }}
              >
                {countries.map((item, ind) => (
                  <MenuItem key={ind} value={item?.name}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
              <br />
            </StyledFormControl>
          </>
        )}

        <SearchInput getTypeData={getTypeData} />
        <div
          style={{
            border: "1px solid rgba(0, 0, 0, 0.2)",
            marginTop: "10px",
            borderRadius: "10px",
            padding: "1rem",
          }}
        >
          {data?.map((item) => (
            <Box
              mb={1}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="subtitle2">{item?.name}</Typography>
              <Button
                startIcon={<Add />}
                color="primary"
                variant="outlined"
                size="small"
                onClick={() => handleAdd(item)}
              >
                Add
              </Button>
            </Box>
          ))}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default SelectTypeItem;

const SearchInput = ({ getTypeData }) => {
  const [search, setSearch] = useState("");
  const handleSearch = (e) => {
    getTypeData({ search });
  };
  return (
    <TextField
      variant="outlined"
      fullWidth
      size="small"
      value={search}
      onChange={(e) => setSearch(e.target.value)}
      placeholder="Search"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={handleSearch}
              type="submit"
              color="secondary"
              size="large"
            >
              <Search fontSize="small" />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};
