export const menu = [
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: "/images/menu.png",
    children: [],
  },
  {
    title: " User Registrations",
    path: "/registrations",
    icon: "/images/user (4).png",
    children: [],
  },
  {
    title: "Consultants",
    path: "/consultants",
    icon: "/images/chat (1).png",
    children: [],
  },

  {
    title: "Consultations",
    path: "/consultations",
    icon: "/images/headset.png",
    children: [],
  },
  {
    title: "News Updates",
    path: "/news",
    icon: "/images/newspaper-folded.png",
    children: [],
  },

  {
    title: "Webinars",
    path: "/webinars",
    icon: "/images/video.png",
    children: [],
  },

  {
    title: "Master Import",
    path: "/master-import",
    icon: "/images/database.png",
    children: [],
  },

  // {
  //   title: "Users",
  //   path: "/users",
  //   icon: <PeopleAltIcon color="primary" />,
  //   children: [],
  // },
  // {
  //   title: "Calls",
  //   path: "/calls",
  //   icon: <CallIcon color="primary" />,
  //   children: [],
  // },

  {
    title: "My Panel",
    path: "/my-panel",
    icon: "/images/menu.png",
    children: [],
  },
  {
    title: "My Registrants",
    path: "/registrations",
    icon: "/images/user (4).png",
    children: [],
  },
  {
    title: "Universities",
    path: "/universities",
    icon: "/images/graduation-cap.png",
    children: [],
  },
];
