import axios from "axios";

const ENV = process.env.NODE_ENV;

export const http = axios.create({
  baseURL:
    ENV === "development" ? "https://api.stuzee.com" : "https://api.stuzee.com",
  headers: {
    "Content-Type": "application/json",
    Authorization: localStorage.getItem("access_token"),
  },
});

const remote = "";

// ?  //"http://13.232.20.60:5000/" //
