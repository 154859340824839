import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useEffect, useState } from "react";
import Accordian from "../../../components/Accordian";
import StudentProfileLayout from "../../../Layout/StudentProfileLayout";
import { http } from "../../../services/http";
import EmptyScreen from "../../emptyScreen";
import CardContainer from "./CardContainer";

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(2),
    padding: theme.spacing(2),
    borderRadius: "1rem",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    marginTop: "1rem",
    "&:not(:last-of-type)": {
      marginBottom: theme.spacing(2),
    },
  },
  cover: {
    background: `linear-gradient(to bottom, transparent 30%, ${theme.palette.primary.main} 100% )`,
    height: 200,
    width: 200,
    color: "white",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
    borderRadius: "1rem",
  },
  squareCard: {
    height: 200,
    width: 200,
    backgroundRepeat: "no-repeat",
    borderRadius: "1rem",
    backgroundSize: "100%",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    "&:not(:last-of-type)": {
      marginBottom: theme.spacing(2),
    },
  },
}));

const initState = {
  isLoading: false,
  error: false,
  data: [],
};
const Section = () => {
  const [data, setData] = useState([]);

  const [fav, setFav] = useState([]);

  const userId = new URLSearchParams(window.location.search).get("userId");

  useEffect(() => {
    getuserFavUniversities();
    getUserFavorites();
  }, []);

  const getUserFavorites = () => {
    http.get(`/users/${userId}/favorites`).then((res) => {
      setFav(res.data);
    });
  };
  const getuserFavUniversities = () => {
    http
      .get(`/users/${userId}/favorite-universities`)
      .then((res) => {
        setData(res.data);
      })
      .catch((e) => {})
      .finally(() => {});
  };
  return (
    <StudentProfileLayout>
      <div style={{ display: "grid", gap: "2rem" }}>
        <Accordian>
          <Accordian.Header>Favorite Universities</Accordian.Header>
          <Accordian.Body>
            <Box>
              {data.length ? (
                data.map((item) => (
                  <RectangleCard data={item} key={item.id} />
                  // <SquareCard data={item} />
                ))
              ) : (
                <EmptyScreen heading={"No records found"} />
              )}
            </Box>
          </Accordian.Body>
        </Accordian>
        <CardContainer data={fav} title="Favorite Countries" type={"country"} />
        <CardContainer data={fav} title="Favorite Exams" type={"exam"} />
        <CardContainer
          data={fav}
          title="Favorite Area of Studies"
          type={"areaOfStudy"}
        />
        <CardContainer
          data={fav}
          title="Favorite Languages"
          type={"language"}
        />
      </div>
    </StudentProfileLayout>
  );
};

export default Section;

export const RectangleCard = ({ data }) => {
  const classes = useStyles();
  const {
    logoUrl: image,
    name: title,
    city,
    country,
    summer,
    winter,
    fall,
    spring,
  } = data;
  console.log(data);
  return (
    <Box className={classes.card}>
      <Box borderRadius="1rem">
        {image ? (
          <img src={image} />
        ) : (
          <Box
            height={120}
            width={120}
            bgcolor="purple"
            borderRadius="1rem"
          ></Box>
        )}
      </Box>
      <Box>
        <Typography color="primary" variant="h6">
          {title}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {city + " " + country}
        </Typography>
        {/* <Typography variant="body2" color="textSecondary">
          Intakes:{" "}
          {[
            summer && "Summer",
            winter && "Winter",
            fall && "Fall",
            spring && "Spring",
          ]
            .filter((item) => item !== null)
            .join(", ")}
        </Typography> */}
      </Box>
    </Box>
  );
};

export const SquareCard = ({ data }) => {
  const classes = useStyles();
  // console.log(data, "data");
  const { imageUrl, name, location, intakes } = data;
  return (
    <Box
      className={classes.squareCard}
      style={{
        backgroundImage: `url(${imageUrl})`,
      }}
    >
      <Box className={classes.cover}>
        <Box margin={2}>
          <Typography variant="h6">{name}</Typography>
          {/* <Typography variant="caption">{location}</Typography> */}
        </Box>
      </Box>
    </Box>
  );
};
