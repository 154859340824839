import { Box, Button, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import CustomUpload from "../../../components/CustomUpload";
import ImageWithUploadButton from "../../../components/Button/ImageWithUploadButton";
import { http } from "../../../services/http";
import { validateEmail, validateMobile } from "../../../services/validations";
import FormControl from "./components/FormControl";

const useStyles = makeStyles({
  upload: {
    margin: "0 2rem",
    height: "75%",
    background: "rgba(0, 0, 0, 0.1)",
    borderRadius: "1rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gridRowEnd: "span 5",
    alignSelf: "center",
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
  grid: {
    margin: "2rem 0",
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: "0rem 1rem",
  },
});

const initialState = {
  userType: "",
  status: "",
  registrationNumber: "",
  date: new Date(),
  mobile: "",
  password: "",
  email: "",
  fullName: "",
  image: "",
  imageUrl: "",
  id: "",
  roles: [],
  name: "",
};

const UserProfile = ({ userId }) => {
  const classes = useStyles();
  const history = useHistory();
  const match = useRouteMatch();
  const [loading, setLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();

  const [userRoles, setUserRoles] = useState({});
  const [state, setState] = useState(initialState);
  const [userRolesValues, setUserRolesValues] = useState(null);

  useEffect(() => {
    if (userId) {
      return getExistingUser();
    } else {
      setLoading(false);
    }
  }, []);

  const getExistingUser = async () => {
    try {
      const { data } = await http.get(`users/${userId}`);
      const rolesData = await http.get(`roles`);
      let tempObj = rolesData.data?.map((data) => ({
        value: data.roleName,
        label: data.roleName,
      }));
      setUserRoles(tempObj);
      setState({ ...data, fullName: data.name, roles: [] });
      if (data.roles?.length !== 0 && data.roles != undefined) {
        let tempObj = data.roles.map((data) => ({
          label: data.name,
          value: data.name,
        }));
        setUserRolesValues(tempObj);
      }
      setLoading(false);
    } catch (err) {
      enqueueSnackbar("Error Loading User Data", { variant: "error" });
    }
  };

  const handleImageChange = (value) => {
    setState((prev) => ({
      ...prev,
      image: value,
    }));
  };

  const handleRoleChange = (selected) => {
    setState((prev) => ({
      ...prev,
      roles: selected?.map((data) => {
        return data.value;
      }),
    }));
  };

  const handleSaveProfile = async (e) => {
    e.preventDefault();

    if (!validateMobile(state.mobile)) {
      enqueueSnackbar("Please enter valid mobile Number", {
        variant: "warning",
      });
    }
    if (!validateEmail(state.email)) {
      enqueueSnackbar("Please enter valid email", {
        variant: "warning",
      });
    }

    const postBody = {
      fullName: state.fullName,
      mobile: state.mobile,
      password: state.password,
      email: state.email,
      userType: state.userType,
      status: state.status,
      image: state.image,
      roles: state.roles,
      permissions: [],
    };

    if (state?.id) postBody.id = state?.id;
    try {
      if (!state.image) {
        enqueueSnackbar("Please Upload a Photo", {
          variant: "warning",
        });
        return;
      }
      const res = await http.post(`/users/admin-create-user`, {
        ...postBody,
        roles: ["STUDENT"],
      });
      history.push(`${match.url}?userId=${res.data.id}`);
      console.log({ postBody });

      if (!state.id) enqueueSnackbar("User Created", { variant: "success" });
      if (state.id) enqueueSnackbar("User Updated", { variant: "success" });
    } catch (err) {
      if (err)
        enqueueSnackbar(err.response.data.message, {
          variant: "error",
        });
    }
  };

  const handleUpdateUser = async (e) => {
    e.preventDefault();
    const postBody = {
      fullName: state.fullName,
      mobile: state.mobile,
      email: state.email,
      userType: state.userType,
      status: state.status,
      image: state.image,
      roles: state.roles,
      permissions: [],
    };
    console.log("update user", postBody);
    try {
      const res = await http.put(`/users/${state.id}`, postBody);
      history.push(`${match.url}?userId=${res.data.id}`);
      console.log({ postBody }, "postbody");
      if (state.id) enqueueSnackbar("User Updated", { variant: "success" });
    } catch (err) {
      if (err)
        enqueueSnackbar(err.response.data.message, {
          variant: "error",
        });
    }
  };

  const handleChange = (e) => {
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  if (loading) return <CircularProgress />;

  return (
    <form className={classes.grid}>
      <Box className={classes.upload}>
        {state.imageUrl ? (
          <Box width="100%">
            <ImageWithUploadButton
              name="imageUrl"
              src={state?.imageUrl}
            state={state}
            setState={setState}
            field = {"image"}
            />
          </Box>
        ) : (
          <CustomUpload
            label="Upload Photo"
            onChange={handleImageChange}
            name="imageUrl"
          />
        )}
      </Box>

      <FormControl.Select
        label="User Type"
        value={state.userType}
        name="userType"
        handleChange={handleChange}
        options={["Study", "Working", "Visit"]}
        required
      />
      <FormControl.Select
        name="status"
        value={state.status}
        handleChange={handleChange}
        label="Status"
        required
        options={["Active", "Inactive"]}
      />
      {/* <FormControl label='Registration Number' /> */}
      <FormControl.Date label="Date" value={state.date} disabled />

      <FormControl
        label="Name"
        name="fullName"
        value={state.fullName}
        handleChange={handleChange}
        required
      />
      <FormControl
        label="Mobile"
        name="mobile"
        value={state.mobile}
        handleChange={handleChange}
        required
      />
      <FormControl
        label="Email"
        name="email"
        value={state.email}
        handleChange={handleChange}
        required
      />

      {/* <Box>
        <span>User Roles</span>
        <Select
          label
          defaultValue={userRolesValues}
          isMulti
          options={userRoles}
          onChange={handleRoleChange}
          placeholder="User Roles"
        />
      </Box> */}

      {!userId && (
        <FormControl
          label="Password"
          name="password"
          value={state.password}
          handleChange={handleChange}
          required
        />
      )}

      <Box></Box>

      <Box mt={2}>
        {!userId ? (
          <Button
            variant="contained"
            color="secondary"
            onClick={handleSaveProfile}
          >
            Save
          </Button>
        ) : (
          <Button
            variant="contained"
            color="secondary"
            onClick={handleUpdateUser}
          >
            Update
          </Button>
        )}
      </Box>
      {console.log(state)}
    </form>
  );
};

export default UserProfile;
