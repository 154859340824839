import React from "react";
import JourneyAnalyticsTable from "../../components/JourneyAnalyticsTable";

function SOPandLORDocuments() {
  return (
    <JourneyAnalyticsTable
      status={"SOP_LOR_DOCS"}
      title={"SOP & LOR Documents"}
    />
  );
}

export default SOPandLORDocuments;
