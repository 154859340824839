import { Box, Button, CircularProgress } from "@material-ui/core";
import MaterialTable from "material-table";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { http } from "../../services/http";

function Countries() {
	const router = useHistory();
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setLoading(true);
		http
			.get("/countries")
			.then((res) => {
				console.log(res.data);
				setData(res.data);
				setLoading(false);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	if (loading) return <CircularProgress />;

	return (
		<Box>
			<Box mb={2} textAlign='right'>
				<Button
					onClick={() => router.push("/countries/add")}
					color='secondary'
					variant='contained'>
					Add
				</Button>
			</Box>
			<MaterialTable
				title='Countries'
				options={{
					actionsColumnIndex: -1,
				}}
				actions={[
					{
						icon: "edit",
						tooltip: "Save User",
						onClick: (event, rowData) =>
							router.push(`/countries/${rowData.slug}`),
					},
				]}
				data={data}
				columns={[{ title: "Name", field: "name" }]}
			/>
		</Box>
	);
}

export default Countries;
