import { Box, Button, CircularProgress } from '@material-ui/core'
import MaterialTable from 'material-table'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { http } from '../../services/http'

const Exams = () => {
	const history = useHistory()
	let { enqueueSnackbar } = useSnackbar()

	const [data, setData] = useState(null)
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		getData()
	}, [])

	const getData = async () => {
		setLoading(true)
		try {
			const res = await http.get('/exams')
			setData(res.data)
			setLoading(false)
		} catch (err) {
			enqueueSnackbar('Error Loading data', { variant: 'error' })
		}
	}

	const handleEdit = (rowData) => {
		if (rowData.howToRegister == null) {
			rowData = {
				...rowData, howToRegister: {
					description: "",
					steps: [""]
				}
			}
		}

		if (rowData.examPattern == null) {
			rowData = {
				...rowData, examPattern: {
					description: '',
					subjects: [
						{
							name: "",
							time: "",
							sections: []
						}
					]
				}
			}
		}

		history.push({ pathname: `/exams/form`, state: rowData })
	}
	const handleDelete = (id) => {
		let isConfirm = window.confirm('Are you sure about Deleting it?')
		isConfirm && deleteColumn(id)
		return
	}

	const deleteColumn = async (id) => {
		try {
			const res = await http.delete(`/exams/${id}`)
			if (res.status === 200) {
				enqueueSnackbar('Deleted Successfully', { variant: 'success' })
				getData()
			}
		} catch (err) {
			enqueueSnackbar('Error Deleting', { variant: 'error' })
		}
	}

	if (loading) return <CircularProgress />
	console.log(data)
	return (
		<div>
			<Box>
				<Box mb={2} textAlign='right'>
					<Button
						onClick={() => history.push('/exams/form')}
						color='secondary'
						variant='contained'
					>
						Add
					</Button>
				</Box>
				<MaterialTable
					title='Exams'
					options={{
						actionsColumnIndex: -1,
					}}
					actions={[
						{
							icon: 'edit',
							onClick: (event, rowData) => {
								console.log(rowData)
								handleEdit(rowData)
							},
						},
						{
							icon: 'delete',
							onClick: (event, rowData) => {
								console.log(rowData)
								handleDelete(rowData.id)
							},
						},
					]}
					data={data}
					columns={[
						{ title: 'Name', field: 'name' },
						{ title: 'Title', field: 'title' },
						{ title: 'Website', field: 'website' },
						{ title: 'Conducted By', field: 'conductedBy' },
						{ title: 'Validity', field: 'validity', render: (rowData) => {
							return (rowData.validity + (rowData.validity == 1 ? " Year" : " Years"))
						} },
						{ title: 'Fee', field: 'fee', render: (rowData) => {
							return("₹ " +rowData.fee);
						} },
						{ title: 'Type of Exam', field: 'typeOfExam' },
						{ title: 'Exam Duration', field: 'examDuration' },
						{ title: 'Grading Scale', field: 'gradingScale' },
					]}
				/>
			</Box>
		</div>
	)
}

export default Exams
