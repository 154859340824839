import Applied from "./applied";
import Documents from "./documents";
import MyFavourites from "./my-favorites";
import Profile from "./profile";
import Shortlisted from "./shortlisted";
import StudentJourney from "./StudentJourney";

const tabs = [
  {
    name: "Student Profile",
    path: "/profile",
    Component: Profile,
  },
  {
    name: "Student Preferences",
    path: "/favourites",
    Component: MyFavourites,
  },
  {
    name: "Student Documents",
    path: "/documents",

    Component: Documents,
  },
  // {
  //   name: "Applied Universities",
  //   path: "/applied",

  //   Component: Applied,
  // },
  // {
  //   name: "Shortlisted Universities",
  //   path: "/shortlisted",

  //   Component: Shortlisted,
  // },
  {
    name: "Student Journey",
    path: "/student-journey",

    Component: StudentJourney,
  },
  // {
  // 	name: 'Reports',
  // 	path: '/reports',

  // 	Component: Reports,
  // },
  // {
  // 	name: 'Receipts',

  // 	Component: Receipts,
  // 	path: '/receipts',
  // },
];
export default tabs;
