import React from "react";
import JourneyAnalyticsTable from "../../components/JourneyAnalyticsTable";

function UnivsApprovals() {
  return (
    <JourneyAnalyticsTable
      status={"UNIVERSITY_APPROVAL"}
      title={"University Approvals"}
    />
  );
}

export default UnivsApprovals;
