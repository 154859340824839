import {
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import CustomUpload from "../../components/CustomUpload";
import { http } from "../../services/http";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import CustomSelect from "../../shared/components/CustomSelect";
import { getCountries } from "../../api";
import { TOPICS } from "../../shared/constants";
import {
  SmallBox,
  StyledButton,
  StyledFormControl,
  WideBox,
} from "../../components/webinar/components";
import ImageUpload from "../../components/webinar/ImageUpload";

import StyledTextField from "../../components/StyledTextField";
import { LightTypo } from "../../components/Typography/LightTypo";
import { KeyboardBackspace } from "@material-ui/icons";
import SelectTypeItem from "../../components/webinar/SelectTypeItem";
import SelectGroupCard from "../../components/webinar/SelectGroupCard";
import { profileStore } from "../../Layout";

function AddNews() {
  const router = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const user = profileStore.useState((s) => s.state);
  const userId = user?.id;

  const [state, setState] = useState({
    title: "",
    images: [],
    link: "",
    description: "",
    topic: "",
    country: "",
    type: "",
    typeId: "",
  });
  const [countries, setCountries] = useState([]);
  const [imageUrl, setImageUrl] = useState("");
  const [typeData, setTypeData] = useState();
  const [open, setOpen] = useState(false);

  useEffect(
    (images) => {
      console.log("hi");
      console.log(imageUrl);
      if (imageUrl) {
        setState({ ...state, [images]: state?.images?.push(imageUrl) });
      }
    },
    [imageUrl]
  );

  const update = async (body) => {
    const postBody = {
      ...state,
    };

    delete postBody.typeValue;
    try {
      const res = await http.put(`/news/${body?.id}`, postBody);
      enqueueSnackbar("Updated Successfully", { variant: "success" });
      history.push("/news");
    } catch (err) {
      enqueueSnackbar("Error Submitting", { variant: "error" });
    }
  };

  const history = useHistory();
  const editMode = !!history.location.state;

  useEffect(() => {
    if (history.location.state) {
      setState(history.location.state);
    }
  }, []);

  useEffect(() => {
    getData();
  }, []);

  const handleGroupType = (name, value) => {
    console.log(name, value);
    setState({ ...state, [name]: value });
  };

  const getData = async () => {
    let resCountries = await getCountries();
    setCountries(resCountries.data);
  };

  function handleChange(name, value) {
    setState({ ...state, [name]: value });
    console.log(name);
  }

  function handleTopicChange(e) {
    setState({ ...state, [e.target.name]: e.target.value });
  }

  const post = async (state) => {
    if (
      !state?.type ||
      !state?.typeId ||
      !state?.title ||
      !state?.images.length ||
      !state?.link ||
      !state?.topic ||
      !state?.description
    ) {
      enqueueSnackbar("Please fill the required fields", { variant: "error" });
      return;
    }
    const postBody = {
      ...state,
      duration: 45,
    };
    delete postBody.typeValue;
    try {
      const res = await http.post(`/news/user/${userId}`, postBody);
      enqueueSnackbar("Submitted Successfully", { variant: "success" });
      history.push("/news");
    } catch (err) {
      console.log(err);

      enqueueSnackbar("Error Submitting", { variant: "error" });
    }
  };

  async function handleSubmit(e) {
    e.preventDefault();
    editMode ? update(state) : post(state);
  }
  // http
  //   .post("/common/news", state)
  //   .then((res) => {
  //     enqueueSnackbar("News added successfully.", {
  //       variant: "success",
  //     });
  //     router.push("/news");
  //   })
  //   .catch((err) => {
  //     console.log(err);
  //   });

  console.log(state);

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box style={{ display: "grid", gap: "1rem" }}>
          <Box display="flex" gridGap={20} alignItems="center">
            <IconButton onClick={() => history.goBack()}>
              <KeyboardBackspace />
            </IconButton>
            <Typography variant="h6">
              {editMode ? "Edit News" : "Add News"}
            </Typography>
          </Box>
          <SmallBox>
            <StyledTextField
              title="News Title"
              fieldName={"title"}
              value={state?.title}
              onChange={handleChange}
              required
            />
          </SmallBox>
          <WideBox>
            <StyledTextField
              title="News Description"
              fieldName={"description"}
              value={state?.description}
              onChange={handleChange}
              placeholder="Enter News Description…"
              rows={3}
              required
            />
          </WideBox>
          <SmallBox>
            <StyledTextField
              title="Enter News Link"
              fieldName={"link"}
              value={state?.link}
              onChange={handleChange}
              required
            />
          </SmallBox>
          <ImageUpload
            required={true}
            imageUrl={imageUrl}
            setImageUrl={setImageUrl}
          />
          <SmallBox>
            <Box>
              <LightTypo>
                Select Topic<span style={{ color: "red" }}>*</span>
              </LightTypo>
              <StyledFormControl>
                <Select
                  value={state?.topic}
                  name={"topic"}
                  style={{ borderRadius: "0.625rem" }}
                  onChange={handleTopicChange}
                  required
                >
                  {TOPICS?.map((item) => (
                    <MenuItem value={item.name}>{item.name}</MenuItem>
                  ))}
                </Select>
              </StyledFormControl>
            </Box>
          </SmallBox>
          <SelectGroupCard
            required
            setOpen={setOpen}
            handleChange={handleChange}
            value={state?.type}
            typeId={state?.typeId}
            typeData={typeData}
          />{" "}
          <SelectTypeItem
            type={state?.type}
            typeId={state?.typeId}
            open={open}
            setOpen={setOpen}
            setState={setState}
            setTypeData={setTypeData}
          />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            type="submit"
            color="secondary"
            variant="contained"
            style={{ marginTop: "0.3rem" }}
          >
            Submit
          </Button>
        </Box>
      </form>
    </Box>
  );
}

export default AddNews;
