import {
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import "react-quill/dist/quill.snow.css";
import { useHistory } from "react-router-dom";
import CustomUpload from "../../components/CustomUpload";
import { http } from "../../services/http";

function CreateFieldOfStudy() {
  const router = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useState({
    name: "",
    image: "",
    description: "",
    fields: [],
    videos: [],
  });
  const [field, setField] = useState("");
  const [video, setVideo] = useState("");

  function handleChange(e) {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  }

  function addField(e) {
    e.preventDefault();
    if (!field) {
      enqueueSnackbar("Enter Field Name", {
        variant: "error",
      });
      return;
    }
    setState({
      ...state,
      fields: [...state.fields, field],
    });
    setField("");
  }

  function deleteField(index) {
    let filtered = state.fields?.filter((itm, idx) => idx !== index);
    setState({
      ...state,
      fields: filtered,
    });
  }

  function addVideo(e) {
    e.preventDefault();
    if (!video) {
      enqueueSnackbar("Enter video link", {
        variant: "error",
      });
      return;
    }
    setState({
      ...state,
      videos: [...state.videos, video],
    });
    setVideo("");
  }

  function deleteVideo(index) {
    let filtered = state.videos?.filter((itm, idx) => idx !== index);
    setState({
      ...state,
      videos: filtered,
    });
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (!state.image) {
      enqueueSnackbar("Upload Image", {
        variant: "error",
      });
      return;
    }
    http
      .post("/common/trending-areas-of-study", state)
      .then((res) => {
        enqueueSnackbar("Area Of Study created successfully.", {
          variant: "success",
        });
        router.push("/fields-of-study");
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <Box p={2}>
      <form onSubmit={handleSubmit}>
        <Box mb={2} display="flex" justifyContent="space-between">
          <Typography variant="subtitle1" color="primary">
            Create Area Of Study
          </Typography>
          <Box>
            <Button
              size="medium"
              variant="outlined"
              onClick={() => router.goBack()}
              color="secondary"
            >
              Back
            </Button>
            <Button
              size="medium"
              type="submit"
              style={{ marginLeft: 10 }}
              variant="contained"
              color="secondary"
            >
              Submit
            </Button>
          </Box>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Box>
              <TextField
                onChange={handleChange}
                fullWidth
                variant="outlined"
                margin="dense"
                label="Name"
                name="name"
                required
              />
            </Box>
            <Box mt={1}>
              <TextField
                onChange={handleChange}
                fullWidth
                variant="outlined"
                margin="dense"
                label="Description"
                rows={4}
                multiline
                name="description"
              />
            </Box>
            <Box mt={2}>
              <CustomUpload
                name="image"
                label="Upload Image"
                onChange={(v) => setState({ ...state, image: v })}
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box>
              <Typography variant="subtitle1">Fields</Typography>
              <Box mt={1}>
                {state.fields?.map((item, index) => (
                  <Box display="flex" width="80%" alignItems="center">
                    <Box flex={1}>
                      <Typography variant="body1">
                        {index + 1}. {item}
                      </Typography>
                    </Box>
                    <IconButton onClick={() => deleteField(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                ))}
              </Box>
              <Box display="flex" mt={1}>
                <Box flex={1}>
                  <TextField
                    variant="outlined"
                    size="small"
                    value={field}
                    placeholder="Field Name"
                    fullWidth
                    onChange={(e) => setField(e.target.value)}
                  />
                </Box>
                <Box ml={2}>
                  <Button
                    onClick={addField}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Add
                  </Button>
                </Box>
              </Box>
            </Box>
            <Box mt={2}>
              <Typography variant="subtitle1">Videos</Typography>
              <Box mt={1}>
                {state.videos?.map((item, index) => (
                  <Box display="flex" width="80%" alignItems="center">
                    <Box flex={1}>
                      <Typography variant="body1">
                        {index + 1}. {item}
                      </Typography>
                    </Box>
                    <IconButton onClick={() => deleteVideo(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                ))}
              </Box>
              <Box display="flex" mt={1}>
                <Box flex={1}>
                  <TextField
                    variant="outlined"
                    size="small"
                    value={video}
                    placeholder="Video Link"
                    fullWidth
                    onChange={(e) => setVideo(e.target.value)}
                  />
                </Box>
                <Box ml={2}>
                  <Button
                    onClick={addVideo}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Add
                  </Button>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}

export default CreateFieldOfStudy;
