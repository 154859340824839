import { Box, Button, FormHelperText, TextField, Typography } from '@material-ui/core'
import React, { useState } from 'react'

function ExamPattern({ state, setState }) {

    function addSubject() {
        setState({
            ...state, examPattern: {
                ...state.examPattern, subjects: [...state.examPattern.subjects, {
                    name: "",
                    time: "",
                    sections: []
                }]
            }
        })

    }

    function removeSubject(i) {
        const subjects = state.examPattern.subjects.filter((subject) => {
            return subject !== state.examPattern.subjects[i]
        })
        setState({
            ...state, examPattern: {
                ...state.examPattern, subjects: subjects
            }
        })
    }

    function handleChange(name, value, index) {
        const subjects = state.examPattern.subjects
        subjects[index][name] = value
        setState({
            ...state, examPattern: {
                ...state.examPattern, subjects: subjects
            }
        })
    }

    return (
        <Box>
            <CustomTextField
                value={state?.examPattern?.description}
                multiline
                minRows={5}
                label='Exam Pattern'
                onChange={(e) => setState({
                    ...state, examPattern: {
                        ...state.examPattern, description: e.target.value
                    }
                })}
            />

            <Box >
                {
                    state?.examPattern?.subjects.map((item, index) => (
                        <Box py={2} mx={2} display="flex" justifyContent="left">
                            <Box>
                                <CustomTextField value={item.name} onChange={(e) => handleChange("name", e.target.value, index)} label="Section Name" />
                            </Box>
                            <Box>
                                <CustomTextField value={item.time} label="Time" onChange={(e) => handleChange("time", e.target.value, index)} />
                            </Box>
                            <Box width={500}>
                                <CustomTextField value={item.sections.join(',')} label="Sections" onChange={(e) => handleChange("sections", e.target.value.split(','), index)} />
                                <FormHelperText>Enter Sections seperated by a comma</FormHelperText>
                            </Box>
                            {
                                state.examPattern.subjects.length == index + 1 ? (
                                    <Box>
                                        <Button variant='outlined' onClick={addSubject}>Add</Button>
                                    </Box>) : ''
                            }
                            {
                                state.examPattern.subjects.length !== 1 ? (
                                    <Box>
                                        <Button variant='outlined' onClick={
                                            (e) => removeSubject(index)
                                        }>Remove</Button>
                                    </Box>
                                ) : ''
                            }
                        </Box>
                    ))
                }
            </Box>
        </Box>
    )
}


const CustomTextField = ({ label, onChange, value, ...props }) => {
    return (
        <TextField
            fullWidth
            variant='outlined'
            size='small'
            label={label}
            onChange={onChange}
            value={value}
            {...props}
        />
    )
}

export default ExamPattern
