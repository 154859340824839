import { Box } from "@material-ui/core";
import React from "react";
import Empty from "../../assets/images/empty.svg";
import { BoldTypo } from "../../components/Typography/BoldTypo";

const EmptyScreen = (props) => {
  return (
    <Box textAlign="center" mt={5} margin=" 0 auto">
      <img src={Empty} alt="" width={"100px"} />
      <BoldTypo>{props?.heading}</BoldTypo>
    </Box>
  );
};

export default EmptyScreen;
