import { CircularProgress } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { http } from '../../services/http'

const AttributesEdit = () => {
	const params = useParams()

	const [data, setData] = useState(null)
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		setLoading(false)
		getData()
	}, [])

	const getData = async () => {
		try {
			const res = await http.get('/eav?type=form')
			setData(res.data)
			setLoading(false)
		} catch (err) {
			console.log(err)
		}
	}

	if (loading || !data) return <CircularProgress />

	return <div>{params?.id}</div>
}

export default AttributesEdit
