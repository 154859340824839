import {
  Box,
  Button,
  Checkbox,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import { KeyboardBackspace } from "@material-ui/icons";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import CustomUpload from "../../components/CustomUpload";
import ImageWithUploadButton from "../../components/Button/ImageWithUploadButton";
import { http } from "../../services/http";
import SelectTypeItem from "../../components/webinar/SelectTypeItem";
import { LightTypo } from "../../components/Typography/LightTypo";
import StyledTextField from "../../components/StyledTextField";
import { profileStore } from "../../Layout";

import {
  GroupCard,
  SmallBox,
  StyledButton,
  StyledFormControl,
  WideBox,
} from "../../components/webinar/components";
import SelectGroupCard from "../../components/webinar/SelectGroupCard";
import ImageUpload from "../../components/webinar/ImageUpload";

const WebinarForm = () => {
  let { enqueueSnackbar } = useSnackbar();
  const user = profileStore.useState((s) => s.state);
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [typeData, setTypeData] = useState();
  const userId = user?.id;
  const [state, setState] = useState({
    type: "",
    typeId: "",
    title: "",
    topic: "",
    description: "",
    link: "",
    speaker: "",
    speakerDescription: "",
    images: [],
    startTime: "",
    endTime: "",
  });

  const editMode = !!history.location.state;
  console.log(imageUrl);
  useEffect(
    (images) => {
      console.log("hi");
      console.log(imageUrl);
      if (imageUrl) {
        setState({ ...state, [images]: state?.images?.push(imageUrl) });
      }
    },
    [imageUrl]
  );

  useEffect(() => {
    if (history.location.state) {
      setState(history.location.state);
    }
  }, []);

  const handleChange = (name, value) => {
    setState({ ...state, [name]: value });
    console.log(name);
  };

  const postLevelOfStudy = async (state) => {
    if (
      !state?.type ||
      !state?.typeId ||
      !state?.title ||
      !state?.speaker ||
      !state?.speakerDescription ||
      !state?.topic ||
      !state?.description ||
      !state?.images.length ||
      !state?.startTime ||
      !state?.endTime ||
      !state?.link
    ) {
      enqueueSnackbar("Please fill the required fields", { variant: "error" });
      return;
    }
    const postBody = {
      ...state,
      duration: 45,
    };
    delete postBody.typeValue;
    try {
      const res = await http.post(`/webinars/user/${userId}`, postBody);
      enqueueSnackbar("Submitted Successfully", { variant: "success" });
      history.push("/webinars");
    } catch (err) {
      console.log(err);

      enqueueSnackbar("Error Submitting", { variant: "error" });
    }
  };
  const updateLevelOfStudy = async (body) => {
    const postBody = {
      ...state,
    };

    delete postBody.typeValue;
    try {
      const res = await http.put(`/webinars/${body?.id}`, postBody);
      enqueueSnackbar("Updated Successfully", { variant: "success" });
      history.push("/webinars");
    } catch (err) {
      enqueueSnackbar("Error Submitting", { variant: "error" });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    editMode ? updateLevelOfStudy(state) : postLevelOfStudy(state);
  };

  console.log(state);
  return (
    <form onSubmit={handleSubmit}>
      <Box display="flex" flexDirection="column" gridGap={30}>
        <Box display="flex" gridGap={20} alignItems="center">
          <IconButton onClick={() => history.goBack()}>
            <KeyboardBackspace />
          </IconButton>
          <Typography variant="h6">
            {!editMode ? "Add Webinar" : "Edit Webinar"}
          </Typography>
        </Box>

        <SmallBox>
          <Box>
            <StyledTextField
              title="Webinar Title"
              value={state?.title}
              fieldName={"title"}
              onChange={handleChange}
              required
            />
            <StyledTextField
              title="Webinar Speaker"
              value={state?.speaker}
              fieldName={"speaker"}
              onChange={handleChange}
              required
            />
          </Box>
        </SmallBox>

        <WideBox>
          <StyledTextField
            title="Webinar Speaker Description"
            value={state?.speakerDescription}
            multiline={true}
            fieldName={"speakerDescription"}
            onChange={handleChange}
            placeholder="Enter Webinar Speaker Description…"
            rows={3}
            required
          />
        </WideBox>
        <ImageUpload
          required={true}
          imageUrl={imageUrl}
          setImageUrl={setImageUrl}
        />

        <SmallBox>
          <StyledTextField
            title="Webinar Topic"
            value={state?.topic}
            fieldName={"topic"}
            onChange={handleChange}
            required
          />
          <StyledTextField
            title="Start Time"
            type={"datetime-local"}
            size="small"
            fieldName={"startTime"}
            value={state?.startTime}
            onChange={handleChange}
            label="Start Time"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            required
          />
          <StyledTextField
            title="End Time"
            type={"datetime-local"}
            size="small"
            label="End Time"
            fieldName={"endTime"}
            value={state?.endTime}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            required
          />
        </SmallBox>

        <WideBox>
          <StyledTextField
            title="Webinar Description"
            value={state?.description}
            fieldName={"description"}
            multiline={true}
            onChange={handleChange}
            placeholder="Enter Webinar Description…"
            rows={3}
            required
          />
        </WideBox>
        <SmallBox>
          <StyledTextField
            title="Webinar Link"
            value={state?.link}
            fieldName={"link"}
            onChange={handleChange}
            required
          />
        </SmallBox>
        <SelectGroupCard
          required
          setOpen={setOpen}
          handleChange={handleChange}
          value={state?.type}
          typeId={state?.typeId}
          typeData={typeData}
        />
      </Box>
      {/* {open && ( */}
      <SelectTypeItem
        type={state?.type}
        typeId={state?.typeId}
        open={open}
        setOpen={setOpen}
        setState={setState}
        setTypeData={setTypeData}
      />
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          type="submit"
          color="secondary"
          variant="contained"
          style={{ marginTop: "0.3rem" }}
        >
          Submit
        </Button>
      </Box>
    </form>
  );
};

export default WebinarForm;
