import { Box } from "@material-ui/core";
import React from "react";

export default function StudentProfileLayout({ children }) {
  return (
    <>
      <Box
        style={{
          maxWidth: "1000px",
          margin: "2rem auto",
        }}
      >
        {children}
      </Box>
    </>
  );
}
