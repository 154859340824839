import { Box } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { http } from "../../services/http";
import ImageWithUploadButton from "../Button/ImageWithUploadButton";
import CustomUpload from "../CustomUpload";
import { BoldTypo } from "../Typography/BoldTypo";
import { LightTypo } from "../Typography/LightTypo";
import { StyledButton, WideBox } from "./components";

export default function ImageUpload({
  imageUrl,
  setImageUrl,
  state,
  handleChange,
  required,
  src,
}) {
  const inputRef = useRef();
  const [loading, setLoading] = useState(false);
  const [key, setKey] = useState("");

  useEffect(() => {
    setImageUrl(src);
  }, [src]);

  const handleClick = () => {
    if (inputRef) {
      inputRef.current.click();
    }
  };

  function handleChange(e) {
    if (e.target.files[0]) {
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      setLoading(true);
      http
        .post("/common/upload", formData)
        .then((res) => {
          //   onChange(res.data);
          setImageUrl(res.data.key);
          setKey(res.data.key);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  }
  console.log(imageUrl);

  return (
    <WideBox>
      <Box>
        <LightTypo>
          Image{required && <span style={{ color: "red" }}>*</span>}
        </LightTypo>
        {key ? (
          <BoldTypo>{key}</BoldTypo>
        ) : (
          <StyledButton variant="outlined" onClick={handleClick}>
            Upload Image
          </StyledButton>
        )}
      </Box>

      <input
        type="file"
        accept="image/png, image/jpeg"
        style={{ display: "none" }}
        ref={inputRef}
        onChange={handleChange}
      />
    </WideBox>
  );
}
