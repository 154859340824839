import { http } from "../services/http";

//AUTH
function signup(data) {
  return http.post("/auth/sign-up", data);
}

function login(data) {
  return http.post("/auth/login", data);
}

function getProfile(search) {
  return http.get(`users/profile?search=${search}`);
}

//COMMON
function uploadFile(data) {
  return http.post("/common/upload", data);
}

function getApplicationData(id) {
  return http.get(`/application/${id}`);
}

function getCountries() {
  return http.get("/countries");
}

function getNews() {
  return http.get("/news");
}

function deleteNews(id) {
  return http.delete(`/common/news/${id}`);
}

function updateCallStatus(id) {
  return http.post(`/schedule/${id}/complete`);
}

function postApplicationDocuments(data) {
  return http.post(`/application`, data);
}

function addDocument(data) {
  return http.post(`/documents/user`, data);
}

function uploadDocument(id, data) {
  return http.patch(`/documents/${id}`, data);
}

function deleteDocument(id, data) {
  return http.delete(`/documents/${id}`);
}

function getUserDocuments(id) {
  return http.get(`/documents/user/?userId=${id}`);
}

function getAllForms(userId) {
  return http.get(`/eav?type=form`);
}

export {
  signup,
  login,
  uploadFile,
  uploadDocument,
  getProfile,
  getApplicationData,
  getCountries,
  getNews,
  deleteNews,
  updateCallStatus,
  postApplicationDocuments,
  getUserDocuments,
  addDocument,
  deleteDocument,
  getAllForms,
};
