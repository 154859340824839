import { Box } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { Store } from "pullstate";
import React, { useEffect } from "react";
import Settings from "../components/Settings";
import { http } from "../services/http";
import LayoutDrawer from "./Drawer";
import Routes from "./Routes";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    width: "100%", // `calc(100% - ${drawerWidth}px)`,
    // marginLeft: drawerWidth,
    backgroundColor: "white",
    zIndex: theme.zIndex.drawer + 1,
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    minHeight: "100vh",
    backgroundColor: "white",
    padding: theme.spacing(3),
  },
}));

export const profileStore = new Store({ state: "", roles: [] });
function Layout() {
  const classes = useStyles();

  useEffect(() => {
    getUserProfile();
  }, []);

  function getUserProfile() {
    http.get("/users").then((res) => {
      profileStore.update((s) => {
        s.state = res.data;
        s.roles = res.data.roles;
      });
      if (res?.data?.roles[0]?.name == "MENTOR") {
        document.title = "Stuzee Consultant";
      }
    });
  }
  function handleLogout() {
    let isConfirm = window.confirm("Do you want to Logout?");
    if (isConfirm) {
      localStorage.removeItem("access_token");
      window.location.href = "/";
    }
    return;
  }
  return (
    <div className={classes.root}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <HeaderLogo />
          <Box sx={{ display: "flex" }}>
            <Settings handleLogout={handleLogout} />
          </Box>
        </Toolbar>
      </AppBar>
      <LayoutDrawer />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Routes />
      </main>
    </div>
  );
}

export default Layout;

const HeaderLogo = () => {
  const role = profileStore.useState((s) => s.roles.map((role) => role.name));

  const admin = role[0] == "ADMIN" || role[1] == "MENTOR";
  return (
    <Box flex={1} style={{ display: "flex", gap: "1rem", marginLeft: "1rem" }}>
      <img src="/images/logo.png" alt="Logo" width="100px" />
      <Typography variant="h6" style={{ color: "black" }}>
        {admin ? "Admin Panel" : "Consultant Panel"}
      </Typography>
    </Box>
  );
};
