import { Box, Button, TextField } from '@material-ui/core'
import React from 'react'

function HowToRegister({ state, setState }) {

    function addStep() {
        setState({
            ...state, howToRegister: {
                ...state.howToRegister, steps: [...state.howToRegister.steps, ""]
            }
        })
    }

    function removeStep(i) {
        const steps = state.howToRegister.steps.filter((step) => {
            return step !== state.howToRegister.steps[i]
        })
        setState({
            ...state, howToRegister: {
                ...state.howToRegister, steps: steps
            }
        })
    }

    function handleChange(value, index) {
        const steps = state.howToRegister.steps
        steps[index] = value
        setState({
            ...state, howToRegister: {
                ...state.howToRegister, steps: steps
            }
        })
    }

    return (
        <Box>
            <CustomTextField
                value={state?.howToRegister?.description}
                multiline
                minRows={3}
                label='How To Register'
                onChange={(e) => setState({
                    ...state, howToRegister: {
                        ...state.howToRegister, description: e.target.value
                    }
                })}
            />
            <Box >
                {state?.howToRegister.steps.map((item, index) => (
                    <Box display="flex" justifyContent="left" py={2} px={2}>
                        <Box width="70%">
                            <CustomTextField
                                value={item}
                                multiline
                                label={'How to Register Step' + (index + 1)}
                                onChange={(e) => handleChange(e.target.value, index)}
                            />
                        </Box>
                        {state.howToRegister.steps.length == index + 1 ? <Box>
                            <Button variant='outlined' onClick={addStep}>Add Step</Button>
                        </Box> : ""}

                        {state.howToRegister.steps.length !== 1 ?
                            <Box>
                                <Button variant='outlined' onClick={
                                    (e) => removeStep(index)
                                }>Remove</Button>
                            </Box> : ""}

                    </Box>
                ))}

            </Box>
        </Box>
    )
}

export default HowToRegister

const CustomTextField = ({ label, onChange, value, ...props }) => {
    return (
        <TextField
            fullWidth
            variant='outlined'
            size='small'
            label={label}
            onChange={onChange}
            value={value}
            {...props}
        />
    )
}
