import { Box, styled, Typography } from "@material-ui/core";
import { BoldTypo } from "./Typography/BoldTypo";
import { LightTypo } from "./Typography/LightTypo";

export const FlexBox = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "1rem",
}));

export const Stats = ({ title, value }) => {
  //   console.log(title, value, "stats");
  return (
    <>
      <Box>
        <LightTypo style={{ whiteSpace: "nowrap" }}>{title}</LightTypo>
        <BoldTypo style={{ whiteSpace: "nowrap" }}>{value}</BoldTypo>
      </Box>
    </>
  );
};

export const ScoreCards = ({ title, score }) => {
  return (
    <Box
      style={{
        border: "1px solid rgba(211,211,211,0.5)",
        display: "flex",

        padding: "1rem",
        flexGrow: 1,
        borderRadius: "0.5rem",
        justifyContent: "space-between",
      }}
    >
      <Typography>{title}</Typography>
      <Typography>{score}</Typography>
    </Box>
  );
};
export const CourseCard = styled(Box)(({ theme }) => ({
  boxShadow: " 0px 3px 15px #273B801A",
  margin: "1rem",
  padding: "1rem",
  borderRadius: "1rem",
}));

export const CardsContainer = ({
  title,
  color,
  children,
  titleColor,
  ...props
}) => (
  <Box
    style={{
      border: `1px solid ${color}`,
      margin: "0 0.5rem",
      borderRadius: "1rem",
      overflow: "hidden",
      ...props,
    }}
  >
    <Box
      style={{
        padding: "1rem",
        color: titleColor || "white",
        background: `${color}`,
      }}
    >
      {title}
    </Box>
    <Box
      sx={{
        height: "75vh",
        overflowY: "scroll",

        "&::-webkit-scrollbar": {
          width: "5px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#DCDCDC",
        },
        "&::-webkit-scrollbar-thumb:hover": {
          background: "#D3D3D3",
        },
      }}
    >
      {children}
    </Box>
  </Box>
);
