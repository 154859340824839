import { Box } from "@material-ui/core";
import React from "react";
import { FlexBox } from "../container";
import { BoldTypo } from "../Typography/BoldTypo";
export default function CourseActions({ courseName, children }) {
  return (
    <FlexBox style={{ justifyContent: "space-between" }}>
      <Box>
        <BoldTypo color="primary" style={{ marginBottom: "0.5rem" }}>
          {courseName}
        </BoldTypo>
      </Box>
      <FlexBox>{children}</FlexBox>
    </FlexBox>
  );
}
