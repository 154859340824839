import { Button } from "@material-ui/core";
import React from "react";

export const OutlinedButton = ({ onClick, children }) => {
  return (
    <>
      <Button
        variant="outlined"
        color="secondary"
        size="small"
        style={{ height: "fit-content", margin: "auto" }}
        onClick={onClick}
      >
        {children}
      </Button>
    </>
  );
};
