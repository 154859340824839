import { createSlice } from '@reduxjs/toolkit'
import { http } from '../../services/http'

const initialState = {
	data: null,
	selectedForm: null,
	loading: false,
	error: null,
}

const eavFormSlice = createSlice({
	name: 'eavForm',
	initialState: initialState,
	reducers: {
		setData(state, action) {
			state.data = action.payload
			state.loading = false
		},

		setLoading(state, action) {
			state.loading = action.payload
		},
		handleChange(state, action) {
			const { fieldIndex, value } = action.payload
			state.selectedForm.fields[fieldIndex].value.value = value
		},
		setSelectedForm(state, action) {
			state.selectedForm = action.payload
		},
	},
})

export const { setData, setLoading, handleChange, setSelectedForm } =
	eavFormSlice.actions

export default eavFormSlice.reducer

export const fetchUserForm = (userId) => async (dispatch) => {
	const url = userId ? `/eav/${userId}/type-form` : `/eav?type=form`

	try {
		dispatch(setLoading(true))
		const res = await http.get(url)
		dispatch(setData(res.data))
	} catch (error) {
		dispatch(setLoading(false))
	}
}
