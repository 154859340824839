import React, { useState } from "react";
import { BoldTypo } from "../../components/Typography/BoldTypo";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  Text,
  TextField,
} from "@mui/material";
import { Search } from "@material-ui/icons";
import { http } from "../../services/http";
import { LightTypo } from "../../components/Typography/LightTypo";
import { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
function PreferredCountries() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const navigate = useHistory();

  //   const innerNavigation = (country) => {
  //     router.push(`/preferred-universities?country=${country}`);
  //   };

  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    setLoading(true);
    try {
      const res = await http.get(
        "/users/dashboard/student-preferences/country"
      );
      console.log(res);
      setData(res.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };
  if (loading) return <CircularProgress />;

  console.log(data);
  //   const SearchInput = ({ getData }) => {
  //     const [search, setSearch] = useState("");
  //     const handleSearch = (e) => {
  //       getData({ search });
  //     };
  //     return (
  //       <TextField
  //         variant="outlined"
  //         fullWidth
  //         size="small"
  //         value={search}
  //         onChange={(e) => setSearch(e.target.value)}
  //         placeholder="Search for a University"
  //         InputProps={{
  //           endAdornment: (
  //             <InputAdornment position="end">
  //               <IconButton
  //                 onClick={handleSearch}
  //                 type="submit"
  //                 color="secondary"
  //                 size="large"
  //               >
  //                 <Search fontSize="small" />
  //               </IconButton>
  //             </InputAdornment>
  //           ),
  //         }}
  //       />
  //     );
  //   };

  return (
    <div>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <ArrowBackIcon
          sx={{ color: "#D72245", cursor: "pointer", fontSize: "medium" }}
          onClick={() => navigate.goBack()}
        />
        <BoldTypo style={{ color: "#D72245", marginLeft: "0.3rem" }}>
          Countries
        </BoldTypo>
      </Box>
      {/* <Box sx={{ width: "435px" }}>
        <SearchInput getData={getData} />
      </Box> */}
      <Grid container style={{ marginTop: 10 }} spacing={2}>
        {data?.map((item, ind) => {
          return (
            <Grid item xs={6}>
              <Paper
                p={2}
                minHeight="100px"
                elevation={2}
                style={{
                  fontWeight: "bold",
                  borderRadius: "15px",
                }}
              >
                <Box
                  sx={{
                    padding: "30px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box>
                      <img
                        src={item?.imageUrl}
                        style={{
                          width: "120px",
                          height: "120px",
                          borderRadius: "10px",
                          boxShadow: "0px 3px 5px #273B801A",
                          objectFit: "cover",
                        }}
                        alt={item?.name}
                      />
                    </Box>
                    <Box sx={{ marginLeft: "1rem" }}>
                      <BoldTypo style={{ color: "#D72245" }}>
                        {item?.name}
                      </BoldTypo>

                      <Stack
                        direction="row"
                        spacing={2}
                        sx={{ marginTop: "0.3rem" }}
                        divider={<Divider orientation="vertical" flexItem />}
                      >
                        <Box>
                          <LightTypo>Number of students</LightTypo>
                          <BoldTypo>{item?.studentCount}</BoldTypo>
                        </Box>
                        <Box>
                          <LightTypo>Number of Universities</LightTypo>
                          <BoldTypo>{item?.unvCount}</BoldTypo>
                        </Box>
                      </Stack>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      marginTop: "6rem",
                    }}
                  >
                    <Link to={`/preferred-universities?country=${item?.name}`}>
                      <ArrowForwardIcon
                        sx={{
                          fontSize: "large",
                          color: "#D72245",
                          cursor: "pointer",
                        }}
                      />
                    </Link>
                  </Box>
                </Box>
              </Paper>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}

export default PreferredCountries;
