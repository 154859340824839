import { Box, Typography, useTheme } from "@mui/material";
import React from "react";
import { LightTypo } from "./Typography/LightTypo";

function UnderLineHeading({ title, color = "primary", ...props }) {
  const theme = useTheme();
  return (
    <Box style={{ height: "3em" }}>
      <Typography
        {...props}
        color={color}
        sx={{
          position: "relative",
          "&:before": {
            content: "''",
            position: "absolute",
            width: 50,
            height: 4,
            bottom: "-10px",
            background: "#D7385B",
            borderRadius: "10px",
          },
        }}
        variant="subtitle2"
      >
        {title}
      </Typography>
    </Box>
  );
}

export default UnderLineHeading;
