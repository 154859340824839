import { Typography, Box, makeStyles, IconButton } from "@material-ui/core";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import { createContext, useContext, useState } from "react";

const AccordianContext = createContext(null);

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: theme.spacing(2),
    border: `1px solid #FDB2B1`,
  },
  header: {
    borderRadius: theme.spacing(2),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(1),
    background: theme.palette.primary.light,
    color: theme.palette.primary.main,
  },
  body: {
    padding: theme.spacing(2),
  },
  active: {
    borderRadius: `${theme.spacing(2)}px ${theme.spacing(2)}px 0 0`,
  },
}));

const Accordian = ({ children }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  return (
    <AccordianContext.Provider value={{ open, setOpen }}>
      <div className={classes.root}>{children}</div>
    </AccordianContext.Provider>
  );
};

Accordian.Header = function AccordianHeader({ children, ...restProps }) {
  const { open = true, setOpen } = useContext(AccordianContext);
  const classes = useStyles(open);

  return (
    <Box
      {...restProps}
      className={!open ? classes.header : classes.header + " " + classes.active}
    >
      <Typography variant="h5">{children}</Typography>
      <IconButton onClick={() => setOpen((prev) => !prev)} size="small">
        {open ? <RemoveIcon /> : <AddIcon />}
      </IconButton>
    </Box>
  );
};

Accordian.Body = function AccordianBody({ children, ...restProps }) {
  const classes = useStyles();
  const { open = true } = useContext(AccordianContext);
  return (
    <Box minWidth={400}>
      {open && (
        <Box {...restProps} className={classes.body}>
          {children}
        </Box>
      )}
    </Box>
  );
};

export default Accordian;
