import { Box } from "@material-ui/core";
import StudentProfileLayout from "../../../Layout/StudentProfileLayout";
import EavFormList from "./eav";
import UserProfile from "./UserProfile";

const Profile = () => {
  const userId = new URLSearchParams(window.location.search).get("userId");

  return (
    <StudentProfileLayout>
      <div style={{ margin: "0 1.5rem" }}>
        <UserProfile userId={userId} />
      </div>
      {userId && <EavFormList />}
    </StudentProfileLayout>
  );
};

export default Profile;
