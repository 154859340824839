import {
  IconButton,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import { makeStyles } from "@material-ui/core/styles";
import EditAttributesOutlinedIcon from "@material-ui/icons/EditAttributesOutlined";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { styled } from "@material-ui/styles";
import React from "react";
import { Link } from "react-router-dom";
import { profileStore } from "../Layout";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  paper: {
    marginRight: theme.spacing(2),
  },
}));

export default function Settings({ handleLogout }) {
  const roles = profileStore.useState((s) => s.roles.map((item) => item.name));
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const authenticate = roles[0] == "ADMIN" || roles[1] == "ADMIN";
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div className={classes.root}>
      <div>
        <Button
          ref={anchorRef}
          aria-controls={open ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          <CustomIcon src="/images/settings 2.png" />
          <Typography>Settings</Typography>
        </Button>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper elevation={3} style={{ marginRight: "30px" }}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                  >
                    {authenticate &&
                      menuItems.map((item) => (
                        <CustomMenuItem item={item} handleClose={handleClose} />
                      ))}
                    <ListItem button onClick={handleLogout}>
                      <CustomIcon src={"/images/exit.png"} />

                      <ListItemText
                        style={{ color: "black" }}
                        primary={"Logout"}
                      />
                    </ListItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
}

const CustomIcon = styled("img")(({ theme }) => ({
  width: "24px",
  marginRight: "10px",
}));

const CustomMenuItem = ({ item, handleClose }) => {
  return (
    <Link key={item.path} to={item.path}>
      <ListItem button key={`${item.path}`} onClick={handleClose}>
        {typeof item.icon == "string" ? (
          <CustomIcon src={item.icon} />
        ) : (
          <IconButton style={{ width: "24px", marginRight: "10px" }}>
            {item.icon}
          </IconButton>
        )}

        <ListItemText style={{ color: "black" }} primary={`${item.title}`} />
      </ListItem>
    </Link>
  );
};

const menuItems = [
  {
    title: "Countries",
    path: "/countries",
    icon: "/images/countries 2.png",
  },
  {
    title: "Area of Study",
    path: "/areas-of-study",
    icon: "/images/mortarboard.png",
  },
  {
    title: "Level of Study",
    path: "/level-of-study",
    icon: "/images/increase.png",
  },
  {
    title: "Exams",
    path: "/exams",
    icon: "/images/exam.png",
  },
  {
    title: "Scholarships",
    path: "/scholarships",
    icon: "/images/scholarship.png",
  },
  {
    title: "Languages",
    path: "/languages",
    icon: "/images/languages.png",
  },
  {
    title: "Attributes",
    path: "/attributes",
    icon: "/images/settings (1).png",
    children: [],
  },
  {
    title: "Admin Users",
    path: "/admin-users",
    icon: "/images/user 2.png",
    children: [],
  },
  //   {
  //     title: "Log Out",
  //     path: "/",
  //     icon: "/images/lang.png",
  //   },
];
