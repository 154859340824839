import { Box, IconButton, makeStyles, useTheme } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { http } from "../../services/http";
import {
  CardsContainer,
  CourseCard,
  FlexBox,
  ScoreCards,
  Stats,
} from "../container";

import { BoldTypo } from "../Typography/BoldTypo";
import { LightTypo } from "../Typography/LightTypo";
import { useQuery } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { useQueryClient } from "@tanstack/react-query";
import { OutlinedButton } from "../OutlinedButton";
import CourseActions from "../courses/CourseActions";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import moment from "moment";
import StudentsChoiceDialog from "./StudentsChoiceDialog";
import _ from "lodash";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";

const initState = {
  appliedCourseId: null,
  studentsChoice: false,
  intake: [],
  status: "",
};
export default function ViewStudentsChoice() {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [shortlistBody, setShortlistBody] = useState(initState);
  const userId = new URLSearchParams(window.location.search).get("userId");

  let { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const theme = useTheme();
  const classes = useStyles();

  const handleClickOpen = (obj) => {
    console.log(obj);

    setShortlistBody({ ...shortlistBody, ...obj });
    setOpen(true);
    console.log(shortlistBody);
  };

  const handleChange = (field, value) => {
    setShortlistBody({ ...shortlistBody, [field]: value });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const updateStudentsChoice = () => {
    http
      .put(`/application/students-choice/user/${userId}`, {
        ...shortlistBody,
        userId,
      })
      .then(() => {
        queryClient.invalidateQueries("studentsChoice");
        setShortlistBody(initState);
      });
  };

  const { data: appliedUnivs, isLoading } = useQuery(
    ["studentsChoice"],
    () =>
      http.get(`/application/students-choice/user/${userId}`).then((res) => {
        console.log(res);

        return res?.data;
      }),
    {
      onSuccess: (data) => {
        setData(data);
      },
    }
  );
  console.log(data);
  return (
    <Box
      sx={{
        display: "flex",
        marginTop: "2rem",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          color: "red !important",
          marginLeft: "98rem",
          marginBottom: "1rem",
        }}
      >
        <Link to={`students-choice?userId=${userId}`}>
          <Typography style={{ color: "#d3264c" }}>
            {" "}
            Edit Student's Choice
          </Typography>
        </Link>
      </Box>

      <CardsContainer
        color={theme.palette.primary.main}
        title={"Student's Choice"}
      >
        <>
          <>
            <FlexBox style={{ margin: "1rem" }}>
              <img
                src={data?.university?.logoUrl}
                style={{
                  width: "100px",
                  borderRadius: "10px",
                  boxShadow: "0px 3px 5px #273B801A",
                }}
                alt={data?.university?.name}
              />
              <Box style={{ padding: "1rem" }}>
                <BoldTypo color="primary">{data?.university?.name}</BoldTypo>
                <LightTypo>
                  {data?.university?.city + ", " + data?.university?.country}{" "}
                </LightTypo>
              </Box>
            </FlexBox>
            {data?.appliedCourses?.map((course, index) => {
              return (
                <CourseCard>
                  <CourseActions
                    courseName={course?.course?.name}
                  ></CourseActions>

                  <FlexBox
                    style={{
                      marginBottom: "1rem",
                      gap: "2.5rem",
                      marginTop: "1.2rem",
                    }}
                  >
                    <Stats title="Payment Type" value={course?.feeType} />
                    <Stats
                      title="Fee Amount"
                      value={`${course?.feeAmount} ${data?.university?.currency}`}
                    />
                    <Stats
                      title="Application Date"
                      value={moment(course?.applicationDate).format(
                        "YYYY-MM-DD"
                      )}
                    />
                    <Stats
                      title="Expected Approval Date"
                      value={moment(course?.followUpDate).format("YYYY-MM-DD")}
                    />
                    <Stats
                      title="Documents received"
                      value={course?.docsReceived ? "Yes" : "No"}
                    />
                  </FlexBox>
                  <FlexBox
                    style={{
                      marginBottom: "1rem",
                      gap: "2.5rem",
                      marginTop: "1.2rem",
                    }}
                  >
                    <Stats
                      title="Approval Status"
                      value={course?.admissionDecision}
                    />
                    <Stats
                      title="Approval Date"
                      value={moment(course?.approvalDate).format("YYYY-MM-DD")}
                    />
                    <Stats title="I20 Status" value={course?.i20Status} />
                  </FlexBox>
                  <FlexBox
                    style={{
                      marginBottom: "1rem",
                      gap: "2.5rem",
                      marginTop: "1.2rem",
                    }}
                  >
                    <Stats
                      title="Student's choice"
                      value={course?.studentsChoice ? "Yes" : "No"}
                    />
                    <Stats title="Intake" value={course?.intake} />
                  </FlexBox>
                </CourseCard>
              );
            })}
          </>
        </>
        <StudentsChoiceDialog
          updateStudentChoice={updateStudentsChoice}
          handleClose={handleClose}
          open={open}
          handleChange={handleChange}
          shortlistBody={shortlistBody}
          isUpdate={true}
        />
      </CardsContainer>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  icon: {
    "&:hover": {
      color: theme.palette.secondary.main,
      backgoundColor: "#FFFF00",
    },
  },
}));
