import { Box, CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
// import UniversitiesContainer from "../../../components/universities/UniversitiesContainer"
import UniversitiesContainer from "../../components/universities/UniversitiesContainer";
import CoursesContainer from "../../components/courses/CoursesContainer";
import ShortlistedCourses from "../../components/courses/ShortlistedCourses";
import { http } from "../../services/http";

export default function ShortlistUniversities() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [univId, setUnivId] = useState();
  const userId = new URLSearchParams(window.location.search).get("userId");
  const [search, setSearch] = useState("");

  useEffect(() => {
    console.log("Hiii");
    setLoading(true);
    http
      .get("/universities")
      .then((res) => {
        setData(res.data.data);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <Box mt={10} textAlign="center">
        <CircularProgress />
      </Box>
    );
  }
  return (
    <>
      <Box
        sx={{
          display: "flex",
          marginTop: "2rem",
        }}
      >
        <Box
          sx={{
            flex: 1,
          }}
        >
          <UniversitiesContainer
            data={data}
            setData={setData}
            setUnivId={setUnivId}
            selectedId={univId || data[0]?.id}
          />
        </Box>
        <Box sx={{ flex: 1 }}>
          <CoursesContainer
            univId={univId || data[0]?.id}
            userId={userId}
          ></CoursesContainer>
        </Box>
        <Box sx={{ flex: 1 }}>
          <ShortlistedCourses
            univId={univId || data[0]?.id}
            courseId={1}
            userId={userId}
          />
        </Box>
      </Box>
    </>
  );
}
