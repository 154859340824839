import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { http } from "../../services/http";
import CustomDataUpload from "../../shared/components/CustomDataUploader";
import CustomSelect from "../../shared/components/CustomSelect";

function ImportData() {
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(false);
    http
      .get("/countries")
      .then((res) => {
        setCountries(res.data);
        console.log(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  if (loading) return <CircularProgress />;

  return (
    <Grid container spacing={4}>
      <Grid item xs={6}>
        <ImportUniversities
          countries={countries.map((item) => ({
            label: item?.name,
            value: item?.name,
          }))}
        />
      </Grid>
      <Grid item xs={6}>
        <ImportCourses
          countries={countries.map((item) => ({
            label: item?.name,
            value: item?.name,
          }))}
        />
      </Grid>
    </Grid>
  );
}

function ImportUniversities({ countries }) {
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useState({
    country: "",
    file: null,
  });
  const [loading, setLoading] = useState(false);

  function handleSubmit(e) {
    e.preventDefault();
    if (!state.file) {
      enqueueSnackbar("File is required", { variant: "error" });
      return;
    }
    let formData = new FormData();
    formData.append("country", state.country);
    formData.append("file", state.file);
    setLoading(true);
    http
      .post("/universities/upload", formData)
      .then((res) => {
        enqueueSnackbar("Universities imported successfully", {
          variant: "success",
        });
        setLoading(false);
      })
      .catch((err) => {
        enqueueSnackbar(`something went wrong ${err.response.data.message} `, {
          variant: "error",
        });
        setLoading(false);
      });
  }
  return (
    <Box maxWidth="600px" width="100%">
      <Typography variant="h4" gutterBottom>
        Import Universities
      </Typography>
      <form onSubmit={handleSubmit}>
        <Box mt={2}>
          <CustomSelect
            value={state.country}
            onChange={(e) => setState({ ...state, country: e.target.value })}
            required
            name="country"
            options={countries}
            label="Select Country"
          />
        </Box>
        <Box mt={2}>
          <CustomDataUpload
            onChange={(v) => setState({ ...state, file: v })}
            name="sheet"
            accept=".xls,.xlsx"
          />
          <Typography
            style={{ paddingTop: 5 }}
            color="textSecondary"
            variant="body2"
          >
            *only .xls or .xlsx files are accepted
          </Typography>
        </Box>
        <Box mt={3} textAlign="right">
          <Button type="submit" variant="contained" color="primary">
            {loading ? (
              <CircularProgress size={30} style={{ color: "white" }} />
            ) : (
              "Submit"
            )}
          </Button>
        </Box>
      </form>
    </Box>
  );
}

function ImportCourses({ countries }) {
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useState({
    country: "",
    file: null,
  });
  const [loading, setLoading] = useState(false);

  function handleSubmit(e) {
    e.preventDefault();
    if (!state.file) {
      enqueueSnackbar("File is required", { variant: "error" });
      return;
    }
    let formData = new FormData();
    formData.append("country", state.country);
    formData.append("file", state.file);
    setLoading(true);
    http
      .post("/universities/courses/upload", formData)
      .then((res) => {
        enqueueSnackbar("Courses imported successfully", {
          variant: "success",
        });
        setLoading(false);
      })
      .catch((err) => {
        enqueueSnackbar(`something went wrong ${err.response.data.message} `, {
          variant: "error",
        });
        setLoading(false);
      });
  }
  return (
    <Box maxWidth="600px" width="100%">
      <Typography variant="h4" gutterBottom>
        Import Courses
      </Typography>
      <form onSubmit={handleSubmit}>
        <Box mt={2}>
          <CustomSelect
            required
            options={countries}
            onChange={(e) => setState({ ...state, country: e.target.value })}
            name="country"
            label="Select Country"
          />
        </Box>
        <Box mt={2}>
          <CustomDataUpload
            onChange={(v) => setState({ ...state, file: v })}
            name="coursesheet"
            accept=".xls,.xlsx"
          />
          <Typography
            style={{ paddingTop: 5 }}
            color="textSecondary"
            variant="body2"
          >
            *only .xls or .xlsx files are accepted
          </Typography>
        </Box>
        <Box mt={3} textAlign="right">
          <Button type="submit" variant="contained" color="primary">
            {loading ? (
              <CircularProgress size={30} style={{ color: "white" }} />
            ) : (
              "Submit"
            )}
          </Button>
        </Box>
      </form>
    </Box>
  );
}

export default ImportData;
