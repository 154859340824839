import { createTheme } from "@material-ui/core/styles";

export const theme = createTheme({
  palette: {
    common: {
      white: "#fff",
    },
    primary: {
      main: "#D72245",
      light: "RGB(255, 240, 240)",
    },
    secondary: {
      main: "#D3264C",
      light: "rgba(247, 150, 79, 0.1)",
    },
  },
  overrides: {
    MuiPaper: {
      elevation2: {
        boxShadow: "0px 0px 15px rgba(0,0,0,0.1)",
      },
      elevation4: {
        boxShadow: "0px 0px 15px rgba(0,0,0,0.2)",
      },
      elevation1: {
        boxShadow: "0px 5px 15px #22222214",
      },
    },
    MuiCard: {
      root: {
        borderRadius: "5px",
      },
    },
    MuiInputLabel: {
      root: {
        fontFamily: "muli_regular",
        fontSize: 14,
      },
    },
    MuiButton: {
      root: {
        textTransform: "capitalize",
        fontFamily: "muli_bold",
      },
      contained: {
        boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
        padding: "8px 20px",
      },
      outlined: {
        padding: "8px 20px",
      },
    },
    MuiDialog: {
      root: {
        backdropFilter: "blur(2px)",
      },
      paper: {
        borderRadius: 20,
      },
    },
    MuiTypography: {
      root: {
        fontFamily: "muli_regular",
      },
      h1: {
        fontSize: 40,
        fontFamily: "muli_bold",
        lineHeight: "50px",
      },

      h2: {
        fontSize: 36,
        fontFamily: "muli_bold",
      },
      h3: {
        fontSize: 30,
        fontFamily: "muli_medium",
      },
      h4: {
        fontSize: 24,
        fontFamily: "muli_medium",
      },
      h5: {
        fontSize: 20,
        fontFamily: "muli_medium",
      },

      h6: {
        fontSize: 18,
        fontFamily: "muli_medium",
      },
      body1: {
        fontSize: 16,
        fontFamily: "muli_medium",
        lineHeight: "30px",
      },
      body2: {
        fontSize: 14,
        fontFamily: "muli_regular",
      },
      subtitle1: {
        fontSize: 18,
        fontFamily: "muli_medium",
      },
      subtitle2: {
        fontSize: 16,
        fontFamily: "muli_medium",
      },
      caption: {
        fontSize: 12,
        fontFamily: "muli_regular",
      },
    },
  },
});

export default theme;
