import { Box } from "@mui/material";
import image from "../assets/images/dashboardImgs/High School-cuate.png";
import { BoldTypo } from "./Typography/BoldTypo";
import { LightTypo } from "./Typography/LightTypo";
export default function EmptyScreen({
  heading,
  subHeading,
  additionalStyling,
}) {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginLeft: additionalStyling ? "-16rem" : "",
          marginTop: additionalStyling ? "7rem" : "",
        }}
      >
        <img src={image} />
        <BoldTypo sx={{ marginTop: "1rem" }}>{heading}</BoldTypo>
        <LightTypo sx={{ marginTop: "1rem" }}>{subHeading}</LightTypo>
      </Box>
    </>
  );
}
