import { Box, CircularProgress, Typography } from '@material-ui/core'
import PublishOutlinedIcon from '@material-ui/icons/PublishOutlined'
import { useState } from 'react'
import { http } from '../services/http'

function CustomUpload({ name, onChange, label = 'Tap to upload' }) {
	let [fileName, setFileName] = useState(null)
	const [loading, setLoading] = useState(false)
	const [fileUrl, setFileUrl] = useState('')

	function handleChange(e) {
		if (e.target.files[0]) {
			const formData = new FormData()
			formData.append('file', e.target.files[0])
			setFileName(e.target.files[0].name)
			setLoading(true)
			http
				.post('/common/upload', formData)
				.then((res) => {
					onChange(res.data.key)
					setFileUrl(res.data.Location)
					setLoading(false)
				})
				.catch((err) => {
					setLoading(false)
					console.log(err)
				})
		}
	}

	return (
		<>
			<label htmlFor={name} className='cursor_pointer'>
				<Box
					borderRadius={8}
					textAlign='center'
					py={2}
					style={{ cursor: 'pointer' }}
					width='100%'
				>
					{loading ? (
						<CircularProgress />
					) : !fileUrl ? (
						<>
							<PublishOutlinedIcon color='disabled' />
							<Typography variant='body2' color='textSecondary'>
								{fileName ? fileName : label}
							</Typography>
						</>
					) : (
						<img height={150} width='auto' src={fileUrl} alt={label} />
					)}
				</Box>
			</label>
			<input
				onChange={handleChange}
				type='file'
				style={{ display: 'none' }}
				id={name}
			/>
		</>
	)
}

export default CustomUpload
