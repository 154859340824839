import { Box, Typography } from "@material-ui/core";
import {
  Link,
  useHistory,
  useLocation,
  Route,
  useRouteMatch,
} from "react-router-dom";
import tabs from "./tabs";
import ArrowBackIcon from "@material-ui/icons/KeyboardBackspace";
const Layout = ({ children }) => {
  const history = useHistory();
  const match = useRouteMatch();
  const location = useLocation();

  const userId = new URLSearchParams(location.search).get("userId");

  const getActiveTab = (tabName) => {
    const currentPath = history.location.pathname.split("/")[3];
    return currentPath === tabName.slice(1);
  };

  return (
    <div>
      {history.location.pathname !== "/registrations" && (
        <Box
          display="flex"
          // justifyContent="space-between"
          alignItems="center"
          bgcolor="#F4F4F4"
          style={{ listStyle: "none" }}
          px={4}
        >
          <Box>
            <Link style={{ color: "black" }} to="/registrations">
              <ArrowBackIcon />
            </Link>
          </Box>
          <Box display="flex" gridGap={40} margin="auto">
            {tabs.map((item) => {
              const border = getActiveTab(item.path);

              return (
                <Link
                  to={{
                    pathname: match.url + item.path,
                    search: userId && `?userId=${userId}`,
                  }}
                >
                  <Box py={1} borderBottom={border ? "2px solid #D72245" : ""}>
                    <Typography
                      style={{ color: "black" }}
                      component="li"
                      variant="h6"
                    >
                      {item.name}
                    </Typography>
                  </Box>
                </Link>
              );
            })}
          </Box>
        </Box>
      )}
      <Box>
        {tabs.map((item) => {
          return (
            <Route
              key={item.path}
              path={`${match.path}${item.path}`}
              component={item.Component}
            />
          );
        })}
      </Box>
    </div>
  );
};

export default Layout;
