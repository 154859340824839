import {
  Box,
  FormControl,
  InputAdornment,
  styled,
  TextField,
} from "@material-ui/core";
import React from "react";
import { LightTypo } from "./Typography/LightTypo";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import moment from "moment";
export default function StyledTextField({
  title,
  value,
  onChange,
  rows,
  placeholder,
  fieldName,
  multiline = false,
  type,
  required,
}) {
  function handleChange(e) {
    onChange(fieldName, e.target.value);
  }
  return (
    <Box>
      <FormControl
        style={{
          width: "100%",
        }}
      >
        {/* <Box> */}
        <LightTypo>
          {title} {required && <span style={{ color: "red" }}>*</span>}
        </LightTypo>
        {/* </Box> */}
        <CustomTextField
          variant="outlined"
          required={required}
          size="small"
          type={type}
          value={
            type === "date"
              ? value
                ? moment(value).format("YYYY-MM-DD")
                : ""
              : value ?? ""
          }
          multiline={multiline}
          rows={rows}
          placeholder={placeholder}
          onChange={handleChange}
          InputProps={{
            endAdornment: type === "date" && (
              <InputAdornment position="end">
                <AccessTimeIcon style={{ color: "#A9A9A9" }} />
              </InputAdornment>
            ),
          }}
        />
      </FormControl>
    </Box>
  );
}

const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: `0.625rem`,
    },
  },
}));
