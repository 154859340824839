import { Box } from "@material-ui/core";
import StudentProfileLayout from "../../../Layout/StudentProfileLayout";
import Section from "./Section";

const MyFavourites = () => {
  return (
    <StudentProfileLayout>
      <Section />
    </StudentProfileLayout>
  );
};

export default MyFavourites;
