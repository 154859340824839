import { Icon } from "@material-ui/core";
import { makeStyles, styled } from "@material-ui/core/styles";
import FastfoodIcon from "@material-ui/icons/Fastfood";
import Timeline from "@material-ui/lab/Timeline";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import BugReportIcon from "@material-ui/icons/BugReport";
import FlightIcon from "@material-ui/icons/Flight";
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "6px 16px",
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

export default function JourneyTimelineItem({
  flightPosition,
  cardPosition,
  children,
}) {
  const classes = useStyles();

  return (
    <StyledTimelineItem>
      <StyledTimelineSeperator>
        <StyledTimelineIcon>
          {flightPosition === cardPosition ? (
            <FlightIcon />
          ) : flightPosition > cardPosition ? (
            <CheckCircleIcon />
          ) : (
            <BugReportIcon />
          )}
        </StyledTimelineIcon>
        <TimelineConnector />
      </StyledTimelineSeperator>
      <StyledTimelineContent style={{ py: "12px", px: 2 }}>
        {children}
      </StyledTimelineContent>
    </StyledTimelineItem>
  );
}

export const StyledTimeline = styled(Timeline)(({ them }) => ({
  "&.MuiTimeline-root": {
    // padding: "0 16px",
    // margin: "0.5rem",
  },
}));
const StyledTimelineItem = styled(TimelineItem)(({ theme }) => ({
  "&.MuiTimelineItem-root ": {
    // alignItems: "center",
    // flex: 0,
  },

  "&.MuiTimelineItem-root::before": {
    content: "none",
    // flex: 0,
  },
  padding: "0",
}));

const StyledTimelineContent = styled(TimelineContent)(({ theme }) => ({
  "&.MuiTimelineContent-root": {
    // padding: "0 16px",
  },
}));

export const StyledTimelineSeperator = styled(TimelineSeparator)(
  ({ theme }) => ({
    "span.MuiTimelineConnector-root": {
      // position: "absolute",
      // top: 10,
      // height: "100%",
      // flex: 1,
      // flexGrow: 1,
      // flexDirection: "column",
      // zIndex: "1",
      // color: "red",
    },
  })
);

export const StyledTimelineIcon = styled(Icon)(({ theme }) => ({
  "&.MuiIcon-root": {
    margin: "1rem",
  },
  // img: {
  //   zIndex: "11",
  //   width: "100%",
  //   height: "100%",
  //   background: "white",
  // },
}));
