import React, { useState } from "react";
import { useTheme, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { useQueryClient } from "@tanstack/react-query";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  NativeSelect,
  Select,
  TextField,
} from "@material-ui/core";
import { InputAdornment } from "@mui/material";
import moment from "moment";
import { useHistory } from "react-router-dom";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <Button
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          style={{ fontSize: "16px" }}
        >
          Cancel
        </Button>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const initState = {
  admissionDecision: "",
  i20Status: "",
  approvalDate: "",
  comments: "",
};
export default function ApprovalDialog({
  courseId,
  univId,
  handleClose,
  open,
  handleChange,
  updateApprovalStatus,
  shortlistBody,
  isUpdate,
}) {
  const [state, setState] = useState(shortlistBody);
  const queryClient = useQueryClient();
  const router = useHistory();
  const userId = new URLSearchParams(window.location.search).get("userId");

  const handleUpdate = (field, value) => {
    setState({ ...state, [field]: value });
    handleChange(field, value);
    console.log(field, value);
  };
  console.log(state);
  const handleDialogClose = () => {
    handleClose();
    setState(initState);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    handleClose();
    updateApprovalStatus();
    router.push(`/view-approved-universities?userId=${userId}`);
  };
  return (
    <div>
      <Dialog
        onClose={handleDialogClose}
        aria-labelledby="simple-dialog-title"
        open={open}
        maxWidth="xs"
        fullWidth
      >
        <form onSubmit={handleSubmit}>
          <DialogTitle id="simple-dialog-title" onClose={handleDialogClose}>
            University Approvals
          </DialogTitle>
          <DialogContent dividers>
            <Box style={{ display: "grid", gap: "1rem" }}>
              <Box style={{ display: "grid", gap: "0.5rem" }}>
                <InputLabel htmlFor="">
                  Admission decision<span style={{ color: "red" }}>*</span>
                </InputLabel>
                <FormControl
                  variant="filled"
                  size="small"
                  style={{ width: "100%" }}
                >
                  <Select
                    variant="outlined"
                    id="adDecision"
                    value={shortlistBody?.admissionDecision}
                    onChange={(e) =>
                      handleUpdate("admissionDecision", e.target.value)
                    }
                    required
                  >
                    <MenuItem value="Select" disabled selected>
                      Select
                    </MenuItem>
                    <MenuItem value={"ADMITTED"}>Admitted</MenuItem>
                    <MenuItem value={"DENIED"}>Denied</MenuItem>
                    <MenuItem value={"HOLD"}>Hold</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box style={{ display: "grid", gap: "0.5rem" }}>
                <InputLabel htmlFor="">
                  I20 / Offer Status<span style={{ color: "red" }}>*</span>
                </InputLabel>
                <FormControl
                  variant="filled"
                  size="small"
                  style={{ width: "100%" }}
                >
                  <Select
                    variant="outlined"
                    id="adDecision"
                    value={shortlistBody?.i20Status}
                    onChange={(e) => handleUpdate("i20Status", e.target.value)}
                    required
                  >
                    <MenuItem value="Select" disabled selected>
                      Select
                    </MenuItem>
                    <MenuItem value={"RECEIVED"}>Recieved</MenuItem>
                    <MenuItem value={"NOT_RECEIVED"}>Not Received</MenuItem>
                    <MenuItem value={"HOLD"}>Hold</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <InputLabel htmlFor="">
                {"Approved date"}
                <span style={{ color: "red" }}>*</span>
              </InputLabel>
              <TextField
                type={"date"}
                variant="outlined"
                size="small"
                value={
                  shortlistBody?.approvalDate
                    ? moment(shortlistBody?.approvalDate).format("YYYY-MM-DD")
                    : ""
                }
                required
                onChange={(e) => handleUpdate("approvalDate", e.target.value)}
              ></TextField>
              <Box>
                <TextField
                  variant="outlined"
                  multiline
                  value={shortlistBody?.comments}
                  onChange={(e) => handleUpdate("comments", e.target.value)}
                  rows={4}
                  style={{ width: "100%" }}
                  placeholder={"Write your comments..."}
                  required
                />
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              type="submit"
              color="secondary"
              variant="contained"
              style={{ margin: "auto" }}
            >
              {isUpdate ? "Update" : "Submit"}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
