import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import CustomUpload from "../../components/CustomUpload";
import { http } from "../../services/http";
import { useHistory, useRouteMatch } from "react-router-dom";
import VisaOptions from "./VisaOptions";
import ImageWithUploadButton from "../../components/Button/ImageWithUploadButton";
import { KeyboardBackspace } from "@material-ui/icons";

function EditCountry() {
  const router = useHistory();
  const match = useRouteMatch();
  const [state, setState] = useState({});
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    setLoading(true);
    http
      .get(`/countries/${match.params.id}`)
      .then((res) => {
        let data = {
          ...res.data,
          quickFacts: JSON.parse(res.data.quickFacts),
          visaOptions: JSON.parse(res.data.visaOptions),
        };
        if (res.data.visaOptions == null) {
          data = {
            ...data,
            visaOptions: [
              {
                name: "",
                applicationFee: "",
                validity: "",
                about: "",
                requirements: "",
              },
            ],
          };
        }
        setState(data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function handleChange(e) {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  }

  function handleQuickFacts(e) {
    setState({
      ...state,
      quickFacts: {
        ...state.quickFacts,
        [e.target.name]: e.target.value,
      },
    });
  }

  function handleContentChange(key, v) {
    console.log(v);
    setState({
      ...state,
      [key]: v,
    });
  }

  function handleSubmit() {
    http
      .patch(`/countries/${match.params.id}`, state)
      .then((res) => {
        router.push("/countries");
      })
      .catch((err) => {
        console.log(err);
      });
  }

  if (loading) return <CircularProgress />;

  return (
    <Box p={3}>
      <Box
        display="flex"
        gridGap={20}
        alignItems="center"
        sx={{ marginLeft: "-1rem", marginBottom: "1.5rem" }}
      >
        <IconButton onClick={() => history.goBack()}>
          <KeyboardBackspace />
        </IconButton>
        <Typography variant="h6">Edit Country</Typography>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Box>
            <TextField
              onChange={handleChange}
              fullWidth
              value={state?.name}
              variant="outlined"
              margin="dense"
              label="Name"
              name="name"
            />
          </Box>
          <Box>
            <TextField
              onChange={handleChange}
              fullWidth
              value={state?.order}
              variant="outlined"
              margin="dense"
              label="Order"
              name="order"
            />
          </Box>
          <Box mt={1}>
            <Typography variant="h6" gutterBottom color="primary">
              Image
            </Typography>
            <Box border="1px solid rgba(0, 0, 0, 0.1)" borderRadius={10}>
              <ImageWithUploadButton
                src={state?.imageUrl}
                state={state}
                setState={setState}
                field={"image"}
              />
            </Box>
          </Box>
          <Box mt={2}>
            <Typography variant="h6" color="primary">
              Quick Facts
            </Typography>
            <Box>
              <TextField
                fullWidth
                onChange={handleQuickFacts}
                variant="outlined"
                value={state?.quickFacts?.capital}
                margin="dense"
                label="Capital"
                name="capital"
              />
            </Box>
            <Box mt={1}>
              <TextField
                fullWidth
                onChange={handleQuickFacts}
                variant="outlined"
                value={state?.quickFacts?.languages}
                margin="dense"
                label="Languages"
                name="languages"
              />
            </Box>
            <Box mt={1}>
              <TextField
                fullWidth
                variant="outlined"
                margin="dense"
                label="GDP"
                name="gdp"
                value={state?.quickFacts?.gdp}
                onChange={handleQuickFacts}
              />
            </Box>
            <Box mt={1}>
              <TextField
                fullWidth
                variant="outlined"
                margin="dense"
                value={state?.quickFacts?.currency}
                label="Currency"
                name="currency"
                onChange={handleQuickFacts}
              />
            </Box>
            <Box mt={1}>
              <TextField
                fullWidth
                variant="outlined"
                margin="dense"
                label="Population"
                value={state?.quickFacts?.population}
                name="population"
                onChange={handleQuickFacts}
              />
            </Box>
            <Box mt={1}>
              <TextField
                fullWidth
                variant="outlined"
                margin="dense"
                label="Dialing Code"
                name="dialingCode"
                value={state?.quickFacts?.dialingCode}
                onChange={handleQuickFacts}
              />
            </Box>
            <VisaOptions state={state} setState={setState} />
            <Box mt={1}>
              <Box>
                <Typography variant="h6" gutterBottom color="primary">
                  Intakes
                </Typography>
                <TextField
                  fullWidth
                  onChange={handleChange}
                  value={state?.intakes}
                  variant="outlined"
                  margin="dense"
                  label="Intakes"
                  name="intakes"
                />
                <Typography color="textSecondary" variant="caption">
                  Enter Intakes separate with comma.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box>
            <Typography variant="h6" gutterBottom color="primary">
              Overview
            </Typography>
            <TextField
              fullWidth
              onChange={handleChange}
              value={state?.overview}
              variant="outlined"
              rows={7}
              multiline
              name="overview"
              placeholder="Overview"
            />
          </Box>
          <Box mt={2}>
            <Typography variant="h6" gutterBottom color="primary">
              Culture
            </Typography>
            <TextField
              fullWidth
              onChange={handleChange}
              variant="outlined"
              value={state?.culture}
              rows={7}
              multiline
              name="culture"
              placeholder="Culture"
            />
          </Box>
          <Box mt={2}>
            <Typography variant="h6" gutterBottom color="primary">
              Admission Process
            </Typography>
            <ReactQuill
              value={state?.admissionProcess}
              onChange={(v) => handleContentChange("admissionProcess", v)}
            />
          </Box>
          <Box mt={2}>
            <Typography variant="h6" gutterBottom color="primary">
              Part time jobs
            </Typography>
            <ReactQuill
              value={state?.partTimeJobs}
              onChange={(v) => handleContentChange("partTimeJobs", v)}
            />
          </Box>
          <Box mt={2} textAlign="right">
            <Button
              onClick={handleSubmit}
              size="medium"
              variant="contained"
              color="secondary"
            >
              Submit
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default EditCountry;
