import React, { useState } from "react";
import { BoldTypo } from "../../components/Typography/BoldTypo";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Text,
  TextField,
} from "@mui/material";
import { Search } from "@material-ui/icons";
import { http } from "../../services/http";
import { LightTypo } from "../../components/Typography/LightTypo";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import DP from "../../assets/images/dashboardImgs/levelsOfStudyImg.png";
import ExamCard from "./ExamCard";
import LanguageCard from "./LanguageCard";
function PreferredLanguages() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const navigate = useHistory();

  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    setLoading(true);
    try {
      const res = await http.get(
        "/users/dashboard/student-preferences/language"
      );
      console.log(res);
      setData(res.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  //   const SearchInput = ({ getData }) => {
  //     const [search, setSearch] = useState("");
  //     const handleSearch = (e) => {
  //       getData({ search });
  //     };
  //     return (
  //       <TextField
  //         variant="outlined"
  //         fullWidth
  //         size="small"
  //         value={search}
  //         onChange={(e) => setSearch(e.target.value)}
  //         placeholder="Search for a University"
  //         InputProps={{
  //           endAdornment: (
  //             <InputAdornment position="end">
  //               <IconButton
  //                 onClick={handleSearch}
  //                 type="submit"
  //                 color="secondary"
  //                 size="large"
  //               >
  //                 <Search fontSize="small" />
  //               </IconButton>
  //             </InputAdornment>
  //           ),
  //         }}
  //       />
  //     );
  //   };
  if (loading) return <CircularProgress />;

  return (
    <div>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <ArrowBackIcon
          sx={{ color: "#D72245", cursor: "pointer", fontSize: "medium" }}
          onClick={() => navigate.goBack()}
        />
        <BoldTypo style={{ color: "#D72245", marginLeft: "0.3rem" }}>
          Languages
        </BoldTypo>
      </Box>
      {/* <Box sx={{ width: "435px" }}>
        <SearchInput getData={getData} />
      </Box> */}
      <Grid container style={{ marginTop: 10 }} spacing={2}>
        {data?.map((item, ind) => {
          return (
            <Grid item xs={6}>
              <LanguageCard data={item} />
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}

export default PreferredLanguages;
