import {
  Box,
  CircularProgress,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { Pagination } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SearchField from "../../components/universities/SearchField";
import SearchUniversities from "../../components/universities/SearchField";
import { UniversityCard } from "../../components/universities/UniversityCard";
import { http } from "../../services/http";

const initState = {
  search: "",
  offset: 0,
  page: 1,
};

export default function Universities() {
  const classes = useStyles();
  const [univParams, setUnivParams] = useState(initState);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getUniversities();
  }, [univParams]);

  function getUniversities() {
    setLoading(true);
    http
      .get("/universities", {
        params: {
          ...univParams,
        },
      })
      .then((res) => {
        setData(res.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handlePagination(e, value) {
    setUnivParams({ ...univParams, page: value, offset: (value - 1) * 10 });
  }
  function handleSearch(e) {
    setUnivParams({ ...univParams, search: e.target.value });
  }
  return (
    <>
      <Box style={{ display: "flex" }}>
        <Box style={{ flexBasis: "60%" }}>
          <SearchField
            handleSearch={handleSearch}
            value={univParams.search}
            placeholder="Search for a University"
          />
          <Box style={{}}>
            {loading ? (
              <CircularProgress
                style={{
                  margin: "10vw 0 0 50%",
                }}
              ></CircularProgress>
            ) : (
              <>
                {data?.data?.map((item) => (
                  <Link to={`/universities/${item.slug}`} key={item.id}>
                    <UniversityCard {...item} />
                  </Link>
                ))}
                <Pagination
                  count={Math.ceil(data?.count / 10)}
                  color="primary"
                  onChange={handlePagination}
                  page={univParams.page}
                />
              </>
            )}
          </Box>
        </Box>
        <Box></Box>
      </Box>
    </>
  );
}
//universities/courses
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));
