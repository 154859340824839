import {
  Box,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";

import { useTheme } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import { BoldTypo } from "../../components/Typography/BoldTypo";
import { LightTypo } from "../../components/Typography/LightTypo";
import { http } from "../../services/http";
import { Link } from "react-router-dom";

import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import Country from "../../assets/images/dashboardImgs/Countries.svg";
import University from "../../assets/images/dashboardImgs/Universities.svg";
import Exam from "../../assets/images/dashboardImgs/Exams.svg";
import Language from "../../assets/images/dashboardImgs/Languages.png";
import AreaOfStudy from "../../assets/images/dashboardImgs/areaOfStudy.svg";
import Scholarships from "../../assets/images/dashboardImgs/Scholarships.svg";
import LevelOfStudy from "../../assets/images/dashboardImgs/levelOfStudy.svg";
import studentConsult from "../../assets/images/dashboardImgs/studentConsult.svg";
import MentorAssigned from "../../assets/images/dashboardImgs/MentorAssigned.svg";
import userIcon from "../../assets/images/dashboardImgs/userIcon.svg";
import ProPurchase from "../../assets/images/dashboardImgs/ProPurchase.svg";
import Docs from "../../assets/images/dashboardImgs/Docs.svg";
import sopLorServices from "../../assets/images/dashboardImgs/sopLorServices.svg";
import Loan from "../../assets/images/dashboardImgs/Loan.svg";
import univApplied from "../../assets/images/dashboardImgs/univApplied.svg";
import univApproval from "../../assets/images/dashboardImgs/univApproval.png";
import StudentChoice from "../../assets/images/dashboardImgs/Studentschoice.svg";
import visaSlotBook from "../../assets/images/dashboardImgs/visaSlotBook.svg";
import visaApproval from "../../assets/images/dashboardImgs/visaApproval.png";
import TravelAssitance from "../../assets/images/dashboardImgs/TravelAssistance.svg";
import FlighTicketBook from "../../assets/images/dashboardImgs/FlighTicketBook.svg";
import Testimonial from "../../assets/images/dashboardImgs/Testimonial.png";
import UniversityJoining from "../../assets/images/dashboardImgs/UniversityJoining.svg";
import consMentors from "../../assets/images/dashboardImgs/consMentors.svg";
import EmptyUniversityScreen from "../../assets/images/dashboardImgs/High School-cuate.png";

const Images = {
  country: Country,
  university: University,
  exam: Exam,
  language: Language,
  areaOfStudy: AreaOfStudy,
  scholarship: Scholarships,
  levelOfStudy: LevelOfStudy,
  START: studentConsult,
  MENTOR_ASSIGNED: MentorAssigned,
  PROFILE_PREFERENCES: userIcon,
  PRO_PURCHASE: ProPurchase,
  DOCS: Docs,
  SHORT_LISTED_UNIVERSITIES: University,
  SOP_LOR_SERVICE: sopLorServices,
  SOP_LOR_DOCS: Docs,
  FINANCIAL_ASSISTANCE: Loan,
  SCHOLARSHIP: Scholarships,
  APPLICATIONS: univApplied,
  UNIVERSITY_APPROVAL: univApproval,
  STUDENTS_CHOICE: StudentChoice,
  VISA_SLOT: visaSlotBook,
  VISA_APPROVAL: visaApproval,
  TRAVEL_ASSISTANCE: TravelAssitance,
  FLIGHT_DETAILS: FlighTicketBook,
  TESTIMONIAL: Testimonial,
  UNIVERSITY_JOINING: UniversityJoining,
};

const StudentPrefs = {
  country: "Countries",
  university: "Universities",
  exam: "Exams",
  language: "Languages",
  areaOfStudy: "Area of study",
  scholarship: "Scholarships",
  levelOfStudy: "Level of study",
};

const StudentJourney = {
  START: "Student Mentor Consultation",
  MENTOR_ASSIGNED: "Student Mentor Assigned",
  PROFILE_PREFERENCES: "Student Profile & Preferences",
  PRO_PURCHASE: "Stuzee Pro Purchases",
  DOCS: "Student Documents",
  SHORT_LISTED_UNIVERSITIES: "Shortlist Universities",
  SOP_LOR_SERVICE: "SOP & LOR Services",
  SOP_LOR_DOCS: "SOP & LOR Documents",
  FINANCIAL_ASSISTANCE: "Student Financial Assistance",
  SCHOLARSHIP: "Student Scholarship",
  APPLICATIONS: "University Applied",
  UNIVERSITY_APPROVAL: "University Approvals",
  STUDENTS_CHOICE: "Student's Choice",
  VISA_SLOT: "Visa Slot Booking",
  VISA_APPROVAL: "Visa Approval",
  TRAVEL_ASSISTANCE: "Travel Assistance",
  FLIGHT_DETAILS: "Flight Details",
  TESTIMONIAL: "Testimonials Collected",
  UNIVERSITY_JOINING: "University Joining",
};

const StudentPrefsInnerLinks = {
  university: "/preferred-countries",
  country: "/preferred-countries",
  areaOfStudy: "/preferred-areas-of-study",
  levelOfStudy: "/preferred-levels-of-study",
  exam: "/preferred-exams",
  language: "/preferred-languages",
  scholarship: "/",
};

const StudentJourneyInnerLinks = {
  START: "/mentor-consultation",
  MENTOR_ASSIGNED: "/mentor-assigned",
  PROFILE_PREFERENCES: "/profile-preferences",
  PRO_PURCHASE: "/pro-purchases",
  DOCS: "/documents",
  SHORT_LISTED_UNIVERSITIES: "/shortlisted-univs",
  SOP_LOR_SERVICE: "/sop-lor-services",
  SOP_LOR_DOCS: "/sop-lor-documents",
  FINANCIAL_ASSISTANCE: "/financial-assistance",
  SCHOLARSHIP: "/students-of-scholarship",
  APPLICATIONS: "/university-applications",
  UNIVERSITY_APPROVAL: "/univ-approvals",
  STUDENTS_CHOICE: "/choice-of-student",
  VISA_SLOT: "/visa-slot-booking",
  VISA_APPROVAL: "/visa-approval",
  TRAVEL_ASSISTANCE: "/travel-assistance",
  FLIGHT_DETAILS: "/flight-details",
  TESTIMONIAL: "/testimonial",
  UNIVERSITY_JOINING: "/university-joining",
};

function Dashboard() {
  const [studentPrefs, setStudentPrefs] = useState(null);
  const [studentJourney, setStudentJourney] = useState(null);
  const [userAnalytics, setuserAnalytics] = useState(null);
  const [loading, setLoading] = useState(false);

  const Prefs = [];

  for (let key in StudentPrefs) {
    Prefs.push({
      type: StudentPrefs[key],
      value: studentPrefs?.filter((item) => item["type"] === key)[0]?.count,
      key: key,
      link: StudentPrefsInnerLinks[key],
    });
  }

  const JourneyAnalytics = [];

  for (let key in StudentJourney) {
    JourneyAnalytics.push({
      type: StudentJourney[key],
      value: studentJourney?.filter((item) => item["status"] === key)[0]?.count,
      key: key,
      link: StudentJourneyInnerLinks[key],
    });
  }

  console.log(studentJourney?.filter((item) => item["status"]));

  useEffect(() => {
    getStudentPrefsData();
  }, []);

  useEffect(() => {
    getStudentJourneyData();
  }, []);

  useEffect(() => {
    getUserAnalyticsData();
  }, []);

  const getStudentPrefsData = async () => {
    setLoading(true);
    try {
      const res = await http.get("/users/dashboard/student-preferences");
      setStudentPrefs(res.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const getStudentJourneyData = async () => {
    setLoading(true);
    try {
      const res = await http.get("/journeys/dashboard");
      setStudentJourney(res.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const getUserAnalyticsData = async () => {
    setLoading(true);
    try {
      const res = await http.get("/users/user-analytics");
      setuserAnalytics(res.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  if (loading) return <CircularProgress />;

  return (
    <Box>
      <Box>
        <BoldTypo>User Analytics</BoldTypo>
        <Grid container style={{ marginTop: 10 }} spacing={2}>
          <Grid item xs={4}>
            <CustomAnalyticsCard
              label="Number of Registered Students"
              value={userAnalytics?.studentsCount}
              imgSrc={userIcon}
            />
          </Grid>
          <Grid item xs={4}>
            <CustomAnalyticsCard
              label="Number of Consultant Mentors"
              value={userAnalytics?.mentorsCount}
              imgSrc={consMentors}
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ marginTop: "3rem" }}>
        <BoldTypo>Student Preferences</BoldTypo>
        <Grid container style={{ marginTop: 10 }} spacing={2}>
          {Prefs?.map((item, ind) => {
            return (
              <Grid item xs={6}>
                <CustomCard
                  label={item?.type}
                  value={item?.value}
                  imgSrc={Images[item?.key]}
                  innerLinks={item?.link}
                />
              </Grid>
            );
          })}
        </Grid>
      </Box>
      <Box sx={{ marginTop: "3rem" }}>
        <BoldTypo>Student Journey Analytics</BoldTypo>
        <Grid container style={{ marginTop: 10 }} spacing={2}>
          {JourneyAnalytics?.map((item, ind) => {
            return (
              <Grid item xs={6}>
                <CustomCard
                  label={item?.type}
                  value={item?.value}
                  imgSrc={Images[item?.key]}
                  innerLinks={item?.link}
                />
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Box>
  );
}

export default Dashboard;

const CustomCard = ({ label, value, innerLinks, imgSrc }) => {
  const theme = useTheme();
  // console.log(require({ imgSrc }));
  return (
    // <Card>
    <Paper
      p={2}
      minHeight="100px"
      elevation={2}
      style={{
        fontWeight: "bold",
        borderRadius: "15px",
      }}
    >
      <Box
        sx={{
          padding: "30px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Box>
            <img
              src={imgSrc}
              alt="mySvgImage"
              style={{ width: "51px", height: "51px" }}
            />
          </Box>
          <Typography variant="h6" gutterBottom style={{ marginTop: "0.5rem" }}>
            {label}
          </Typography>
        </Box>
        <Box>
          <LightTypo>Number of students</LightTypo>
          <Typography variant="h6">{value}</Typography>
        </Box>
      </Box>
      {innerLinks && (
        <Link to={innerLinks}>
          <Box
            sx={{
              padding: "10px 30px",
              borderTop: "1px solid #efefef",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6" style={{ color: "#d3264c" }}>
              View
            </Typography>
            <ArrowRightAltIcon sx={{ color: "#d3264c" }} />
          </Box>
        </Link>
      )}
    </Paper>
    // </Card>
  );
};

const CustomAnalyticsCard = ({ label, value, imgSrc }) => {
  const theme = useTheme();
  // console.log(require({ imgSrc }));
  return (
    // <Card>
    <Paper
      p={2}
      minHeight="100px"
      elevation={2}
      style={{
        fontWeight: "bold",
        borderRadius: "15px",
      }}
    >
      <Box
        sx={{
          padding: "30px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box>
            <img
              src={imgSrc}
              alt="mySvgImage"
              style={{ width: "51px", height: "51px" }}
            />
          </Box>
          <LightTypo style={{ marginLeft: "0.8rem" }}>{label}</LightTypo>
        </Box>
        <Box>
          <Typography variant="h6">{value}</Typography>
        </Box>
      </Box>
    </Paper>
    // </Card>
  );
};
