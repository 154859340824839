import {
  Box,
  CircularProgress,
  IconButton,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { http } from "../../services/http";
import {
  CardsContainer,
  CourseCard,
  FlexBox,
  ScoreCards,
  Stats,
} from "../container";

import { BoldTypo } from "../Typography/BoldTypo";
import { LightTypo } from "../Typography/LightTypo";
import { useQuery } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { useQueryClient } from "@tanstack/react-query";
import { OutlinedButton } from "../OutlinedButton";
import CourseActions from "../courses/CourseActions";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import moment from "moment";
import StudentsChoiceDialog from "./StudentsChoiceDialog";
import EmptyScreen from "../EmptyScreen";

const initState = {
  appliedCourseId: null,
  studentsChoice: false,
  intake: [],
  status: "",
};
export default function StudentsChoice() {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [shortlistBody, setShortlistBody] = useState(initState);
  const userId = new URLSearchParams(window.location.search).get("userId");

  let { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const theme = useTheme();
  const classes = useStyles();

  const handleClickOpen = (obj) => {
    console.log(obj);

    setShortlistBody({ ...shortlistBody, ...obj });
    setOpen(true);
    console.log(shortlistBody);
  };

  const handleChange = (field, value) => {
    setShortlistBody({ ...shortlistBody, [field]: value });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const updateStudentsChoice = () => {
    http
      .put(`/application/students-choice/user/${userId}`, {
        ...shortlistBody,
        userId,
      })
      .then(() => {
        queryClient.invalidateQueries("studentsChoice");
        setShortlistBody(initState);
      });
  };

  const getAppliedUnivs = () => {};

  const { data: appliedUnivs, isLoading } = useQuery(
    ["appliedUnivs"],
    () =>
      http.get(`/application/user/${userId}`).then((res) => {
        console.log(res);

        return res?.data;
      }),
    {
      onSuccess: (data) => {
        setData(data);
      },
    }
  );
  console.log(data);
  if (isLoading) {
    return (
      <Box mt={10} textAlign="center">
        <CircularProgress />
      </Box>
    );
  }
  return (
    <>
      <CardsContainer
        color={theme.palette.primary.main}
        title={"Approved Courses"}
      >
        <>
          {data?.length ? (
            data?.map((univ, index) => {
              return (
                <>
                  <FlexBox style={{ margin: "1rem" }}>
                    <img
                      src={univ?.university?.logoUrl}
                      style={{
                        width: "100px",
                        borderRadius: "10px",
                        boxShadow: "0px 3px 5px #273B801A",
                      }}
                      alt={univ?.university?.name}
                    />
                    <Box style={{ padding: "1rem" }}>
                      <BoldTypo color="primary">
                        {univ?.university?.name}
                      </BoldTypo>
                      <LightTypo>
                        {univ?.university?.city +
                          ", " +
                          univ?.university?.country}{" "}
                      </LightTypo>
                    </Box>
                  </FlexBox>
                  {univ?.appliedCourses?.map((course, index) => {
                    return (
                      <CourseCard>
                        <FlexBox style={{ justifyContent: "space-between" }}>
                          <Box>
                            <BoldTypo color="primary">
                              {course.courseName}
                            </BoldTypo>
                          </Box>
                          <Box>
                            {
                              <OutlinedButton
                                onClick={() =>
                                  handleClickOpen({
                                    appliedCourseId: course?.id,
                                    status: "STUDENTS_CHOICE",
                                  })
                                }
                                // style={{ height: "fit-content", margin: "auto" }}
                              >
                                {"Update Student's Choice"}
                              </OutlinedButton>
                            }
                          </Box>
                        </FlexBox>

                        <FlexBox
                          style={{
                            marginBottom: "1rem",
                            gap: "2.5rem",
                            marginTop: "1rem",
                          }}
                        >
                          <Stats title="Payment Type" value={course?.feeType} />
                          <Stats
                            title="Fee Amount"
                            value={`${course?.feeAmount} ${univ?.university?.currency}`}
                          />
                          <Stats
                            title="Application Date"
                            value={moment(course?.applicationDate).format(
                              "YYYY-MM-DD"
                            )}
                          />
                          <Stats
                            title="Expected Approval Date"
                            value={moment(course?.followUpDate).format(
                              "YYYY-MM-DD"
                            )}
                          />
                        </FlexBox>
                        <FlexBox
                          style={{
                            marginBottom: "1rem",
                            gap: "2.5rem",
                            marginTop: "1rem",
                          }}
                        >
                          <Stats
                            title="Approval Status"
                            value={
                              course?.admissionDecision
                                ? course?.admissionDecision
                                : "NA"
                            }
                          />
                          <Stats
                            title="Approval Date"
                            value={
                              course?.approvalDate
                                ? moment(course?.approvalDate).format(
                                    "YYYY-MM-DD"
                                  )
                                : "NA"
                            }
                          />
                          <Stats
                            title="I20 Status"
                            value={course?.i20Status ? course?.i20Status : "NA"}
                          />
                          <Stats
                            title="Documents received"
                            value={course?.docsReceived ? "Yes" : "No"}
                          />
                        </FlexBox>
                        <FlexBox
                          style={{
                            marginBottom: "1rem",
                            gap: "2.5rem",
                            marginTop: "1rem",
                          }}
                        >
                          <Stats
                            title="Comments"
                            value={course?.comments ? course?.comments : "NA"}
                          />
                        </FlexBox>
                      </CourseCard>
                    );
                  })}
                </>
              );
            })
          ) : (
            <EmptyScreen heading={"No records found"} />
          )}
        </>
        <StudentsChoiceDialog
          updateStudentsChoice={updateStudentsChoice}
          handleClose={handleClose}
          open={open}
          handleChange={handleChange}
          shortlistBody={shortlistBody}
          isUpdate={true}
        />
      </CardsContainer>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  icon: {
    "&:hover": {
      color: theme.palette.secondary.main,
      backgoundColor: "#FFFF00",
    },
  },
}));
