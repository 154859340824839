import {
  Box,
  Button,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import { KeyboardBackspace } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import CustomUpload from "../../components/CustomUpload";
import ImageWithUploadButton from "../../components/Button/ImageWithUploadButton";
import { http } from "../../services/http";
import ExamPattern from "./ExamPattern";
import HowToRegister from "./HowToRegister";

const ExamsForm = () => {
  let { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const editMode = !!history.location.state;

  useEffect(() => {
    if (history.location.state) {
      setState(history.location.state);
    }
  }, []);

  const [state, setState] = useState({
    name: "",
    title: "",
    image: "",
    logo: "",
    website: "",
    description: "",
    conductedBy: "",
    registrationLink: "",
    validity: "",
    fee: "",
    typeOfExam: "",
    examDuration: "",
    gradingScale: "",
    content: "",
    howToRegister: {
      description: "",
      steps: [""],
    },
    examPattern: {
      description: "",
      subjects: [
        {
          name: "",
          time: "",
          sections: [],
        },
      ],
    },
  });

  const handleChange = (name, value) => {
    console.log(value)
    setState({ ...state, [name]: value });
  };

  const postLevelOfStudy = async (body) => {
    try {
      const res = await http.post("/exams", body);
      enqueueSnackbar("Submitted Successfully", { variant: "success" });
    } catch (err) {
      console.log(err);

      enqueueSnackbar("Error Submitting", { variant: "error" });
    }
  };
  const updateLevelOfStudy = async (body) => {
    try {
      const res = await http.put(`/exams/${body?.id}`, body);
      enqueueSnackbar("Updated Successfully", { variant: "success" });
    } catch (err) {
      enqueueSnackbar("Error Submitting", { variant: "error" });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    editMode ? updateLevelOfStudy(state) : postLevelOfStudy(state);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box display="flex" flexDirection="column" gridGap={30}>
        <Box display="flex" gridGap={20} alignItems="center">
          <IconButton onClick={() => history.goBack()}>
            <KeyboardBackspace />
          </IconButton>
          <Typography variant="h6">
            {!editMode ? "Add Exam" : "Edit Exam"}
          </Typography>
        </Box>
        <Box border="1px solid rgba(0, 0, 0, 0.1)" borderRadius={10}>
          {editMode ? (
            <ImageWithUploadButton
              src={state?.logoUrl}
              state={state}
              setState={setState}
              field={"logo"}
            />
          ) : (
            <CustomUpload
              name="logo"
              label="Upload Logo"
              onChange={(value) => handleChange("logo", value)}
            />
          )}
        </Box>
        <CustomTextField
          required
          value={state?.name}
          label="Name"
          onChange={(e) => handleChange("name", e.target.value)}
        />
        <CustomTextField
          required
          value={state?.title}
          label="Title"
          onChange={(e) => handleChange("title", e.target.value)}
        />
        <CustomTextField
          value={state?.website}
          label="Website"
          onChange={(e) => handleChange("website", e.target.value)}
        />

        <CustomTextField
          value={state?.registrationLink}
          label="Registration Link"
          onChange={(e) => handleChange("registrationLink", e.target.value)}
        />
        <CustomTextField
          value={state?.description}
          multiline
          minRows={5}
          label="Description"
          onChange={(e) => handleChange("description", e.target.value)}
        />
        <CustomTextField
          value={state?.conductedBy}
          label="Conducted By"
          onChange={(e) => handleChange("conductedBy", e.target.value)}
        />
        <CustomTextField
          value={state?.validity}
          label="Validity ( in years )"
          type="number"
          onChange={(e) => handleChange("validity", e.target.value)}
        />
        <CustomTextField
          value={state?.fee}
          type="number"
          label="Fee"
          onChange={(e) => handleChange("fee", e.target.value)}
        />
        <CustomTextField
          value={state?.typeOfExam}
          label="Type Of Exam"
          onChange={(e) => handleChange("typeOfExam", e.target.value)}
        />
        <CustomTextField
          value={state?.examDuration}
          type="number"
          label="Exam Duration ( in minutes)"
          onChange={(e) => handleChange("examDuration", e.target.value)}
        />
        <CustomTextField
          value={state?.gradingScale}
          label="Grading Scale"
          onChange={(e) => handleChange("gradingScale", e.target.value)}
        />
        <CustomTextField
          value={state?.content}
          label="Content"
          onChange={(e) => handleChange("content", e.target.value)}
        />

        <HowToRegister state={state} setState={setState} />

        <ExamPattern state={state} setState={setState} />

        <Box display="flex" justifyContent="flex-end">
          <Button type="submit" variant="contained" color="secondary">
            {!editMode ? "Submit" : "Update"}
          </Button>
        </Box>
      </Box>
    </form>
  );
};

export default ExamsForm;

const CustomTextField = ({ label, onChange, value, ...props }) => {
  return (
    <TextField
      fullWidth
      variant="outlined"
      size="small"
      label={label}
      onChange={onChange}
      value={value}
      {...props}
    />
  );
};
