import {
  Box,
  Button,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import { KeyboardBackspace } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ImageWithUploadButton from "../../components/Button/ImageWithUploadButton";
import CustomUpload from "../../components/CustomUpload";
import { http } from "../../services/http";

const LOSForm = () => {
  let { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const editMode = !!history.location.state;

  useEffect(() => {
    if (history.location.state) {
      setState(history.location.state);
    }
  }, []);

  const [state, setState] = useState({
    name: "",
    title: "",
    webinarLink: "",
    image: "",
  });

  const handleChange = (name, value) => {
    
    setState({ ...state, [name]: value });
  };
console.log(state)
  const postLevelOfStudy = async (body) => {
    try {
      const res = await http.post("/level-of-study", body);
      console.log(res)
      enqueueSnackbar("Submitted Successfully", { variant: "success" });
    } catch (err) {
      console.log(err);

      enqueueSnackbar("Error Submitting", { variant: "error" });
    }
  };
  const updateLevelOfStudy = async (body) => {
    try {
      console.log(body)
      const res = await http.put(`/level-of-study/${body?.id}`, body);
      enqueueSnackbar("Updated Successfully", { variant: "success" });
    } catch (err) {
      enqueueSnackbar("Error Submitting", { variant: "error" });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    editMode ? updateLevelOfStudy(state) : postLevelOfStudy(state);
  };
console.log(state)
  return (
    <form onSubmit={handleSubmit}>
      <Box display="flex" flexDirection="column" gridGap={30}>
        <Box display="flex" gridGap={20} alignItems="center">
          <IconButton onClick={() => history.goBack()}>
            <KeyboardBackspace />
          </IconButton>
          <Typography variant="h6">
            {editMode ? "Edit Level Of Study" : "Add Level of Study"}
          </Typography>
        </Box>
        <TextField
          required
          fullWidth
          value={state?.name}
          label="Name"
          variant="outlined"
          onChange={(e) => handleChange("name", e.target.value)}
        />
        <TextField
          required
          fullWidth
          value={state?.title}
          label="Title"
          variant="outlined"
          onChange={(e) => handleChange("title", e.target.value)}
        />

        <Box border="1px solid rgba(0, 0, 0, 0.1)" borderRadius={10}>
          {editMode ? (
            <ImageWithUploadButton
            src={state?.imageUrl}
            state={state}
            setState={setState}
            field = {"image"}
          />
          ) : (
            <CustomUpload
              name="image"
              label="Upload Image"
              onChange={(value) => handleChange("image", value)}
            />
          )}
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <Button type="submit" variant="contained" color="secondary">
            {!editMode ? "Submit" : "Update"}
          </Button>
        </Box>
      </Box>
    </form>
  );
};

export default LOSForm;
