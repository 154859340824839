import { Box, Button, Divider, TextField, Typography } from '@material-ui/core'
import { Satellite } from '@material-ui/icons';
import React, { useEffect } from 'react'
import ReactQuill from 'react-quill'

function VisaOptions({ state, setState }) {

    function handleContentChange(key, index, v) {
        console.log(v)
        const visaOptions = state.visaOptions;
        visaOptions[index][key] = v
        setState({
            ...state, visaOptions: visaOptions
        });
    }

    function addOption() {
        setState({
            ...state, visaOptions: [...state.visaOptions, {
                name: "",
                applicationFee: "",
                validity: "",
                about: "",
                requirements: "",
            }]
        })
    }

    function removeOption(i) {
        const visaOptions = state.visaOptions.filter((item, index) => {
            return item !== state.visaOptions[i]
        })
        setState({
            ...state, visaOptions: visaOptions
        })
    }

    return (
        <div>
            <Box mt={1}>
                <Typography variant="h6" gutterBottom color="primary">
                    Visa Options
                </Typography>
                {state?.visaOptions?.map((item, index) => (
                    <div>
                        <Box display="flex" mt={2} justifyContent="space-around">
                            <Box width="60%">
                                <TextField
                                    fullWidth
                                    value={state?.visaOptions[index]["name"]}
                                    onChange={(e) => handleContentChange("name", index, e.target.value)}
                                    variant="outlined"
                                    margin="dense"
                                    name="name"
                                    label="Visa Option"
                                />
                                <TextField
                                    fullWidth
                                    value={state?.visaOptions[index]["applicationFee"]}
                                    onChange={(e) => handleContentChange("applicationFee", index, e.target.value)}
                                    variant="outlined"
                                    margin="dense"
                                    name="applicationFee"
                                    label="Application Fee"
                                />
                                <TextField
                                    fullWidth
                                    value={state?.visaOptions[index]["validity"]}
                                    onChange={(e) => handleContentChange("validity", index, e.target.value)}
                                    variant="outlined"
                                    margin="dense"
                                    name="validity"
                                    label="Validity"
                                />
                                <Box m={2}>
                                    <Typography variant="h6" gutterBottom color="primary">
                                        About
                                    </Typography>
                                    <ReactQuill
                                        value={state?.visaOptions[index]["about"]}
                                        onChange={(v) => handleContentChange("about", index, v)}
                                    />
                                </Box>
                                <Box m={2}>
                                    <Typography variant="h6" gutterBottom color="primary">
                                        What you will need
                                    </Typography>
                                    <ReactQuill
                                        value={state?.visaOptions[index]["requirements"]}
                                        onChange={(v) => handleContentChange("requirements", index, v)}
                                    />
                                </Box>
                            </Box>
                            {
                                state?.visaOptions.length == index + 1 ?
                                    <Box>
                                        <Button onClick={addOption} variant='outlined'>Add</Button>
                                    </Box>
                                    : ""
                            }
                            {
                                state?.visaOptions.length !== 1 ?
                                    <Box>
                                        <Button onClick={(e) => removeOption(index)} variant='outlined'>Remove</Button>
                                    </Box> : ""
                            }
                        </Box>
                        <Divider />
                    </div>


                ))}

            </Box>
        </div>
    )
}

export default VisaOptions
