import { MenuItem, TextField, Box } from "@material-ui/core";
import React from "react";

function CustomSelect(props) {
  return (
    <Box>
      <TextField
        {...props}
        fullWidth
        select
        value={props.value}
        label={props.label}
        name={props.name}
        onChange={props.onChange}
        variant="outlined"
        margin="dense"
        InputLabelProps={{ shrink: true }}
      >
        {props.options.map((item) => (
          <MenuItem value={item.value}>{item.label}</MenuItem>
        ))}
      </TextField>
    </Box>
  );
}

export default CustomSelect;
