import React from "react";
import JourneyAnalyticsTable from "../../components/JourneyAnalyticsTable";

function MentorConsultations() {
  return (
    <JourneyAnalyticsTable status={"START"} title={"Mentor Consultations"} />
  );
}

export default MentorConsultations;
