import {
  Box,
  Button,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import { Delete, Edit, KeyboardBackspace } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import CustomUpload from "../../components/CustomUpload";
import ImageWithUploadButton from "../../components/Button/ImageWithUploadButton";
import { http } from "../../services/http";

const ScholarshipsForm = () => {
  let { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const editMode = !!history.location.state;

  useEffect(() => {
    if (history.location.state) {
      setState(history.location.state);
    }
  }, []);

  const [state, setState] = useState({
    name: "",
    title: "",
    image: "",
    location: "",
    description: "",
    minimumAmount: "",
    requiredDetails: "",
  });

  const handleChange = (name, value) => {
    setState({ ...state, [name]: value });
  };

  const postLevelOfStudy = async (body) => {
    try {
      const res = await http.post("/scholarship", body);
      enqueueSnackbar("Submitted Successfully", { variant: "success" });
    } catch (err) {
      console.log(err);

      enqueueSnackbar("Error Submitting", { variant: "error" });
    }
  };
  const updateLevelOfStudy = async (body) => {
    try {
      const res = await http.put(`/scholarship/${body?.id}`, body);
      enqueueSnackbar("Updated Successfully", { variant: "success" });
    } catch (err) {
      enqueueSnackbar("Error Submitting", { variant: "error" });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    editMode ? updateLevelOfStudy(state) : postLevelOfStudy(state);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box display="flex" flexDirection="column" gridGap={30}>
        <Box display="flex" gridGap={20} alignItems="center">
          <IconButton onClick={() => history.goBack()}>
            <KeyboardBackspace />
          </IconButton>
          <Typography variant="h6">
            {!editMode ? "Add Scholarship" : "Edit Scholarship"}
          </Typography>
        </Box>
        <TextField
          required
          fullWidth
          value={state?.name}
          label="Name"
          variant="outlined"
          onChange={(e) => handleChange("name", e.target.value)}
        />
        
        <TextField
          fullWidth
          value={state?.location}
          label="Location"
          variant="outlined"
          onChange={(e) => handleChange("location", e.target.value)}
        />
        <TextField
          fullWidth
          value={state?.minimumAmount}
          label="Minimum Amount"
          variant="outlined"
          onChange={(e) => handleChange("minimumAmount", e.target.value)}
        />
        <TextField
          fullWidth
          value={state?.requiredDetails}
          multiline
          minRows={5}
          label="Required Details"
          variant="outlined"
          onChange={(e) => handleChange("requiredDetails", e.target.value)}
        />
        <TextField
          fullWidth
          multiline
          minRows={5}
          value={state?.description}
          label="Description"
          variant="outlined"
          onChange={(e) => handleChange("description", e.target.value)}
        />
        <Box border="1px solid rgba(0, 0, 0, 0.1)" borderRadius={10}>
          {editMode ? (
            <ImageWithUploadButton
              src={state?.imageUrl}
              state={state}
              setState={setState}
              field = {"image"}
            />
          ) : (
            <CustomUpload
              name="image"
              label="Upload Image"
              onChange={(value) => handleChange("image", value)}
            />
          )}
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <Button type="submit" variant="contained" color="secondary">
            {!editMode ? "Submit" : "Update"}
          </Button>
        </Box>
      </Box>
    </form>
  );
};

export default ScholarshipsForm;
