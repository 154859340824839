import {
  Box,
  Button,
  CircularProgress,
  makeStyles,
  TextField,
  Typography,
  useTheme,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import Accordian from "../../components/Accordian";
import { http } from "../../services/http";
import { RectangleCard } from "./my-favorites/Section";

const initState = {
  search: "",
  // offset: 10,
  offset: 0,
  page: 1,
};

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

const Shortlisted = () => {
  const userId = new URLSearchParams(window.location.search).get("userId");

  const [univParams, setUnivParams] = useState(initState);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const classes = useStyles();
  const [reload, setReload] = useState(false);
  const [shortlistedUniv, setShortlistedUniv] = useState([]);

  useEffect(() => {
    getUniversities();
    getShortlistedUniv();
  }, [univParams]);

  function getShortlistedUniv() {
    http.get(`/shortlist/user/${userId}`).then((res) => {
      setShortlistedUniv(res.data.map((item) => item.university));
    });
  }

  function getUniversities() {
    setLoading(true);
    http
      .get("/universities", {
        params: {
          ...univParams,
        },
      })
      .then((res) => {
        setData(res.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handleSearch(e) {
    setUnivParams({ ...univParams, search: e.target.value });
  }

  function handlePagination(e, value) {
    setUnivParams({ ...univParams, page: value, offset: (value - 1) * 5 });
  }
  return (
    <>
      <Box
        style={{
          flexBasis: "60%",
          //   border: "1px solid black",
          boxShadow: "0px 3px 15px #273b8014",

          overflowY: "scroll",
          margin: "1rem 0",
          borderRadius: "1rem",
        }}
        height={"40vh"}
      >
        <Box
          style={{
            position: "sticky",
            top: "5px",
            // zIndex: 1,
          }}
        >
          <form className={classes.root} noValidate autoComplete="off">
            <TextField
              id="outlined-basic"
              //   label="Search for a University"
              placeholder="Search for a University"
              variant="outlined"
              size="small"
              style={{
                width: "95%",
                background: "white",
              }}
              // fullWidth
              onChange={handleSearch}
              value={univParams.search}
            />
          </form>
        </Box>
        <Box style={{}}>
          {loading ? (
            <CircularProgress
              style={{
                margin: "10vw 0 0 50%",
              }}
            ></CircularProgress>
          ) : (
            <>
              {univParams.search != "" &&
                data?.data?.map((item) => (
                  <UniversityCards
                    {...item}
                    getShortlistedUniv={getShortlistedUniv}
                  />
                ))}
            </>
          )}
        </Box>
      </Box>
      <DisplayShortlistedUniv univ={shortlistedUniv} />
    </>
  );
};

export default Shortlisted;

const UniversityCards = ({
  name,
  city,
  country,
  logoUrl,
  slug,
  id,
  refetch,
  getShortlistedUniv,
}) => {
  const theme = useTheme();
  // console.log(theme);
  const { enqueueSnackbar } = useSnackbar();
  const userId = new URLSearchParams(window.location.search).get("userId");
  async function addToShortlist() {
    try {
      await http
        .post("/shortlist", {
          universityName: name,
          appFee: 5000,
          intake: "100",
          userId: +userId,
        })
        .then((res) => {
          getShortlistedUniv();

          if (!res.data.error) {
            enqueueSnackbar("University successfully added to Shorlist", {
              variant: "success",
            });
          } else {
            enqueueSnackbar(res.data.message, {
              variant: "warning",
            });
          }
        });
    } catch (e) {
      enqueueSnackbar(e.message, { variant: "warning" });
    }
  }

  return (
    <>
      <Box
        sx={{
          fontWeight: 400,
          boxShadow: "0px 3px 15px #273b8014",
          display: "flex",
          padding: "20px",
          margin: "16px",
          borderRadius: "20px",
          cursor: "pointer",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "1rem",
          "@media (max-width:600px)": {
            flexDirection: "column",
          },
        }}
      >
        <Box style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
          <Box>
            <img src={`${logoUrl}`} style={{ width: "140px" }} />
          </Box>
          <Box>
            <Box sx={{ alignItems: "center" }}>
              <Typography
                variant="h5"
                style={{
                  fontSize: "1rem",
                  color: "rgb(39, 59, 128)",
                }}
              >
                {name}
              </Typography>
            </Box>
            <Box>
              <Typography
                style={{ fontSize: "0.8rem", color: "rgba(0, 0, 0, 0.6)" }}
              >
                {city + ", " + country}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box>
          <Button variant="outlined" onClick={addToShortlist}>
            Shortlist
          </Button>
        </Box>
      </Box>
    </>
  );
};

const DisplayShortlistedUniv = ({ univ }) => {
  return (
    <>
      <Box sx={{ marginTop: "2rem" }}>
        <Accordian>
          <Accordian.Header>
            {`Shortlisted Universities : ${univ.length}`}
          </Accordian.Header>
          <Accordian.Body>
            <Box>
              {univ.reverse().map((item) => {
                return <RectangleCard data={item} />;
              })}
            </Box>
          </Accordian.Body>
        </Accordian>
      </Box>
    </>
  );
};
