import { Box } from "@material-ui/core";
import React from "react";
import Accordian from "../../../components/Accordian";
import EmptyScreen from "../../emptyScreen";
import { SquareCard } from "./Section";

export default function CardContainer({ title, data, type }) {
  return (
    <>
      <Accordian>
        <Accordian.Header>{title}</Accordian.Header>
        <Accordian.Body>
          <Box display="flex" gridGap={20}>
            {data.length && data.filter((item) => item.type === type).length ? (
              data
                .filter((item) => item.type === type)
                .map((item) => (
                  <>
                    <SquareCard data={item} />
                  </>
                ))
            ) : (
              <EmptyScreen heading="No records found" />
            )}
          </Box>
        </Accordian.Body>
      </Accordian>
    </>
  );
}
