import React from "react";
import JourneyAnalyticsTable from "../../components/JourneyAnalyticsTable";

function ProfileAndPreferences() {
  return (
    <JourneyAnalyticsTable
      status={"PROFILE_PREFERENCES"}
      title={"Profile Preferences"}
    />
  );
}

export default ProfileAndPreferences;
