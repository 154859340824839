import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import DateFilter from "../../../components/DateFilter";
import { http } from "../../../services/http";
import Stats from "./Stats";
import Table from "./Table";

const RegistrationHome = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      setLoading(true);
      const res = await http.get("/users/all");
      console.log(res);
      setData(
        res.data.filter(
          (user) => user?.roles[0]?.name === "STUDENT" || !user?.roles.length
        )
      );
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <>
      <Stats students={data} />
      <Filters setData={setData} />
      {!data ? <CircularProgress /> : <Table data={data} />}
    </>
  );
};

const Filters = ({ setData }) => {
  const [serachString, setSearchString] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const handleNewRegistration = () => {
    history.push("/registrations/details/profile");
  };

  async function handleSubmitSearch(e) {
    e.preventDefault();
    if (serachString !== "") {
      try {
        setLoading(true);
        const res = await http.get(`users/all?search=${serachString}`);
        setData(res.data.filter((user) => user.roles.length));
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    } else {
      try {
        setLoading(true);
        const res = await http.get(`users/all`);
        setData(res.data.filter((user) => !user.roles.length));
        setLoading(false);
        console.log(res.data);
      } catch (err) {
        console.log(err);
      }
    }
  }
  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Box my={2}>
      <form onSubmit={handleSubmitSearch}>
        <Box display="flex" justifyContent="space-between" width="100%">
          <Box display="flex" alignItems="center" width="40%">
            <TextField
              value={serachString}
              onChange={(e) => setSearchString(e.target.value)}
              variant="outlined"
              placeholder="Search for a User/Mobile Number"
              size="small"
              fullWidth
              style={{ background: "rgba(0, 0, 0, 0.05)" }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleSubmitSearch}>
                      <SearchIcon fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box style={{ padding: "0 1rem", display: "flex", gap: "1rem" }}>
            {/* Filter Section */}
            <DateFilter
              setData={setData}
              styles={{
                display: "flex",
                gap: "10px",
                alignItems: "flex-end",
              }}
            />
            {/* <Button variant="outlined" color="secondary">
              <FilterListIcon style={{ color: "black" }} />
            </Button> */}
            <Button
              color="secondary"
              variant="contained"
              onClick={handleNewRegistration}
              style={{ color: "white", whiteSpace: "nowrap" }}
            >
              New registration
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default RegistrationHome;
