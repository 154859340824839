import {
  Box,
  CircularProgress,
  IconButton,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { http } from "../../services/http";
import {
  CardsContainer,
  CourseCard,
  FlexBox,
  ScoreCards,
  Stats,
} from "../container";

import { BoldTypo } from "../Typography/BoldTypo";
import { LightTypo } from "../Typography/LightTypo";
import { useQuery } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { useQueryClient } from "@tanstack/react-query";
import { OutlinedButton } from "../OutlinedButton";
import ApplicationDialog from "./ApplicationDialog";
import CourseActions from "../courses/CourseActions";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import moment from "moment";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import EmptyScreen from "../EmptyScreen";

const initState = {
  universityName: "",
  courseName: "",
  feeType: "",
  feeAmount: null,
  applicationDate: "",
  followUpDate: "",
  docsReceived: false,
  isApplied: false,
  intake: [],
  status: "",
};
export default function ViewAppliedUniversities() {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [shortlistBody, setShortlistBody] = useState(initState);
  const userId = new URLSearchParams(window.location.search).get("userId");

  let { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const theme = useTheme();
  const classes = useStyles();

  const handleClickOpen = (obj) => {
    console.log(obj);

    setShortlistBody({ ...shortlistBody, ...obj });
    setOpen(true);
    console.log(shortlistBody);
  };

  const handleChange = (field, value) => {
    setShortlistBody({ ...shortlistBody, [field]: value });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const updateApplicationStatus = () => {
    http
      .put(`/application/university/user/${userId}`, {
        ...shortlistBody,
        userId,
      })
      .then((res) => queryClient.invalidateQueries("appliedUnivs"))
      .catch((err) =>
        enqueueSnackbar(err?.response?.data?.message, {
          variant: "error",
        })
      );
  };

  const getAppliedUnivs = () => {};

  const { data: appliedUnivs, isLoading } = useQuery(
    ["appliedUnivs"],
    () =>
      http.get(`/application/user/${userId}`).then((res) => {
        console.log(res);
        return res?.data;
      }),
    {
      onSuccess: (data) => {
        setData(data);
      },
    }
  );
  console.log(data);
  if (isLoading) {
    return (
      <Box mt={10} textAlign="center">
        <CircularProgress />
      </Box>
    );
  }
  return (
    <Box
      sx={{
        display: "flex",
        marginTop: "2rem",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          color: "red !important",
          marginLeft: "96rem",
          marginBottom: "1rem",
        }}
      >
        <Link to={`applied-universities?userId=${userId}`}>
          <Typography style={{ color: "#d3264c" }}>
            {" "}
            Edit Applied Universities
          </Typography>
        </Link>
      </Box>
      <CardsContainer
        color={theme.palette.primary.main}
        title={"Applied Universities"}
      >
        <>
          {appliedUnivs?.length ? (
            appliedUnivs?.map((univ, index) => {
              return (
                <>
                  <FlexBox style={{ margin: "1rem" }}>
                    <img
                      src={univ?.university?.logoUrl}
                      style={{
                        width: "100px",
                        borderRadius: "10px",
                        boxShadow: "0px 3px 5px #273B801A",
                      }}
                      alt={univ?.university?.name}
                    />
                    <Box style={{ padding: "1rem" }}>
                      <BoldTypo color="primary">
                        {univ?.university?.name}
                      </BoldTypo>
                      <LightTypo>
                        {univ?.university?.city +
                          ", " +
                          univ?.university?.country}{" "}
                      </LightTypo>
                    </Box>
                  </FlexBox>
                  {univ?.appliedCourses?.map((course, index) => {
                    return (
                      <CourseCard>
                        <CourseActions courseName={course?.course?.name}>
                          {/* <IconButton onClick={handleClickOpen}> */}
                          <EditOutlinedIcon
                            onClick={() =>
                              handleClickOpen({
                                universityName: univ?.universityName,
                                universityId: univ?.universityId,
                                courseName: course?.courseName,
                                courseId: course?.courseId,
                                feeType: course?.feeType,
                                feeAmount: course?.feeAmount,
                                applicationDate: course?.applicationDate,
                                followUpDate: course?.followUpDate,
                                docsReceived: course?.docsReceived,
                                isApplied: course?.isApplied,
                                intake: course?.intake,
                                status: "APPLICATIONS",
                              })
                            }
                            style={{
                              ":hover": {
                                color: "red",
                              },
                              cursor: "pointer",
                            }}
                            className={classes?.icon}
                            color="secondary"
                          />
                        </CourseActions>

                        <FlexBox
                          style={{
                            marginBottom: "1rem",
                            gap: "2.5rem",
                            marginTop: "1rem",
                          }}
                        >
                          <Stats title="Payment Type" value={course?.feeType} />
                          <Stats
                            title="Fee Amount"
                            value={`${course?.feeAmount} ${univ?.university?.currency}`}
                          />

                          <Stats
                            title="Application Date"
                            value={moment(course?.applicationDate).format(
                              "YYYY-MM-DD"
                            )}
                          />
                          <Stats
                            title="Expected Approval Date"
                            value={moment(course?.followUpDate).format(
                              "YYYY-MM-DD"
                            )}
                          />
                          <Stats
                            title="Documents received"
                            value={course?.docsReceived ? "Yes" : "No"}
                          />
                        </FlexBox>
                      </CourseCard>
                    );
                  })}
                </>
              );
            })
          ) : (
            <EmptyScreen heading={"No records found"} />
          )}
        </>
        <ApplicationDialog
          updateApplicationStatus={updateApplicationStatus}
          handleClose={handleClose}
          open={open}
          handleChange={handleChange}
          shortlistBody={shortlistBody}
          isUpdate={true}
        />
      </CardsContainer>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  icon: {
    "&:hover": {
      color: theme.palette.secondary.main,
      backgoundColor: "#FFFF00",
    },
  },
}));
