import { Box, Button, CircularProgress } from "@material-ui/core";
import MaterialTable from "material-table";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { http } from "../../services/http";

function AdminUsers() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    setLoading(true);
    getData();
  }, []);

  const getData = () => {
    http
      .get("/users/all", {
        params: {
          role: "ADMIN",
        },
      })
      .then((res) => {
        // console.log(res.data);
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        // console.log(err);
        setLoading(false);
      });
  };

  const handleEdit = (rowData) => {
    console.log(rowData);
    history.push({ pathname: `/admin-users/registration`, state: rowData });
  };
  
  function handleNewRegistration() {
    history.push("/admin-users/registration");
  }

  if (loading) return <CircularProgress />;

  return (
    <Box>
      {/* <DateFilter
        setData={setData}
        styles={{
          display: "flex",
          gap: "10px",
          justifyContent: "flex-end",
          backgroundColor: "white",
          padding: "10px",
        }}
      /> */}

      <Box my={2} style={{ justifyContent: "end", display: "flex" }}>
        <Button
          color="secondary"
          variant="contained"
          onClick={handleNewRegistration}
          style={{ color: "white" }}
        >
          Create New Admin
        </Button>
      </Box>
      <MaterialTable
        title="Admin Users"
        options={{
          actionsColumnIndex: -1,
        }}
        actions={[
          {
            icon: "edit",
            onClick: (event, rowData) => {
              console.log(rowData);
              handleEdit(rowData);
            },
          },
        ]}
        data={data}
        columns={[
          { title: "Name", field: "name" },
          { title: "Mobile", field: "mobile" },
          { title: "Email", field: "email" },
        ]}
      />
    </Box>
  );
}

export default AdminUsers;
