import { Box, Button, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import CustomUpload from "../../components/CustomUpload";
import ImageWithUploadButton from "../../components/Button/ImageWithUploadButton";
import { http } from "../../services/http";
import { validateEmail, validateMobile } from "../../services/validations";
import FormControl from "../registration/profile/components/FormControl";

const useStyles = makeStyles({
  upload: {
    margin: "0 2rem",
    height: "100%",
    background: "rgba(0, 0, 0, 0.1)",
    borderRadius: "1rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gridRowEnd: "span 5",
    alignSelf: "center",
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
  grid: {
    margin: "2rem 0",
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: "0rem 1rem",
  },
});

const initialState = {
  registrationNumber: "",
  date: new Date(),
  mobile: "",
  password: "",
  email: "",
  fullname: "",
  image: "",
  imageUrl: "",
  id: "",
  roles: ["MENTOR"],
};

const CreateConsultant = () => {
  const classes = useStyles();
  const history = useHistory();
  const match = useRouteMatch();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [state, setState] = useState(initialState);

  const editMode = !!history.location.state;
  useEffect(() => {
    if (history.location.state) {
      setState(history.location.state);
    }
  }, []);

  const handleImageChange = (value) => {
    setState((prev) => ({
      ...prev,
      image: value,
    }));
  };

  const handleSubmitProfile = async (e) => {

    if (!validateMobile(state.mobile)) {
      enqueueSnackbar("Please enter valid mobile Number", {
        variant: "warning",
      });
    }
    if (!validateEmail(state.email)) {
      enqueueSnackbar("Please enter valid email", {
        variant: "warning",
      });
    }
    if (!validateEmail(state.email)) {
      enqueueSnackbar("Please enter valid email", {
        variant: "warning",
      });
    }

    const postBody = {
      fullName: state.fullname,
      mobile: state.mobile,
      password: state.password,
      email: state.email,
      userType: "Working",
      status: "Active",
      image: state.image,
      roles: ["MENTOR"],
      permissions: [],
    };

    try {
      if (!state.image) {
        enqueueSnackbar("Please Upload a Photo", {
          variant: "warning",
        });
        return;
      }
      if (!state.fullname) {
        enqueueSnackbar("Please enter a Name", {
          variant: "warning",
        });
        return;
      }
      const res = await http.post(`/users/admin-create-user`, postBody);
      console.log({ postBody });

      if (!state.id) enqueueSnackbar("User Created", { variant: "success" });
      if (state.id) enqueueSnackbar("User Updated", { variant: "success" });
    } catch (err) {
      if (err)
        enqueueSnackbar(err?.response?.data?.message, {
          variant: "error",
        });
    }
  };

  const handleSaveProfile = async (e) => {

    if (!validateMobile(state.mobile)) {
      enqueueSnackbar("Please enter valid mobile Number", {
        variant: "warning",
      });
    }
    if (!validateEmail(state.email)) {
      enqueueSnackbar("Please enter valid email", {
        variant: "warning",
      });
    }
    if (!validateEmail(state.email)) {
      enqueueSnackbar("Please enter valid email", {
        variant: "warning",
      });
    }

    const postBody = {
      fullName: state.fullname,
      mobile: state.mobile,
      password: state.password,
      email: state.email,
      userType: "Working",
      status: "Active",
      image: state.image,
      roles: ["MENTOR"],
      permissions: [],
    };

    try {
      if (!state.image) {
        enqueueSnackbar("Please Upload a Photo", {
          variant: "warning",
        });
        return;
      }
      if (!state.fullname) {
        enqueueSnackbar("Please enter a Name", {
          variant: "warning",
        });
        return;
      }
      const res = await http.put(`/users/${state?.id}`, postBody);
      console.log({ postBody });

      if (!state.id) enqueueSnackbar("User Created", { variant: "success" });
      if (state.id) enqueueSnackbar("User Updated", { variant: "success" });
    } catch (err) {
      if (err)
        enqueueSnackbar(err?.response?.data?.message, {
          variant: "error",
        });
    }
  };

  const handleChange = (e) => {
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    editMode ? handleSaveProfile(state) : handleSubmitProfile(state);
  };

  if (loading) return <CircularProgress />;
  console.log(state)
  return (
    <form className={classes.grid} onSubmit={handleSubmit}>
      <Box className={classes.upload}>
        {state.imageUrl ? (
          <Box width="100%">
            <ImageWithUploadButton
              name="imageUrl"
              src={state?.imageUrl}
                state={state}
                setState={setState}
                field={"image"}
            />
          </Box>
        ) : (
          <CustomUpload
            label="Upload Photo"
            onChange={handleImageChange}
            name="imageUrl"
          />
        )}
      </Box>
      <FormControl
        label="Name"
        name="fullname"
        value={state.fullname}
        handleChange={handleChange}
        required
      />
      <FormControl
        label="Mobile"
        name="mobile"
        value={state.mobile}
        handleChange={handleChange}
        required
      />
      <FormControl
        label="Email"
        name="email"
        value={state.email}
        handleChange={handleChange}
        required
      />

      {
        <FormControl
          label="Password"
          name="password"
          type="password"
          value={state.password}
          handleChange={handleChange}
          required
        />
      }

      <Box></Box>

      <Box mt={2}>
        <Button variant="contained" color="secondary" type="submit">
          Save
        </Button>
      </Box>
    </form>
  );
};

export { CreateConsultant };
