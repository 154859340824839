import { CircularProgress } from "@material-ui/core";
import { Store } from "pullstate";
import { useEffect, useState } from "react";
import { http } from "../../../services/http";
import Docs from "./Docs";

const initState = {
  passport: [],
  tenth: null,
  intermediate: null,
  aadharCard: null,
  resume: null,
  transcripts: [],
  lors: [],
  sops: [],
  workDocs: [],

  tenthUrl: null,
  intermediateUrl: null,
  aadharCardUrl: null,
  resumeUrl: null,
  passportUrl: [],
  transcriptsUrl: [],
  sopsUrl: [],
  lorsUrl: [],
  workDocsUrl: [],
};

export const documentStore = new Store({
  data: initState,
  state: initState,
  docsObject: [
    {
      title: "Passport",
      docs: "passport",
      docsUrl: "passportUrl",
      type: "array",
    },
    {
      title: "10th Certificate",
      docs: "tenth",
      docsUrl: "tenthUrl",
      type: "string",
    },
    {
      title: "12th Certificate",
      docs: "intermediate",
      docsUrl: "intermediateUrl",
      type: "string",
    },
    {
      title: "Letter of Recommendations",
      docs: "lors",
      docsUrl: "lorsUrl",
      type: "array",
    },
    {
      title: "AadharCard",
      docs: "aadharCard",
      docsUrl: "aadharCardUrl",
      type: "string",
    },
    {
      title: "Transcripts",
      docs: "transcripts",
      docsUrl: "transcriptsUrl",
      type: "array",
    },
    {
      title: "Statement of Purpose",
      docs: "sops",
      docsUrl: "sopsUrl",
      type: "array",
    },
    { title: "Resume", docs: "resume", docsUrl: "resumeUrl", type: "string" },
    {
      title: "Work Experience",
      docs: "workDocs",
      docsUrl: "workDocsUrl",
      type: "array",
    },
  ],
  userId: new URLSearchParams(window.location.search).get("userId"),
  getDocsData: null,
});

const Documents = () => {
  const userId = new URLSearchParams(window.location.search).get("userId");
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getDocsData();
    documentStore.update((s) => {
      s.getDocsData = getDocsData;
    });
  }, []);

  const getDocsData = async () => {
    setLoading(true);
    try {
      const res = await http.get(`/user-documents/${userId}`);
      setData(res.data);
      documentStore.update((s) => {
        if (res.data.exists) {
          s.state = res.data.documents;
          s.data = res.data.documents;
        }
      });
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  if (loading) return <CircularProgress />;

  return (
    <div>{data && <Docs getDocsData={getDocsData} exists={data.exists} />}</div>
  );
};

export default Documents;
