import React from "react";
import JourneyAnalyticsTable from "../../components/JourneyAnalyticsTable";

function SOPandLORServices() {
  return (
    <JourneyAnalyticsTable
      status={"SOP_LOR_SERVICE"}
      title={"SOP & LOR Services"}
    />
  );
}

export default SOPandLORServices;
