import { Box, Button, CircularProgress } from "@material-ui/core";
import MaterialTable from "material-table";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { http } from "../../services/http";

function FieldsOfStudy() {
  const router = useHistory();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  let { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setLoading(true);
    getData();
  }, []);

  const getData = () => {
    http
      .get("/common/trending-fields-of-study")
      .then((res) => {
        console.log(res.data);
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  function handleDelete(rowData) {
    let confirm = window.confirm("Are you sure you want to delete?");
    if (!confirm) return;
    http
      .delete(`/common/trending-fields-of-study/${rowData.id}`)
      .then((res) => {
        enqueueSnackbar("Field Of Study Deleted successfully.", {
          variant: "success",
        });
        getData();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  function handleEdit(rowData) {
    router.push(`/areas-of-study/${rowData.slug}/edit`);
  }

  if (loading) return <CircularProgress />;

  return (
    <Box>
      <Box mb={2} textAlign='right'>
        <Link to='/areas-of-study/add'>
          <Button variant='contained' color='primary'>
            Create
          </Button>
        </Link>
      </Box>
      <MaterialTable
        title='Areas Of Study'
        actions={[
          {
            icon: "edit",
            onClick: (event, rowData) => handleEdit(rowData),
          },
          {
            icon: "delete",
            onClick: (event, rowData) => handleDelete(rowData),
          },
        ]}
        options={{
          actionsColumnIndex: -1,
        }}
        data={data}
        columns={[{ title: "Name", field: "name" }]}
      />
    </Box>
  );
}

export default FieldsOfStudy;
