import { Box, Button, IconButton, Typography } from "@material-ui/core";
import AddCircleOutlinedIcon from "@material-ui/icons/AddCircleOutlined";
import React from "react";
import { documentStore } from ".";
import StudentProfileLayout from "../../../Layout/StudentProfileLayout";
import { http } from "../../../services/http";
import { SingleDoc } from "./SingleDocument";

export default function Docs({ getDocsData, exists }) {
  const userId = new URLSearchParams(window.location.search).get("userId");
  const state = documentStore.useState((s) => s.state);
  const docsObject = documentStore.useState((s) => s.docsObject);
  function handleSubmit() {
    let bodyObj = {};

    for (var i = 0; i < docsObject.length; i++) {
      bodyObj[docsObject[i].docs] = state[docsObject[i].docs];
    }

    http
      .post(`/user-documents/${userId}`, {
        id: userId,
        ...bodyObj,
      })
      .then((res) => {
        getDocsData();
      })
      .catch((e) => {});
  }
  function handleCancel() {
    documentStore.update((s) => {
      s.state = s.data;
    });
  }
  return (
    <StudentProfileLayout>
      <Box
        style={{
          display: "grid",
          gap: "2rem",
        }}
      >
        {docsObject.map((item) => (
          <Card {...item} getDocsData={getDocsData} />
        ))}
        <Box
          sx={{
            display: "flex",
            position: "sticky",
            bottom: "50px",
            margin: "auto",
            gap: "1rem",
          }}
        >
          <Button
            onClick={handleCancel}
            style={{ boxShadow: "rgb(211 38 76 / 40%) 8px 8px 15px 0px" }}
            color="secondary"
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            style={{ boxShadow: "rgb(211 38 76 / 40%) 8px 8px 15px 0px" }}
            color="secondary"
            variant="contained"
          >
            Submit
          </Button>
        </Box>
      </Box>
    </StudentProfileLayout>
  );
}

const Card = ({ docs, docsUrl, title, type, getDocsData }) => {
  const state = documentStore.useState((s) => s.state);
  const docsObject = documentStore.useState((s) => s.docsObject);
  function handleUpload(e) {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);

    http.post("/common/upload", formData).then((res) => {
      let key = docsObject.find((item) => item.title === title).docs;

      if (type == "array") {
        documentStore.update((s) => {
          if (s.state[key] == null) {
            s.state = { ...s.state, [key]: [res.data.key] };
          } else {
            s.state = { ...s.state, [key]: [...s.state[key], res.data.key] };
          }
        });
      } else {
        documentStore.update((s) => {
          s.state = { ...s.state, [key]: res.data.key };
        });
      }
    });
  }

  return (
    <Box
      sx={{
        minHeight: "123px",
        border: "1px solid #FDB2B1",
        borderRadius: "15px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          width: "100%",
          borderRadius: "15px 15px 0 0",
          height: "60px",
          display: "flex",
          alignItems: "center",
          padding: "16px",
          backgroundColor: "#FFF0F0", //"#89CFF0",
          justifyContent: "space-between",
        }}
      >
        <Typography sx={{ color: "#273B80" }}>{title}</Typography>
        <input
          onChange={(e) => handleUpload(e)}
          type="file"
          style={{ display: "none" }}
          id={`${title}`}
        />
        <label htmlFor={`${title}`}>
          <IconButton component="span">
            <AddCircleOutlinedIcon style={{ color: "black" }} />
          </IconButton>
        </label>
      </Box>
      <Box>
        {state && typeof state[docs] === "object" && state[docs]
          ? state[docs].map((file, index) => (
              //   <embed src={file} width="100px" />
              <SingleDoc
                name={file}
                url={state[docsUrl][index]}
                key={index}
                value={index}
                getDocsData={getDocsData}
                docs={docs}
                type={type}
              />
            ))
          : typeof state[docs] === "string" && (
              <SingleDoc
                name={state[docs]}
                url={state[docsUrl]}
                getDocsData={getDocsData}
                key={null}
                docs={docs}
                type={type}
              />
            )}
      </Box>
    </Box>
  );
};
