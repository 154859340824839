export const validateMobile = (mobile) => {
  if (!/^[1-9]{1}[0-9]{9}$/.test(mobile)) {
    return false;
  }
  return true;
};

export const validateEmail = (email) => {
  if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
    return false;
  }
  return true;
};
