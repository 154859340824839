import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import { KeyboardBackspace } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";
import { useHistory, useRouteMatch } from "react-router-dom";
import ImageWithUploadButton from "../../components/Button/ImageWithUploadButton";
import CustomUpload from "../../components/CustomUpload";
import { http } from "../../services/http";

function EditFieldOfStudy() {
  const router = useHistory();
  const match = useRouteMatch();
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useState({
    name: "",
    image: "",
    description: "",
    fields: [],
    videos: [],
  });
  const [loading, setLoading] = useState(false);
  const [field, setField] = useState("");
  const [video, setVideo] = useState("");
  const history = useHistory();

  useEffect(() => {
    setLoading(true);
    http
      .get(`/common/trending-fields-of-study/${match.params.id}`)
      .then((res) => {
        setState({
          ...res.data,
          fields: res.data?.fields || [],
          videos: res.data?.videos || [],
        });
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  function handleChange(e) {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  }

  function addField(e) {
    e.preventDefault();
    if (!field) {
      enqueueSnackbar("Enter Field Name", {
        variant: "error",
      });
      return;
    }
    setState({
      ...state,
      fields: [...state.fields, field],
    });
    setField("");
  }

  function deleteField(index) {
    let filtered = state.fields?.filter((itm, idx) => idx !== index);
    setState({
      ...state,
      fields: filtered,
    });
  }

  function addVideo(e) {
    e.preventDefault();
    if (!video) {
      enqueueSnackbar("Enter video link", {
        variant: "error",
      });
      return;
    }
    setState({
      ...state,
      videos: [...state.videos, video],
    });
    setVideo("");
  }

  function deleteVideo(index) {
    let filtered = state.videos?.filter((itm, idx) => idx !== index);
    setState({
      ...state,
      videos: filtered,
    });
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (!state.image) {
      enqueueSnackbar("Upload Image", {
        variant: "error",
      });
      return;
    }
    http
      .put(`/common/trending-fields-of-study/${match.params.id}`, state)
      .then((res) => {
        enqueueSnackbar("Area Of Study Updated successfully.", {
          variant: "success",
        });
        router.push("/areas-of-study");
      })
      .catch((err) => {
        console.log(err);
      });
  }

  if (loading) return <CircularProgress />;

  return (
    <Box p={2}>
      <Box
        display="flex"
        gridGap={20}
        alignItems="center"
        sx={{ marginLeft: "-1rem", marginBottom: "1.5rem" }}
      >
        <IconButton onClick={() => history.goBack()}>
          <KeyboardBackspace />
        </IconButton>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Typography variant="h6"> Edit Area Of Study</Typography>
          <Box>
            <Button
              style={{ marginLeft: 10 }}
              size="medium"
              type="submit"
              variant="contained"
              color="secondary"
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Box>
      <form onSubmit={handleSubmit}>
        <Box mb={2} display="flex" justifyContent="space-between"></Box>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Box>
              <TextField
                onChange={handleChange}
                fullWidth
                variant="outlined"
                margin="dense"
                label="Name"
                name="name"
                value={state.name}
                required
              />
            </Box>
            <Box mt={1}>
              <TextField
                onChange={handleChange}
                fullWidth
                variant="outlined"
                margin="dense"
                label="Description"
                value={state.description}
                rows={4}
                multiline
                name="description"
              />
            </Box>
            <Typography variant="h6" gutterBottom color="primary">
              Image
            </Typography>
            <Box border="1px solid rgba(0, 0, 0, 0.1)" borderRadius={10}>
              <ImageWithUploadButton
                // onChange={(v) => setState({ ...state, image: v })}
                src={state?.imageUrl}
                state={state}
                setState={setState}
                field={"image"}
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box>
              <Typography variant="subtitle1" color="primary">Fields</Typography>
              <Box mt={1}>
                {state?.fields?.map((item, index) => (
                  <Box display="flex" width="80%" alignItems="center">
                    <Box flex={1}>
                      <Typography variant="body1">
                        {index + 1}. {item}
                      </Typography>
                    </Box>
                    <IconButton onClick={() => deleteField(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                ))}
              </Box>
              <Box display="flex" mt={1}>
                <Box flex={1}>
                  <TextField
                    variant="outlined"
                    size="small"
                    value={field}
                    placeholder="Field Name"
                    fullWidth
                    onChange={(e) => setField(e.target.value)}
                  />
                </Box>
                <Box ml={2}>
                  <Button
                    onClick={addField}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Add
                  </Button>
                </Box>
              </Box>
            </Box>
            <Box mt={2}>
              <Typography variant="subtitle1" color="primary">Videos</Typography>
              <Box mt={1}>
                {state?.videos?.map((item, index) => (
                  <Box display="flex" width="80%" alignItems="center">
                    <Box flex={1}>
                      <Typography variant="body1">
                        {index + 1}. {item}
                      </Typography>
                    </Box>
                    <IconButton onClick={() => deleteVideo(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                ))}
              </Box>
              <Box display="flex" mt={1}>
                <Box flex={1}>
                  <TextField
                    variant="outlined"
                    size="small"
                    value={video}
                    placeholder="Video Link"
                    fullWidth
                    onChange={(e) => setVideo(e.target.value)}
                  />
                </Box>
                <Box ml={2}>
                  <Button
                    onClick={addVideo}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Add
                  </Button>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}

export default EditFieldOfStudy;
