import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  styled,
  TextField,
  Typography,
  useTheme,
} from "@material-ui/core";
import moment from "moment";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Accordian from "../Accordian";
import { BoldTypo } from "../Typography/BoldTypo";
import { LightTypo } from "../Typography/LightTypo";
import JourneyTimelineItem from "./JourneyTimeline";
import InputAdornment from "@mui/material/InputAdornment";
import Paper from "@mui/material/Paper";
import { Stack } from "@mui/material";

const MarginBox = styled(Box)(({ theme }) => ({
  display: "flex",
  //   margin: "0.5rem",
  //   marginLeft: "1rem",
}));

export const JourneyStats = ({ stats }) => {
  return (
    <MarginBox
      style={{
        gap: "4rem",
      }}
    >
      {stats.map((props) => {
        // console.log(props, "jstats");
        return <Stats {...props} key={props.title + props.value} />;
      })}
    </MarginBox>
  );
};

export const Stats = ({ title, value }) => {
  // console.log(title, value, "stats");
  return (
    <>
      <Box>
        <LightTypo>{title}</LightTypo>
        <BoldTypo>{value}</BoldTypo>
      </Box>
    </>
  );
};

export const JourneyGeneralQuery = ({
  query,
  value,
  onChange,
  fieldName,
  disabled,
}) => {
  return (
    <>
      <MarginBox
        style={{
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <BoldTypo>{query}</BoldTypo>
        <CustomSelect
          value={value}
          onChange={onChange}
          fieldName={fieldName}
          disabled={disabled}
        />
      </MarginBox>
    </>
  );
};

export const CustomSelect = ({
  label,
  value,
  onChange,
  fieldName,
  disabled,
}) => {
  const [state, setState] = useState(Boolean(value));

  useEffect(() => {
    setState(Boolean(value));
  }, [value]);

  function handleChange(e) {
    setState(e.target.value);
    onChange(fieldName, e.target.value);
    console.log(e.target.value);
  }

  return (
    <FormControl sx={{ m: 1, minWidth: 220 }} size="small">
      <Typography style={{ fontSize: "14px", opacity: "0.5" }}>
        {label}
      </Typography>

      <Select
        value={Boolean(state)}
        variant="outlined"
        style={{ minWidth: "250px" }}
        onChange={handleChange}
        disabled={disabled}
      >
        <MenuItem value={true}>Yes</MenuItem>
        <MenuItem value={false}>No</MenuItem>
      </Select>
    </FormControl>
  );
};
export const CustomTextField = ({
  label,
  value,
  type,
  rows,
  placeholder,
  fieldName,
  onChange,
  required,
  todayOnwards,
  amount,
}) => {
  function handleChange(e) {
    onChange(fieldName, e.target.value);
  }

  return (
    <Box style={{ display: "grid", gap: "0.5rem" }}>
      <InputLabel htmlFor="select">
        {label}
        {required && <span style={{ color: "red" }}>*</span>}
      </InputLabel>

      <TextField
        type={type || "text"}
        variant="outlined"
        size="small"
        required={required}
        inputProps={
          todayOnwards && { min: moment(new Date()).format("YYYY-MM-DD") }
        }
        rows={rows || 1}
        multiline={!!rows}
        placeholder={placeholder || ""}
        value={
          type === "date"
            ? value
              ? moment(value).format("YYYY-MM-DD")
              : ""
            : value ?? ""
        }
        InputProps={
          amount && {
            startAdornment: <InputAdornment position="start">₹</InputAdornment>,
          }
        }
        onChange={handleChange}
      ></TextField>
    </Box>
  );
};

export const JourneyInfoCard = ({ stats, view }) => {
  const theme = useTheme();
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          boxShadow: "0px 3px 15px #273B801A",
          borderRadius: "15px",
          padding: "1rem",
          margin: "1rem",
          //   paddingLeft: "1rem",
        }}
      >
        <Stats {...stats} />
        <Box
          style={{
            padding: "1rem",
            border: `1px solid ${theme.palette.secondary.main}`,
            borderRadius: "10px",
            cursor: "pointer",
          }}
        >
          <BoldTypo color="secondary">{view}</BoldTypo>
        </Box>
      </Box>
    </>
  );
};

export const AddButton = ({ title, path }) => {
  const router = useHistory();
  return (
    <Button
      style={{
        border: "2px dashed #DCDCDC",
        width: "100%",
        padding: "2rem 0",
        color: "#C0C0C0",
      }}
      onClick={() => router.push(path)}
    >
      {"+ " + title}
    </Button>
  );
};

export const JourneyCard = ({
  positions,
  title,
  children,
  handleSubmit,
  disabled,
  submitButton,
}) => {
  return (
    <JourneyTimelineItem {...positions}>
      <Box
        style={{
          marginBottom: "1rem",
          pointerEvents: disabled ? "none" : "initial",
        }}
      >
        <Accordian
          sx={{
            "&.MuiTypography-root &.MuiTypography-h5": {
              fontSize: "11px",
            },
          }}
        >
          <Accordian.Header style={{ paddingLeft: " 2rem" }}>
            {title}
          </Accordian.Header>
          <Accordian.Body style={{ padding: "1rem 2rem" }}>
            <Box style={{ display: "grid", gap: "1rem" }}>{children}</Box>

            {submitButton !== false && !disabled && (
              <Button
                type="text"
                color="secondary"
                onClick={handleSubmit}
                style={{
                  display: "flex",
                  margin: "0.5rem 0 0 48.8rem",
                }}
              >
                Submit
              </Button>
            )}
          </Accordian.Body>
        </Accordian>
      </Box>
    </JourneyTimelineItem>
  );
};

export const TextFieldGrid = styled(Box)(({ theme }) => ({
  display: "grid",
  justifyContent: "space-between",
  gap: "1rem",
  gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
}));

export const ViewBanner = ({ title, buttonText, count, path }) => {
  const router = useHistory();
  return (
    <Paper
      elevation={2}
      sx={{ width: "100%", padding: "20px", borderRadius: "15px !important" }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <InputLabel>{title}</InputLabel>
          <BoldTypo>{count}</BoldTypo>
        </Box>
        <Button
          variant="outlined"
          color="secondary"
          style={{ width: "fit-content" }}
          onClick={() => router.push(path)}
        >
          {buttonText}
        </Button>
      </Box>
    </Paper>
  );
};
