import React from "react";
import JourneyAnalyticsTable from "../../components/JourneyAnalyticsTable";

function UniversityApplied() {
  return (
    <JourneyAnalyticsTable
      status={"APPLICATIONS"}
      title={"University Applications"}
    />
  );
}

export default UniversityApplied;
