import React from "react";
import JourneyAnalyticsTable from "../../components/JourneyAnalyticsTable";

function FinancialAssistance() {
  return (
    <JourneyAnalyticsTable
      status={"FINANCIAL_ASSISTANCE"}
      title={"Financial Assistance"}
    />
  );
}

export default FinancialAssistance;
