import React from "react";
import JourneyAnalyticsTable from "../../components/JourneyAnalyticsTable";

function TravelAssistance() {
  return (
    <JourneyAnalyticsTable
      status={"TRAVEL_ASSISTANCE"}
      title={"Travel Assistance"}
    />
  );
}

export default TravelAssistance;
