import React, { useState } from "react";
import { BoldTypo } from "../../components/Typography/BoldTypo";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Text,
  TextField,
} from "@mui/material";
import { Search } from "@material-ui/icons";
import { http } from "../../services/http";
import { LightTypo } from "../../components/Typography/LightTypo";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import EmptyScreen from "../../components/EmptyScreen";
function PreferredUnivs() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const navigate = useHistory();
  const country = new URLSearchParams(window.location.search).get("country");


  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    setLoading(true);
    try {
      const res = await http.get(
        `/users/dashboard/student-preferences/country/${country}`
      );
      console.log(res);
      setData(res.data.filter((item) => item.hasOwnProperty("id")));
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  console.log(data);
  if (loading) return <CircularProgress />;

  const SearchInput = ({ getData }) => {
    const [search, setSearch] = useState("");
    const handleSearch = (e) => {
      getData({ search });
    };
    return (
      <TextField
        variant="outlined"
        fullWidth
        size="small"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        placeholder="Search for a University"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={handleSearch}
                type="submit"
                color="secondary"
                size="large"
              >
                <Search fontSize="small" />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    );
  };

  return (
    <div>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <ArrowBackIcon
          sx={{ color: "#D72245", cursor: "pointer", fontSize: "medium" }}
          onClick={() => navigate.goBack()}
        />
        <BoldTypo style={{ color: "#D72245", marginLeft: "0.3rem" }}>
          Universities
        </BoldTypo>
      </Box>
      {/* <Box sx={{ width: "435px" }}>
        <SearchInput getData={getData} />
      </Box> */}
      {data?.length ? (
        <Grid container style={{ marginTop: 10 }} spacing={2}>
          {data?.map((item, ind) => {
            return (
              <Grid item xs={6}>
                <Paper
                  p={2}
                  minHeight="100px"
                  elevation={2}
                  style={{
                    fontWeight: "bold",
                    borderRadius: "15px",
                  }}
                >
                  <Box
                    sx={{
                      padding: "30px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box>
                        <img
                          src={item?.logoUrl}
                          style={{
                            width: "120px",
                            height: "120px",
                            borderRadius: "10px",
                            boxShadow: "0px 3px 5px #273B801A",
                            objectFit: "cover",
                          }}
                          alt={item?.name}
                        />
                      </Box>
                      <Box sx={{ marginLeft: "1rem" }}>
                        <BoldTypo style={{ color: "#D72245" }}>
                          {item?.name}
                        </BoldTypo>
                        <LightTypo>
                          {item?.city},{item?.country}
                        </LightTypo>
                        <Box sx={{ marginTop: "0.3rem" }}>
                          <LightTypo>Preferred Students</LightTypo>
                          <BoldTypo>{item?.studentCount}</BoldTypo>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Paper>
              </Grid>
            );
          })}
        </Grid>
      ) : (
        <EmptyScreen
          heading="There are no preferred universities marked by the student"
          additionalStyling
        />
      )}
    </div>
  );
}

export default PreferredUnivs;
