import React from "react";
import JourneyAnalyticsTable from "../../components/JourneyAnalyticsTable";

function ShortlistedUnivs() {
  return (
    <JourneyAnalyticsTable
      status={"SHORT_LISTED_UNIVERSITIES"}
      title={"Shortlisted Universities"}
    />
  );
}

export default ShortlistedUnivs;
