import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import MaterialTable from "material-table";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { http } from "../../services/http";

function Consultations() {
  const router = useHistory();
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mentors, setMentors] = useState();
  const [mentorId, setMentorId] = useState();
  const [mentorName, setMentorName] = useState();
  const [selectedStudents, setSelectedStudents] = useState();

  let { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setLoading(true);
    getMentors();
    getData();
  }, []);

  function handleChange(id, name) {
    setMentorId(id);
    setMentorName(name);
  }

  const getData = () => {
    http
      .get("/schedule")
      .then((res) => {
        // console.log(res.data);
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        // console.log(err);
        setLoading(false);
      });
  };

  async function handleSubmit(students) {
    console.log(students);
    const userId = students[0]?.id;
    const mntorId = mentorId;
    const mntorName = mentorName;

    try {
      var res = await http.put(`/journeys/update-journey/user/${userId}`, {
        userId: userId,
        mentorId: mntorId,
        status: "MENTOR_ASSIGNED",
      });

      if (res?.data?.status === "MENTOR_ASSIGNED") {
        enqueueSnackbar(
          "Assigned Consultant to the Users!! View it in User Registartions",
          {
            variant: "success",
          }
        );
        handleClose();
      }
    } catch (error) {
      enqueueSnackbar("Error while updating", {
        variant: "error",
      });
    } // console.log("submit", students);
    // let Resolve = [],
    //   Complete = [];
    // for (let i = 0; i < students.length; i++) {
    //   Resolve[i] = http
    //     .put(`/journeys/${students[i].id}`, {
    //       status: "MENTOR_ASSIGNED",
    //       // data: null,
    //       mentorId,
    //       userId: students[i].id,
    //     })
    //     .then(() => {
    //       Complete[i] = http
    //         .post(`/schedule/${students[i].schedule.id}/complete`)
    //         .finally(() => {
    //           getData();
    //         });
    //     });
    // }
    // Promise.all([...Resolve, ...Complete])
    //   .then((res) => {
    //     // console.log(res.data);
    //   })
    //   .catch((err) => {
    //     // console.log(err);
    //   })
    //   .finally(() => {
    //     getData();
    //     enqueueSnackbar(
    //       "Assigned Consultant to the Users!! View it in User Registartions",
    //       { variant: "success" }
    //     );
    //     handleClose();
    //   });
  }

  const getMentors = () => {
    http.get("/users/all?role=MENTOR").then((res) => {
      setMentors(res.data);
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  // console.log(!!students);
  if (loading) return <CircularProgress />;
  return (
    <Box>
      <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
        <DialogTitle>Assign Mentors</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              m: "0 1rem",
              gap: "1rem",
            }}
          >
            <Autocomplete
              fullWidth={true}
              id="tags-outlined"
              options={mentors?.map((item) => ({
                name: item.name,
                id: item.id,
              }))}
              onChange={(e, newVal) => {
                handleChange(newVal.id, newVal.name);
              }}
              getOptionLabel={(option) => option.name}
              filterSelectedOptions
              size="small"
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Mentors" />
              )}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined">
            Close
          </Button>
          <Button
            disabled={!!!mentorId}
            onClick={() => {
              handleSubmit(selectedStudents);
            }}
            style={{ size: "small" }}
            variant="contained"
            color="secondary"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <MaterialTable
        title="Student Consultations"
        components={{
          Action: (props) => {
            if (props.action.icon == "assign") {
              // console.log(props.data);
              // setStudents(props.data.map((user) => user.id));
              return (
                <Button
                  onClick={() => {
                    handleClickOpen();
                    setSelectedStudents(props.data);
                  }}
                  variant="contained"
                  color="secondary"
                  style={{ marginLeft: "1rem" }}
                >
                  Assign Mentor
                </Button>
              );
            }
          },
        }}
        actions={[{ icon: "assign" }]}
        options={{
          actionsColumnIndex: -1,
          selection: true,
          // searchFieldAlignment: "left",
          // toolbarButtonAlignment: "left",
        }}
        data={data}
        columns={[
          { title: "Name", field: "name" },
          { title: "Mobile", field: "mobile" },
          // { title: "Mobile", field: "alternateMobile" },
          { title: "Date", field: "schedule.date" },
          { title: "Slot", field: "schedule.slot" },
          // { title: "Notes", field: "schedule.notes" },
        ]}
      />
    </Box>
  );
}

export default Consultations;

// const handleClick = ({ schedule }) => {
//   let isConfirm = window.confirm("Do you want to Resolve this Call?");
//   if (isConfirm) {
//     updateCallStatus(schedule.id)
//       .then((res) => {
//         enqueueSnackbar("The Call has been Scheduled", {
//           variant: "success",
//         });
//         getData();
//       })
//       .catch((err) => {
//         console.log(err);
//         enqueueSnackbar("Something went Wrong,Try again", {
//           variant: "warning",
//         });
//       });
//   }
// };
