import { Box, Typography } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";

export default function UniversityOverview({
  overview,
  name,
  city,
  country,
  video_link,
}) {
  // console.log(props);
  return (
    <>
      <Box>
        <Box style={{ marginBottom: "2rem" }}>
          <Link to={"/universities"}>{"< Back to Universities"}</Link>
        </Box>
        <Box>
          <iframe width="355" height="200" src={`${video_link}`}></iframe>
        </Box>
        <Typography variant="h5" color="primary" fontSize="20px">
          {name}
        </Typography>
        <Typography
          variant="body1"
          style={{ fontWeight: 400, opacity: 0.7, marginBottom: "1rem" }}
        >
          {city + ", " + country}
        </Typography>
        <Typography variant="h6" fontSize="16px">
          Overview
        </Typography>
        <Typography
          style={{ fontWeight: 400, opacity: 0.7, marginBottom: "2rem" }}
          fontSize="16px"
        >
          {overview}
        </Typography>
      </Box>
    </>
  );
}
