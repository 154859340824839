import { Box, Button, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useEffect, useState } from "react";
import {
  AddButton,
  CustomSelect,
  CustomTextField,
  JourneyCard,
  JourneyGeneralQuery,
  JourneyStats,
  TextFieldGrid,
  ViewBanner,
} from "../../components/journey/JourneyCard";
import { StyledTimeline } from "../../components/journey/JourneyTimeline";
import StudentProfileLayout from "../../Layout/StudentProfileLayout";
import { http } from "../../services/http";
import { useSnackbar } from "notistack";
import {
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { Typography } from "@mui/material";
import Paper from "@mui/material/Paper";

import moment from "moment";
import { LightTypo } from "../../components/Typography/LightTypo";
import { BoldTypo } from "../../components/Typography/BoldTypo";
import { useHistory } from "react-router-dom";
// import { VisaSlotDetails } from "./VisaSlotDetails";
const form = {
  i20Lock: "",
  visaFee: "",
  financial: "",
  sevisPaymentsStatus: "",
  location: "",
  ofcDate: "",
  visaDate: "",
  mock: "",
  stuzeeUndertakes: "",
  visaStamping: "",
  status: "VISA_SLOT",
};

export default function StudentJourney() {
  let { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState({});
  const [visaSlot, setVisaSlot] = useState(
    data?.visaSlots?.length ? true : false
  );
  const [shortlistedUniv, setShortlistedUniv] = useState([]);

  const shortlistCount = data?.shortlist_count;
  const sopDocsCount = data?.sop_lor_docs_count;
  const appliedCoursesCount = data?.applied_courses_count;
  const approvalCount = data?.applied_courses_approval_count;
  const studentsChoiceCount = data?.studentsChoice;

  const [flightPosition, setFlight] = useState(0);
  const userId = new URLSearchParams(window.location.search).get("userId");
  const [detailsForm, setDetailsForm] = useState([
    { ...form, key: Date.now() },
  ]);
  const router = useHistory();

  useEffect(() => {
    getMyJourney();
  }, []);
  useEffect(() => {
    getShortlistedUnivs();
  }, []);

  const [visaDetails, setVisaDetails] = useState({});

  useEffect(() => {
    getVisaDetails();
  }, []);

  const getVisaDetails = () => {
    http.get(`/visa/user/${userId}`).then((res) => {
      console.log(res);
      setVisaDetails(res.data);
    });
  };

  const getMyJourney = () => {
    http.get(`/journeys/my-journey/user/${userId}`).then((res) => {
      setData(res.data);
      setFlight(res.data.statuses.length);
    });
  };

  function handleVisaChange(field, value, slotId) {
    console.log(field, value, slotId);
    const newState = detailsForm.map((obj) => {
      if (obj.key === slotId) {
        return { ...obj, [field]: value, ["i20Lock"]: visaDetails?.university };
      }
      return obj;
    });

    setDetailsForm(newState);
  }

  const getShortlistedUnivs = () => {
    http.get(`/shortlist/user/${userId}`).then((res) => {
      // console.log(res);
      setShortlistedUniv(res.data);
    });
  };

  function handleChange(field, value) {
    console.log(field);
    if (field) {
      setData({ ...data, [field]: value });
    }
    console.log(field, value);
    console.log(data);
  }

  function handleProChange(field, value) {
    console.log(field);
    if (field) {
      setData({ ...data, [field]: value });
    }
    if (value === false) {
      setData({ ...data, [field]: value, proAmount: null, proPayment: null });
    }
    console.log(data);
  }

  function handleSOPChange(field, value) {
    console.log(field);
    if (field) {
      setData({ ...data, [field]: value });
    }
    if (value === false) {
      setData({
        ...data,
        [field]: value,
        sopLorAmount: null,
        sopLorPayment: null,
      });
    }
    console.log(data);
  }

  function handleFinancialChange(field, value) {
    console.log(field);
    if (field) {
      setData({ ...data, [field]: value });
    }
    if (value === false) {
      setData({
        ...data,
        [field]: value,
        financialAssistanceAmount: null,
      });
    }
    console.log(data);
  }

  function handleScholarshipChange(field, value) {
    console.log(field);
    if (field) {
      setData({ ...data, [field]: value });
    }
    if (value === false) {
      setData({
        ...data,
        [field]: value,
        scholarshipName: null,
      });
    }
    console.log(data);
  }
  function handleVisaApprovalChange(field, value) {
    console.log(field);
    if (field) {
      setData({ ...data, [field]: value });
    }
    if (value === false) {
      setData({
        ...data,
        [field]: value,
        visaApprovalDate: null,
      });
    }
    console.log(data);
  }

  function handleTestimonialChange(field, value) {
    console.log(field);
    if (field) {
      setData({ ...data, [field]: value });
    }
    if (value === false) {
      setData({
        ...data,
        [field]: value,
        testimonial: null,
      });
    }
    console.log(data);
  }

  function handleVisaSlotStatusChange(field, value) {
    if (field) {
      setData({ ...data, [field]: value });
    }
    if (value === false) {
      setVisaSlot(false);
    }
    if (value === true) {
      setVisaSlot(true);
    }
  }

  async function handleSubmit(status) {
    try {
      var res = await http.put(`/journeys/update-journey/user/${userId}`, {
        ...data,
        status: status,
      });

      if (res?.data?.status === status) {
        enqueueSnackbar("Updated successfully", {
          variant: "success",
        });
        getMyJourney();
      }
    } catch (error) {
      enqueueSnackbar("Error while updating", {
        variant: "error",
      });
    }
  }

  async function handleVisaSubmit() {
    try {
      var res = await http.post(
        `/visa/create-visa-slot/user/${userId}`,
        detailsForm[0]
      );
      console.log(res);

      if (res?.data?.data.length > 0) {
        enqueueSnackbar("Updated successfully", {
          variant: "success",
        });
        getMyJourney();
      }
    } catch (error) {
      enqueueSnackbar("Error while updating", {
        variant: "error",
      });
    }
  }

  console.log(detailsForm);

  return (
    <StudentProfileLayout>
      {/* <Box
        style={{
          display: "grid",
        }}
      > */}
      {/* Step 1 */}
      <Paper
        elevation={2}
        sx={{ width: "100%", padding: "20px", borderRadius: "15px !important" }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <BoldTypo>Abroad Education Journey Status</BoldTypo>
          <BoldTypo>
            {data?.status
              ? data?.status === "START"
                ? "Schedule Call"
                : data?.status
              : "Not Started"}
          </BoldTypo>
        </Box>
      </Paper>
      <StyledTimeline>
        <JourneyCard
          title="Schedule call slot details"
          positions={{ flightPosition, cardPosition: 0 }}
          submitButton={false}
          key="Schedule call slot details"
        >
          <JourneyStats
            stats={[
              { title: "Slot Date", value: data?.schedule?.date ?? "" },
              { title: "Slot Time", value: data?.schedule?.slot ?? "" },
            ]}
          />
        </JourneyCard>

        {/* Step 2 */}
        <JourneyCard
          submitButton={false}
          title="Mentor details"
          positions={{ flightPosition, cardPosition: 1 }}
          key="Mentor details"
        >
          <JourneyStats
            stats={[
              { title: "Mentor Name", value: data?.mentor?.name },
              { title: "Mobile Number", value: data?.mentor?.mobile },
            ]}
          />
        </JourneyCard>

        {/* Step 3 */}
        <JourneyCard
          submitButton={
            data?.isPro
              ? data?.proPayment && data?.proAmount
                ? true
                : false
              : true
          }
          title="Stuzee pro status"
          positions={{ flightPosition, cardPosition: 2 }}
          handleSubmit={() => handleSubmit("PRO_PURCHASE")}
          key={"Stuzee pro status"}
        >
          <JourneyGeneralQuery
            query="Update the status of student's Stuzee pro purchase"
            value={data?.isPro}
            onChange={handleProChange}
            fieldName="isPro"
          />
          {data?.isPro && (
            <TextFieldGrid>
              <CustomTextField
                type="date"
                label="Payment date"
                value={data?.proPayment ?? ""}
                todayOnwards={true}
                fieldName="proPayment"
                onChange={handleChange}
                required
              />
              <CustomTextField
                label="Paid Amount"
                value={data?.proAmount ?? ""}
                fieldName="proAmount"
                type="number"
                amount={true}
                onChange={handleChange}
                required
              />
            </TextFieldGrid>
          )}
        </JourneyCard>

        {/* Step 4 */}
        <JourneyCard
          key={"Profile & Preferences"}
          title={"Profile & Preferences"}
          positions={{ flightPosition, cardPosition: 3 }}
          handleSubmit={() => handleSubmit("PROFILE_PREFERENCES")}
        >
          <JourneyGeneralQuery
            onChange={handleChange}
            value={data?.profilePreferences}
            query={
              <p>
                Update the status if the profile and preference <br />
                information of student is filled completely
              </p>
            }
            fieldName="profilePreferences"
          />
        </JourneyCard>

        {/* Step 5*/}
        <JourneyCard
          title={"Documents"}
          positions={{ flightPosition, cardPosition: 4 }}
          handleSubmit={() => handleSubmit("DOCS")}
        >
          <JourneyGeneralQuery
            onChange={handleChange}
            value={data?.docs}
            query={
              <p>
                Update the status if all the documents
                <br /> of student are uploaded
              </p>
            }
            fieldName="docs"
          />
        </JourneyCard>

        {/* Step 6*/}
        {/* pending */}
        <JourneyCard
          submitButton={shortlistCount ? false : true}
          title={"Shorlisted Universities"}
          positions={{ flightPosition, cardPosition: 5 }}
          handleSubmit={() => handleSubmit("SHORT_LISTED_UNIVERSITIES")}
        >
          <JourneyGeneralQuery
            query="Have you shortlisted universities for student"
            onChange={handleChange}
            value={data?.shortlist_count}
          />
          {shortlistCount ? (
            <ViewBanner
              title={"Total Shortlisted Courses"}
              buttonText={"View Shortlisted Universities"}
              count={shortlistCount}
              path={`/view-shortlisted-universities?userId=${userId}`}
            />
          ) : (
            <AddButton
              title={"Add Shortlisted Universities"}
              path={`/shortlisted-universities?userId=${userId}`}
            />
          )}
        </JourneyCard>

        {/* Step 7*/}
        <JourneyCard
          submitButton={
            data?.isSopLorService
              ? data?.sopLorPayment && data?.sopLorAmount
                ? true
                : false
              : true
          }
          title={"SOP & LOR Services"}
          positions={{ flightPosition, cardPosition: 6 }}
          handleSubmit={() => handleSubmit("SOP_LOR_SERVICE")}
        >
          <JourneyGeneralQuery
            query="Update the status if student wants SOP and LOR service"
            value={data?.isSopLorService}
            onChange={handleSOPChange}
            fieldName="isSopLorService"
          />
          {data?.isSopLorService && (
            <TextFieldGrid>
              <CustomTextField
                type="date"
                todayOnwards={true}
                label="Payment date"
                value={data?.sopLorPayment}
                fieldName="sopLorPayment"
                onChange={handleChange}
                required
              />
              <CustomTextField
                label="Paid Amount"
                amount={true}
                value={data?.sopLorAmount}
                type="number"
                fieldName="sopLorAmount"
                onChange={handleChange}
                required
              />
            </TextFieldGrid>
          )}
        </JourneyCard>

        {/* Step 8*/}
        <JourneyCard
          submitButton={sopDocsCount ? false : true}
          title={"SOP & LOR Documents"}
          positions={{ flightPosition, cardPosition: 7 }}
          handleSubmit={() => handleSubmit("SOP_LOR_DOCS")}
        >
          <JourneyGeneralQuery
            query="Update the status if documents are collected for SOP and LOR"
            onChange={handleChange}
            value={data?.sop_lor_docs_count}
          />
          {sopDocsCount ? (
            <ViewBanner
              title={"Total SOP & LOR Documents uploaded"}
              buttonText={"View SOP & LOR Documents"}
              count={sopDocsCount}
              path={`/sop-lor-status?userId=${userId}`}
            />
          ) : (
            <AddButton
              title={"Update Document Status"}
              path={`/sop-lor-status?userId=${userId}`}
            />
          )}
        </JourneyCard>

        {/* Step 9 */}
        <JourneyCard
          submitButton={
            data?.isFinancialAssistance
              ? data?.financialAssistanceAmount
                ? true
                : false
              : true
          }
          title="Financial Assistance"
          positions={{ flightPosition, cardPosition: 8 }}
          handleSubmit={() => handleSubmit("FINANCIAL_ASSISTANCE")}
        >
          <JourneyGeneralQuery
            query="Update the status if student requires financial assistance"
            fieldName={"isFinancialAssistance"}
            value={data?.isFinancialAssistance ?? ""}
            onChange={handleFinancialChange}
          />
          {data?.isFinancialAssistance && (
            <TextFieldGrid>
              <CustomTextField
                label="Enter Amount"
                amount={true}
                value={data?.financialAssistanceAmount}
                fieldName="financialAssistanceAmount"
                type="number"
                required
                onChange={handleChange}
              />
            </TextFieldGrid>
          )}
        </JourneyCard>

        {/* Step 10 */}
        <JourneyCard
          submitButton={
            data?.isScholarship ? (data?.scholarshipName ? true : false) : true
          }
          title="Scholarship Details"
          positions={{ flightPosition, cardPosition: 9 }}
          handleSubmit={() => handleSubmit("SCHOLARSHIP")}
        >
          <JourneyGeneralQuery
            query="Update the status if student has applied for scholarship"
            value={data?.isScholarship ?? ""}
            onChange={handleScholarshipChange}
            fieldName="isScholarship"
          />
          {data?.isScholarship && (
            <TextFieldGrid>
              <CustomTextField
                label="Enter Scholarship name"
                value={data?.scholarshipName ?? ""}
                fieldName="scholarshipName"
                onChange={handleChange}
                required
              />
            </TextFieldGrid>
          )}
        </JourneyCard>

        {/* Step 11 */}
        {/* pending */}
        <JourneyCard
          submitButton={appliedCoursesCount ? false : true}
          title={"Application"}
          positions={{ flightPosition, cardPosition: 10 }}
          handleSubmit={() => handleSubmit("APPLICATIONS")}
        >
          <JourneyGeneralQuery
            query="Have you applied universities for student"
            onChange={handleChange}
            value={data?.applied_courses_count}
          />
          {appliedCoursesCount ? (
            <ViewBanner
              title={"Total Applied Courses"}
              buttonText={"View Applied Universities & Courses"}
              count={appliedCoursesCount}
              path={`/view-applied-universities?userId=${userId}`}
            />
          ) : (
            <AddButton
              title={"Apply for Universities"}
              path={`/applied-universities?userId=${userId}`}
            />
          )}
        </JourneyCard>

        {/* Step 12 */}
        {/* pending */}
        <JourneyCard
          submitButton={approvalCount ? false : true}
          title={"University Approval"}
          positions={{ flightPosition, cardPosition: 11 }}
          handleSubmit={() => handleSubmit("UNIVERSITY_APPROVAL")}
        >
          <JourneyGeneralQuery
            query="Has any university approved student's application form"
            onChange={handleChange}
            value={approvalCount}
          />
          {approvalCount ? (
            <ViewBanner
              title={"Total University Approvals"}
              buttonText={"View University Approvals"}
              count={approvalCount}
              path={`/view-university-approvals?userId=${userId}`}
            />
          ) : (
            <AddButton
              title={"Select Approved Universities"}
              path={`/university-approvals?userId=${userId}`}
            />
          )}
        </JourneyCard>

        {/* Step 13 */}
        <JourneyCard
          submitButton={studentsChoiceCount ? false : true}
          title={"Student's choice"}
          positions={{ flightPosition, cardPosition: 12 }}
          handleSubmit={() => handleSubmit("STUDENTS_CHOICE")}
        >
          <JourneyGeneralQuery
            query="Did student choose any University/ Course"
            onChange={handleChange}
            value={studentsChoiceCount}
          />
          {studentsChoiceCount ? (
            <Paper
              elevation={2}
              sx={{
                width: "100%",
                padding: "20px",
                borderRadius: "15px !important",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {" "}
              <Button
                variant="outlined"
                color="secondary"
                style={{ width: "fit-content" }}
                onClick={() =>
                  router.push(`/view-students-choice?userId=${userId}`)
                }
              >
                View Student’s Choice
              </Button>
            </Paper>
          ) : (
            <AddButton
              title={"Select Student's Choice"}
              path={`/students-choice?userId=${userId}`}
            />
          )}
        </JourneyCard>

        {/* Step 14 */}
        <JourneyCard
          title={"Visa Slot Details"}
          positions={{ flightPosition, cardPosition: 13 }}
          handleSubmit={() => handleVisaSubmit("VISA_SLOT")}
        >
          <JourneyGeneralQuery
            query="Update the status if student's visa slot is booked"
            onChange={handleVisaSlotStatusChange}
            value={data?.visaSlots?.length}
          />
          {data?.visaSlots?.length || visaSlot ? (
            <VisaSlotDetails
              form={form}
              detailsForm={detailsForm}
              setDetailsForm={setDetailsForm}
              handleVisaChange={handleVisaChange}
              visaDetails={visaDetails}
              setVisaDetails={setVisaDetails}
            />
          ) : (
            ""
          )}
        </JourneyCard>

        {/* Step 15 */}
        <JourneyCard
          submitButton={
            data?.visaApproval ? (data?.visaApprovalDate ? true : false) : true
          }
          title="Visa Approval Status"
          positions={{ flightPosition, cardPosition: 14 }}
          handleSubmit={() => handleSubmit("VISA_APPROVAL")}
        >
          <JourneyGeneralQuery
            query="Update the status of student's visa approval"
            value={data?.visaApproval}
            onChange={handleVisaApprovalChange}
            fieldName="visaApproval"
          />
          {data?.visaApproval && (
            <TextFieldGrid>
              <CustomTextField
                label="Approval date"
                type="date"
                value={data?.visaApprovalDate ?? ""}
                fieldName="visaApprovalDate"
                onChange={handleChange}
                required
              />
            </TextFieldGrid>
          )}
        </JourneyCard>

        {/* Step 16 */}
        <JourneyCard
          title="Travel Assistance"
          positions={{ flightPosition, cardPosition: 15 }}
          handleSubmit={() => handleSubmit("TRAVEL_ASSISTANCE")}
        >
          <JourneyGeneralQuery
            query="Update the status if student requires the travel assistance"
            value={data?.isTravelAssistance ?? ""}
            onChange={handleChange}
            fieldName="isTravelAssistance"
          />
        </JourneyCard>

        {/* Step 17 */}
        <JourneyCard
          submitButton={
            data?.flightName && data?.flightTiming && data?.flightCost
              ? true
              : false
          }
          title="Flight details"
          positions={{ flightPosition, cardPosition: 16 }}
          handleSubmit={() => handleSubmit("FLIGHT_DETAILS")}
        >
          <TextFieldGrid>
            <CustomTextField
              label="Flight name"
              value={data?.flightName ?? ""}
              fieldName="flightName"
              onChange={handleChange}
              required
            />
            <CustomTextField
              label="Flight timings"
              type="datetime-local"
              value={data?.flightTiming ?? ""}
              fieldName="flightTiming"
              onChange={handleChange}
              required
            />
            <CustomTextField
              label="Cost"
              type="number"
              amount={true}
              value={data?.flightCost ?? ""}
              fieldName="flightCost"
              onChange={handleChange}
              required
            />
          </TextFieldGrid>
        </JourneyCard>

        {/* Step 18 */}
        <JourneyCard
          submitButton={
            data?.isTestimonial ? (data?.testimonial ? true : false) : true
          }
          title="Student Testimonials"
          positions={{ flightPosition, cardPosition: 17 }}
          handleSubmit={() => handleSubmit("TESTIMONIAL")}
        >
          <JourneyGeneralQuery
            query="Update the status if student is willing to give a testimonial"
            value={data?.isTestimonial ?? ""}
            onChange={handleTestimonialChange}
            fieldName="isTestimonial"
          />
          {data?.isTestimonial && (
            <CustomTextField
              label=""
              rows={"4"}
              placeholder="Write your comments..."
              value={data?.testimonial ?? ""}
              fieldName="testimonial"
              onChange={handleChange}
            />
          )}
        </JourneyCard>
        {/* Step 19 */}
        <JourneyCard
          submitButton={data?.joiningDate ? true : false}
          title="University Joining"
          positions={{ flightPosition, cardPosition: 18 }}
          handleSubmit={() => handleSubmit("UNIVERSITY_JOINING")}
        >
          <TextFieldGrid>
            <CustomTextField
              label="University Joining date"
              type="date"
              value={data?.joiningDate ?? ""}
              fieldName="joiningDate"
              onChange={handleChange}
              required
            />
          </TextFieldGrid>
        </JourneyCard>
        {/* </Box> */}
      </StyledTimeline>
    </StudentProfileLayout>
  );
}

export const VisaSlotDetails = ({
  form,
  detailsForm,
  setDetailsForm,
  handleVisaChange,
  visaDetails,
  setVisaDetails,
}) => {
  let slotNo = 1;

  function handleDelete(id) {
    console.log("delete");
    setDetailsForm((prev) => prev.filter((item, index) => id != item.key));
  }
  console.log(detailsForm.detailsForm);
  return (
    <>
      {Array.isArray(detailsForm) === true
        ? detailsForm?.map((item, index) => {
            return (
              <Box
                style={{ boxShadow: "0px 3px 15px #273B801A", padding: "1rem" }}
                key={item.key}
              >
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: "1rem",
                  }}
                >
                  <Typography>Slot - {slotNo}</Typography>
                  <Box style={{ display: "flex", justifyContent: "end" }}>
                    <IconButton
                      onClick={() => {
                        handleDelete(item.key);
                      }}
                      color="secondary"
                      size="small"
                    >
                      <CloseIcon size="small" />
                    </IconButton>
                  </Box>
                </Box>
                <TextFieldGrid>
                  <CustomVisaTextField
                    label={"I20 University Lock"}
                    defaultValue={visaDetails.university}
                    disabled
                  />
                  <CustomVisaSelect
                    label={"Visa Fee Status"}
                    defaultValue={"Not Paid"}
                    menuItems={[
                      { label: "Paid", value: "Paid" },
                      { label: "Not Paid", value: "Not Paid" },
                    ]}
                    onChange={handleVisaChange}
                    fieldName={"visaFee"}
                    disabled={false}
                    slotId={item.key}
                  />
                  <CustomVisaSelect
                    label={"Student Financials"}
                    menuItems={[
                      { label: "Loan", value: "Loan" },
                      { label: "Savings", value: "Savings" },
                      { label: "FD", value: "FD" },
                    ]}
                    onChange={handleVisaChange}
                    fieldName={"financial"}
                    disabled={false}
                    slotId={item.key}
                  />
                  <CustomVisaSelect
                    label={"DS-160 Form status"}
                    menuItems={[
                      { label: "Filled", value: "Filled" },
                      { label: "Submitted", value: "Submitted" },
                    ]}
                    onChange={handleVisaChange}
                    fieldName={"ds160"}
                    disabled={false}
                    slotId={item.key}
                  />

                  <CustomVisaSelect
                    label={"SEVIS Payments Status"}
                    menuItems={[
                      { label: "Paid", value: "Paid" },
                      { label: "Not Paid", value: "Not Paid" },
                    ]}
                    onChange={handleVisaChange}
                    fieldName={"sevisPaymentsStatus"}
                    disabled={false}
                    slotId={item.key}
                  />
                  <CustomVisaSelect
                    label={"Visa Interview Location"}
                    menuItems={[
                      { label: "Hyderabad", value: "Hyderabad" },
                      { label: "Chennai", value: "Chennai" },
                      { label: "Mumbai", value: "Mumbai" },
                      { label: "Kolkata", value: "Kolkata" },
                      { label: "Delhi", value: "Delhi" },
                    ]}
                    onChange={handleVisaChange}
                    fieldName={"location"}
                    disabled={false}
                    slotId={item.key}
                  />
                  <CustomVisaTextField
                    label={"OFC Appointment Date"}
                    type="datetime-local"
                    onChange={handleVisaChange}
                    fieldName={"ofcDate"}
                    disabled={false}
                    inputProps
                    slotId={item.key}
                  />
                  <CustomVisaTextField
                    label={"Visa Date"}
                    type="datetime-local"
                    onChange={handleVisaChange}
                    fieldName={"visaDate"}
                    disabled={false}
                    slotId={item.key}
                  />
                  <CustomVisaSelect
                    label={"Visa Mock Interview"}
                    menuItems={[
                      { label: "1st Mock", value: "1st Mock" },
                      { label: "2nd Mock", value: "2nd Mock" },
                    ]}
                    onChange={handleVisaChange}
                    fieldName={"mock"}
                    disabled={false}
                    slotId={item.key}
                  />

                  <CustomVisaSelect
                    label={"Stuzee Undertaking Form Status"}
                    menuItems={[
                      { label: "Yes", value: true },
                      { label: "No", value: false },
                    ]}
                    onChange={handleVisaChange}
                    fieldName={"stuzeeUndertakes"}
                    disabled={false}
                    slotId={item.key}
                  />
                  <CustomVisaSelect
                    label={"Visa Stamping Status"}
                    menuItems={[
                      { label: "Yes", value: true },
                      { label: "No", value: false },
                    ]}
                    onChange={handleVisaChange}
                    fieldName={"visaStamping"}
                    disabled={false}
                    slotId={item.key}
                  />
                </TextFieldGrid>
              </Box>
            );
          })
        : ""}
      <Button
        onClick={() => {
          console.log("add");
          setDetailsForm([...detailsForm, { ...form, key: Date.now() }]);
        }}
        variant="text"
        color="secondary"
      >
        +Add New Slot
      </Button>
    </>
  );
};

const CustomVisaSelect = ({
  label,
  defaultValue,
  menuItems,
  onChange,
  fieldName,
  disabled,
  slotId,
}) => {
  const [state, setState] = useState();

  useEffect(() => {
    setState(defaultValue);
  }, [defaultValue]);

  function handleChange(e) {
    setState(e.target.value);
    onChange(fieldName, e.target.value, slotId);
    console.log(e.target.value);
  }

  return (
    <FormControl sx={{ m: 1, minWidth: 220 }} size="small">
      <Typography style={{ fontSize: "14px", opacity: "0.5" }}>
        {label}
      </Typography>

      <Select
        value={state}
        variant="outlined"
        style={{ minWidth: "250px" }}
        onChange={handleChange}
        disabled={disabled}
      >
        {menuItems?.map((obj, ind) => {
          return <MenuItem value={obj?.value}>{obj?.label}</MenuItem>;
        })}
      </Select>
    </FormControl>
  );
};

const CustomVisaTextField = ({
  label,
  defaultValue,
  type,
  rows,
  placeholder,
  fieldName,
  onChange,
  required,
  todayOnwards,
  amount,
  slotId,
  disabled,
  ...props
}) => {
  const [state, setState] = useState(defaultValue);
  function handleChange(e) {
    setState(e.target.value);
    onChange(fieldName, e.target.value, slotId);
    console.log(e.target.value);
  }

  useEffect(() => {
    setState(defaultValue);
  }, [defaultValue]);

  return (
    <Box style={{ display: "grid", gap: "0.5rem" }}>
      <InputLabel htmlFor="select">
        {label}
        {required && <span style={{ color: "red" }}>*</span>}
      </InputLabel>

      <TextField
        type={type || "text"}
        variant="outlined"
        size="small"
        required={required}
        disabled={disabled}
        inputProps={
          todayOnwards && { min: moment(new Date()).format("YYYY-MM-DD") }
        }
        rows={rows || 1}
        multiline={!!rows}
        placeholder={placeholder || ""}
        value={state}
        InputProps={
          amount && {
            startAdornment: <InputAdornment position="start">₹</InputAdornment>,
          }
        }
        onChange={handleChange}
      ></TextField>
    </Box>
  );
};
