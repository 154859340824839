import { Button, CircularProgress, Table as MuiTable } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useHistory } from "react-router";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const headers = [
  "Name",
  // "Gender",
  "Mobile Number",
  "E-Mail ID",
  // "Country Preference",
  "Mentor",
  // "Status",
  // "User Type",
  "Actions",
];

const Table = ({ data }) => {
  if (!data) return <CircularProgress />;

  const rows = data?.map((item) => ({
    name: item.name,
    // gender: item.gender,
    mobile: item.mobile,
    email: item.email,
    // countryPreference: item?.country,
    mentor: item?.journey?.mentor?.name,
    // status: item?.status,
    // userType: item?.userType,
    actions: <Action id={item.id} />,
  }));

  return (
    <div className="">
      <TableContainer component={Paper}>
        <MuiTable size="small">
          <TableHead>
            <TableRow>
              {headers.map((item) => (
                <StyledTableCell key={item}>{item}</StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <StyledTableRow key={index}>
                {Object.keys(row).map((item, index) => (
                  <StyledTableCell key={index}>{row[item]}</StyledTableCell>
                ))}
              </StyledTableRow>
            ))}
          </TableBody>
        </MuiTable>
      </TableContainer>
    </div>
  );
};
export default Table;

const Action = ({ id }) => {
  const history = useHistory();
  const handleClick = () => {
    history.push(`/registrations/details/profile?userId=${id}`);
  };
  return (
    <Button onClick={handleClick} size="small" variant="contained">
      Profile
    </Button>
  );
};
