import { Box, Button, CircularProgress } from "@material-ui/core";
import MaterialTable from "material-table";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { http } from "../../services/http";

function Consultants() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  useEffect(() => {
    setLoading(true);
    getData();
  }, []);

  const handleEdit = (rowData) => {
    console.log(rowData);
    history.push({ pathname: `/consultants/registration`, state: rowData });
  };

  const getData = () => {
    http
      .get("/users/all", {
        params: {
          role: "MENTOR",
        },
      })
      .then((res) => {
        console.log(res.data);
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  function handleNewRegistration() {
    history.push("/consultants/registration");
  }

  if (loading) return <CircularProgress />;

  return (
    <Box>
      <Box my={2} style={{ justifyContent: "end", display: "flex" }}>
        <Button
          color="secondary"
          variant="contained"
          onClick={handleNewRegistration}
          style={{ color: "white" }}
        >
          Create Consultant
        </Button>
      </Box>
      <MaterialTable
        title="Users"
        options={{
          actionsColumnIndex: -1,
        }}
        actions={[
          {
            icon: "edit",
            onClick: (event, rowData) => {
              console.log(rowData);
              handleEdit(rowData);
            },
          },
        ]}
        data={data}
        columns={[
          { title: "Name", field: "name" },
          { title: "Mobile", field: "mobile" },
          { title: "Email", field: "email" },
        ]}
      />
    </Box>
  );
}

export default Consultants;
