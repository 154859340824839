import { Box, Button, CircularProgress } from "@material-ui/core";
import MaterialTable from "material-table";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { http } from "../../services/http";

const Webinars = () => {
  const history = useHistory();
  let { enqueueSnackbar } = useSnackbar();

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log("hi");
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    try {
      const res = await http.get("/webinars");
      setData(res.data);
      setLoading(false);
    } catch (err) {
      enqueueSnackbar("Error Loading data", { variant: "error" });
    }
  };

  const handleEdit = (rowData) => {
    console.log(rowData);
    history.push({ pathname: `/webinars/form`, state: rowData });
  };
  const handleDelete = (id) => {
    let isConfirm = window.confirm("Are you sure about Deleting it?");
    isConfirm && deleteColumn(id);
    return;
  };

  const deleteColumn = async (id) => {
    try {
      const res = await http.delete(`/webinars/${id}`);
      if (res.status === 200) {
        enqueueSnackbar("Deleted Successfully", { variant: "success" });
        getData();
      }
    } catch (err) {
      enqueueSnackbar("Error Deleting", { variant: "error" });
    }
  };

  if (loading) return <CircularProgress />;
  console.log(data);
  return (
    <div>
      <Box>
        <Box mb={2} textAlign="right">
          <Button
            onClick={() => history.push("/webinars/form")}
            color="secondary"
            variant="contained"
          >
            Add
          </Button>
        </Box>
        {data && (
          <MaterialTable
            title="Webinars"
            options={{
              actionsColumnIndex: -1,
            }}
            actions={[
              {
                icon: "edit",
                onClick: (event, rowData) => {
                  console.log(rowData);
                  handleEdit(rowData);
                },
              },
            ]}
            data={data}
            columns={[
              { title: "Name", field: "title" },
              { title: "Webinar Link", field: "link" },
              {
                title: "Date",
                field: "createdAt",
                render: (rowData) =>
                  moment(rowData.createdAt).format("YYYY-MM-DD"),
              },
            ]}
          />
        )}
      </Box>
    </div>
  );
};

export default Webinars;
