import { Box, CircularProgress, makeStyles } from "@material-ui/core";
import { Paper } from "@mui/material";
import { useEffect, useState } from "react";
import { http } from "../../../services/http";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    gap: 30,
    "& > div": {
      minWidth: 320,
      background: theme.palette.primary.light,
      borderRadius: theme.spacing(1),
      padding: theme.spacing(3),
      color: theme.palette.primary.main,
      fontWeight: "bold",
      "& > div": {
        marginTop: theme.spacing(1),
        fontSize: "1.2rem",
      },
    },
  },
}));

const Stats = ({ students }) => {
  const classes = useStyles();

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    try {
      const res = await http.get("/users/dashboard-stats");
      setData(res.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  if (loading) return <CircularProgress />;
  return (
    <div className={classes.root}>
      <Paper elevation={2} sx={{ backgroundColor: "white !important" }}>
        <span style={{ color: "black" }}>Total Registrations</span>
        <div style={{ color: "black" }}>{students?.length}</div>
      </Paper>
      {/* <Box>
				<span>Active Users</span>
				<div>{data?.activeUsers || 0}</div>
			</Box>
			<Box>
				<span>Inactive Users</span>
				<div>{data?.inactiveUsers || 0}</div>
			</Box> */}
    </div>
  );
};

export default Stats;
