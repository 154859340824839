import React from "react";
import JourneyAnalyticsTable from "../../components/JourneyAnalyticsTable";

function VisaApproval() {
  return (
    <JourneyAnalyticsTable status={"VISA_APPROVAL"} title={"Visa Approval"} />
  );
}

export default VisaApproval;
