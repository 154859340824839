import React from "react";
import JourneyAnalyticsTable from "../../components/JourneyAnalyticsTable";

function UniversityJoining() {
  return (
    <JourneyAnalyticsTable
      status={"UNIVERSITY_JOINING"}
      title={"University Joining"}
    />
  );
}

export default UniversityJoining;
