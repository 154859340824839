import {Box, Button} from '@material-ui/core'
import Add from '@material-ui/icons/Add'
import {makeStyles} from '@material-ui/styles'
import {useSnackbar} from 'notistack'
import {useEffect, useState} from 'react'
import {http} from '../../../../services/http'
import Field from './Field'

const useStyles = makeStyles((theme) => ({
	grid: {
		display: 'grid',
		gridTemplateColumns: '1fr 1fr 1fr',
		gap: '15px',
		borderBottom: 'none',
		'&:not(last-of-type)': {
			marginBottom: '30px',
		},
	},
}))

const Form = ({form, userId}) => {
	const classes = useStyles()
	const [state, setState] = useState(null)
	const {enqueueSnackbar} = useSnackbar()

	useEffect(() => {
		let data = {...form}
		setState(data)
	}, [form])

	const handleSubmit = async (e) => {
		e.preventDefault()
		const url = `/eav/${userId}/${state.uniqueName}`

		const postBody = state.data.map((form) => {
			const data = {
				...form,
				data: {
					list: form.fields.map((field) => {
						const data = {
							...field,
							value: field?.value?.value,
							attributeType: field.type,
							typeId: form.eavTypeId,
							attributeId: field.id,
						}
						delete data.entityTypeId
						delete data.id
						delete data.name
						delete data.options
						delete data.type
						delete data.frontEndType

						return data
					}),
				},
			}

			delete data.fields

			return data
		})

		try {
			const res = await http.post(url, postBody)
			enqueueSnackbar(`Saved ${state.name}`, {variant: 'success'})
		} catch (err) {
			enqueueSnackbar(err?.response.data.message, {variant: 'error'})
		}
	}

	const handleAddNewForm = () => {
		setState((prev) => ({
			...prev,
			data: [
				...prev.data,
				{
					...prev.data[0],
					id: null,
					fields: prev.data[0].fields.map((item) => {
						const {value, ...rest} = item
						return rest
					}),
				},
			],
		}))
	}

	const onChange = (fieldIndex, formIndex, value) => {
		let newState = {...state}
		newState.data[formIndex].fields[fieldIndex].value = {value}
		setState(newState)
	}

	return (
		<form onSubmit={handleSubmit} style={{margin: '1rem'}}>
			<Box>
				{state?.data?.map((formItem, formIndex) => (
					<>
						<Box className={classes.grid}>
							{formItem?.fields.map((item, fieldIndex) => (
								<Field
									data={item}
									onChange={(value) => onChange(fieldIndex, formIndex, value)}
								/>
							))}
						</Box>
					</>
				))}
			</Box>
			<Box>
				{!state?.single && (
					<Button
						size='small'
						color='secondary'
						startIcon={<Add />}
						onClick={handleAddNewForm}
					>
						Add New
					</Button>
				)}
			</Box>
			<Box mt={4} display='flex' gridGap={30}>
				<Button variant='outlined' color='secondary' type='submit'>
					Save
				</Button>
				<Button
					variant='contained'
					color='secondary'
					// onClick={handleSaveAndContinue}
				>
					Save and Continue
				</Button>
			</Box>
		</form>
	)
}

export default Form
