import {
  Box,
  Button,
  Dialog,
  OutlinedInput,
  useTheme,
} from "@material-ui/core";
import {
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { Add, Search } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { http } from "../../services/http";
import {
  CardsContainer,
  CourseCard,
  FlexBox,
  ScoreCards,
  Stats,
} from "../container";
import { OutlinedButton } from "../OutlinedButton";
import { BoldTypo } from "../Typography/BoldTypo";
import { LightTypo } from "../Typography/LightTypo";
import { useQueryClient } from "@tanstack/react-query";

import SearchField from "../universities/SearchField";
import ShortlistDialog from "./DialogComponent";
import { useSnackbar } from "notistack";

const initState = {
  universityName: "",
  courseName: "",
  intake: [],
  status: "SHORT_LISTED_UNIVERSITIES",
  approvalType: "",
  chancesOfApproval: null,
  comments: null,
};

export default function CoursesContainer({ univId, buttonText, userId }) {
  const [data, setData] = useState([]);
  const theme = useTheme();
  const [loading, setLoading] = useState(false);

  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const queryClient = useQueryClient();
  let { enqueueSnackbar } = useSnackbar();

  const [shortlistBody, setShortlistBody] = useState(initState);
  const handleClickOpen = (obj) => {
    setOpen(true);
    setShortlistBody({ ...shortlistBody, ...obj });
    console.log({ ...shortlistBody, ...obj });
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setLoading(true);
    getPreferredCourses();
  }, [univId]);

  console.log(shortlistBody);

  const addToShortlist = () => {
    if (
      !shortlistBody?.approvalType ||
      !shortlistBody?.chancesOfApproval ||
      shortlistBody?.chancesOfApproval === 0
    ) {
      setOpen(true);
      enqueueSnackbar("Please fill the required fields", { variant: "error" });
    } else {
      http
        .put(`/shortlist/add-to-shortlist/user/${userId}`, {
          ...shortlistBody,
          userId,
        })
        .then((res) => {
          queryClient.invalidateQueries("shortlisted");
          setShortlistBody(initState);
        });
    }
  };

  const handleChange = (field, value) => {
    setShortlistBody({ ...shortlistBody, [field]: value });
  };

  async function getPreferredCourses() {
    try {
      http
        .get(`/universities/courses/${univId}?search=${search}`)
        .then((res) => {
          console.log(res);
          setData(res?.data?.results);
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  }

  console.log(data);

  const handleSearch = (e) => {
    e.preventDefault();
    getPreferredCourses();
  };
  if (loading) {
    return (
      <Box mt={10} textAlign="center">
        <CircularProgress />
      </Box>
    );
  }
  return (
    <>
      <CardsContainer
        color={theme.palette.primary.light}
        title={"Courses"}
        titleColor={`${theme.palette.primary.main}`}
      >
        <form className="mb-4" onSubmit={handleSearch}>
          <TextField
            fullWidth
            color="primary"
            value={search}
            onChange={({ target }) => setSearch(target.value)}
            variant="outlined"
            size="small"
            placeholder="Search"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton type="submit" size="large">
                    <Search fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </form>
        {data.length ? (
          <>
            {data.map((course) => {
              return (
                <CourseCard>
                  <FlexBox style={{ justifyContent: "space-between" }}>
                    <Box>
                      <BoldTypo color="primary">{course.name}</BoldTypo>
                    </Box>
                    <Box>
                      {
                        <OutlinedButton
                          onClick={() =>
                            handleClickOpen({
                              universityName: course.university.name,
                              universityId: course.university.id,
                              courseName: course.name,
                              courseId: course.id,
                              intake: course.intake.split(", "),
                            })
                          }
                          // style={{ height: "fit-content", margin: "auto" }}
                        >
                          {"Shortlist"}
                        </OutlinedButton>
                      }
                    </Box>
                  </FlexBox>

                  <FlexBox>
                    <Stats title="Duration" value={`${course.duration} ${course.duration == 1 ? "Year" : "Years"}`} />
                    <Stats
                      title="Approximate fees"
                      value={`${course.university.averageTutionFee} ${course?.university?.currency}`}
                    />
                  </FlexBox>
                  <Box>
                    <LightTypo>Exam Scores Accepted</LightTypo>
                    <FlexBox>
                      <ScoreCards title="GRE" score={course.gre} />
                      <ScoreCards title="IELTS" score={course.ielts} />
                      <ScoreCards title="TOEFL" score={course.toefl} />
                    </FlexBox>
                  </Box>
                </CourseCard>
              );
            })}
          </>
        ) : (
          <Box mt={10} textAlign="center">
            <CircularProgress />
          </Box>
        )}
      </CardsContainer>
      <ShortlistDialog
        addToShortlist={addToShortlist}
        handleClose={handleClose}
        open={open}
        handleChange={handleChange}
      />
    </>
  );
}
