import { Box, Button, Grid, TextField, Typography } from "@material-ui/core";
import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import CustomUpload from "../../components/CustomUpload";
import { http } from "../../services/http";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import VisaOptions from "./VisaOptions";

function AddCountry() {
  const router = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useState({
    name: "",
    order: "",
    image: "",
    quickFacts: {
      capital: "",
      languages: "",
      gdp: "",
      currency: "",
      population: "",
      dialingCode: "",
    },
    overview: "",
    admissionProcess: "",
    culture: "",
    partTimeJobs: "",
    visaOptions: [
      {
        name: "",
        applicationFee: "",
        validity: "",
        about: "",
        requirements: "",
      }
    ],
    intakes: "",
  });

  function handleChange(e) {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  }

  function handleQuickFacts(e) {
    setState({
      ...state,
      quickFacts: {
        ...state.quickFacts,
        [e.target.name]: e.target.value,
      },
    });
  }

  function handleContentChange(key, v) {
    setState({
      ...state,
      [key]: v,
    });
  }

  function handleSubmit() {
    let isInvalid =
      Object.keys(state).some((key) => state[key] == "") ||
      Object.keys(state.quickFacts).some(
        (key) => state["quickFacts"][key] == ""
      );
    if (isInvalid) {
      enqueueSnackbar("Enter All Details", {
        variant: "error",
      });
      return;
    }
    http
      .post("/countries", state)
      .then((res) => {
        enqueueSnackbar("Country created successfully.", {
          variant: "success",
        });
        router.push("/countries");
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <Box p={3}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Box>
            <TextField
              onChange={handleChange}
              fullWidth
              variant="outlined"
              margin="dense"
              label="Name"
              name="name"
            />
          </Box>
          <Box>
            <TextField
              onChange={handleChange}
              fullWidth
              variant="outlined"
              margin="dense"
              label="Order"
              name="order"
            />
          </Box>
          <Box mt={1}>
            <Typography variant="h6" gutterBottom color="primary">
              Image
            </Typography>
            <CustomUpload
              name="image"
              onChange={(v) => setState({ ...state, image: v })}
            />
          </Box>
          <Box mt={2}>
            <Typography variant="h6" color="primary">
              Quick Facts
            </Typography>
            <Box>
              <TextField
                fullWidth
                onChange={handleQuickFacts}
                variant="outlined"
                margin="dense"
                label="Capital"
                name="capital"
              />
            </Box>
            <Box mt={1}>
              <TextField
                fullWidth
                onChange={handleQuickFacts}
                variant="outlined"
                margin="dense"
                label="Languages"
                name="languages"
              />
            </Box>
            <Box mt={1}>
              <TextField
                fullWidth
                variant="outlined"
                margin="dense"
                label="GDP"
                name="gdp"
                onChange={handleQuickFacts}
              />
            </Box>
            <Box mt={1}>
              <TextField
                fullWidth
                variant="outlined"
                margin="dense"
                label="Currency"
                name="currency"
                onChange={handleQuickFacts}
              />
            </Box>
            <Box mt={1}>
              <TextField
                fullWidth
                variant="outlined"
                margin="dense"
                label="Population"
                name="population"
                onChange={handleQuickFacts}
              />
            </Box>
            <Box mt={1}>
              <TextField
                fullWidth
                variant="outlined"
                margin="dense"
                label="Dialing Code"
                name="dialingCode"
                onChange={handleQuickFacts}
              />
            </Box>
            <VisaOptions state={state} setState={setState} />
            <Box mt={1}>
              <Typography variant="h6" gutterBottom color="primary">
                Intakes
              </Typography>
              <TextField
                fullWidth
                onChange={handleChange}
                variant="outlined"
                margin="dense"
                label="Intakes"
                name="intakes"
              />
              <Typography color="textSecondary" variant="caption">
                Enter Intakes separate with comma.
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box>
            <Typography variant="h6" gutterBottom color="primary">
              Overview
            </Typography>
            <TextField
              fullWidth
              onChange={handleChange}
              variant="outlined"
              rows={7}
              multiline
              name="overview"
              placeholder="Overview"
            />
          </Box>
          <Box mt={2}>
            <Typography variant="h6" gutterBottom color="primary">
              Culture
            </Typography>
            <TextField
              fullWidth
              onChange={handleChange}
              variant="outlined"
              rows={7}
              multiline
              name="culture"
              placeholder="Culture"
            />
          </Box>
          <Box mt={2}>
            <Typography variant="h6" gutterBottom color="primary">
              Admission Process
            </Typography>
            <ReactQuill
              onChange={(v) => handleContentChange("admissionProcess", v)}
            />
          </Box>
          <Box mt={2}>
            <Typography variant="h6" gutterBottom color="primary">
              Part time jobs
            </Typography>
            <ReactQuill
              onChange={(v) => handleContentChange("partTimeJobs", v)}
            />
          </Box>
          <Box mt={2} textAlign="right">
            <Button
              onClick={handleSubmit}
              size="medium"
              variant="contained"
              color="secondary"
            >
              Submit
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default AddCountry;
