import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import moment from "moment";
import React, { useState } from "react";
import { http } from "../services/http";

const DateFilter = ({ setData, styles }) => {
  const [date, setDate] = useState({ fromDate: "", toDate: "" });

  async function handleDateSearch() {
    if (date.fromDate !== "" && date.toDate !== "") {
      setData(null);
      try {
        const res = await http.get(
          `/users/all?fromDate=${date.fromDate}&toDate=${date.toDate}`
        );
        setData(res.data);
      } catch (err) {
        console.log(err);
      }
    }
  }

  async function handleClearSearch() {
    setData(null);
    setDate({ fromDate: "", toDate: "" });
    try {
      const res = await http.get(`/users/all`);
      setData(res.data);
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <Box sx={styles}>
      <TextField
        type="date"
        id="outlined-basic"
        label="From"
        variant="outlined"
        size="small"
        InputLabelProps={{ shrink: true }}
        value={date.fromDate}
        onChange={(e) => setDate({ ...date, fromDate: e.target.value })}
      />
      <TextField
        type="date"
        id="outlined-basic"
        label="To"
        variant="outlined"
        size="small"
        InputLabelProps={{ shrink: true }}
        value={date.toDate}
        inputProps={{ min: moment(date.fromDate).format("YYYY-MM-DD") }}
        onChange={(e) => setDate({ ...date, toDate: e.target.value })}
      />
      <Button onClick={handleDateSearch} variant="outlined" color="secondary">
        Search
      </Button>
      <Button onClick={handleClearSearch} variant="outlined" color="secondary">
        Clear
      </Button>
    </Box>
  );
};

export default DateFilter;
