import React, { useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  Text,
  TextField,
} from "@mui/material";
import { Search } from "@material-ui/icons";
import { http } from "../services/http";
import { useEffect } from "react";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Button, Table as MuiTable } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { useSnackbar } from "notistack";
import Pagination from "@mui/material/Pagination";
import EmptyScreen from "./EmptyScreen";
import { BoldTypo } from "./Typography/BoldTypo";
import { validateMobile } from "../services/validations";

function JourneyAnalyticsTable({ title, status }) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const navigate = useHistory();
  const [searchString, setSearchString] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const router = useHistory();
  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

  async function handleSubmitSearch(e) {
    e.preventDefault();
    if (searchString !== "") {
      if (!validateMobile(searchString)) {
        enqueueSnackbar("Please enter a valid Mobile Number", {
          variant: "warning",
        });
      } else {
        try {
          const res = await http.get(
            `/journeys/users-by-status?status=${status}&search=${searchString}&limit=10&offset=0`
          );
          setData(res.data);
          console.log(res.data);
        } catch (err) {
          console.log(err);
        }
      }
    } else {
      try {
        const res = await http.get(
          `/journeys/users-by-status?status=${status}&limit=10&offset=0`
        );
        setData(res.data);
        console.log(res.data);
      } catch (err) {
        console.log(err);
      }
    }
  }

  async function handlePagination(e, v) {
    setPage(v);
    try {
      const res = await http.get(
        `/journeys/users-by-status?status=${status}&limit=10&offset=${v * 10}`
      );
      console.log(res);
      setData(res.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }

  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    setLoading(true);
    try {
      const res = await http.get(
        `/journeys/users-by-status?status=${status}&limit=10&offset=0`
      );
      console.log(res);
      setData(res.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };
  if (loading) return <CircularProgress />;

  console.log(data);

  const headers = ["Name", "Mobile Number", "E-Mail ID", "Actions"];

  const rows = data?.map((item) => ({
    name: item.name,
    mobile: item.mobile,
    email: item.email,
    actions: <Action id={item.id} />,
  }));

  return (
    <div>
      <Box sx={{ display: "flex", alignItems: "center", marginBottom: "2rem" }}>
        <ArrowBackIcon
          sx={{ color: "#D72245", cursor: "pointer", fontSize: "medium" }}
          onClick={() => navigate.goBack()}
        />
        <BoldTypo style={{ color: "#D72245", marginLeft: "0.3rem" }}>
          {title}
        </BoldTypo>
      </Box>
      {data?.length ? (
        <Box>
          <form onSubmit={handleSubmitSearch}>
            <Box
              display="flex"
              alignItems="center"
              width="40%"
              sx={{ marginBottom: "1rem" }}
            >
              <TextField
                value={searchString}
                onChange={(e) => setSearchString(e.target.value)}
                variant="outlined"
                placeholder="Search for a Mobile Number"
                size="small"
                fullWidth
                style={{ background: "rgba(0, 0, 0, 0.05)" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleSubmitSearch}>
                        <SearchIcon fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </form>
          <TableContainer component={Paper}>
            <MuiTable size="small">
              <TableHead>
                <TableRow>
                  {headers.map((item) => (
                    <StyledTableCell key={item}>{item}</StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <StyledTableRow key={index}>
                    {Object.keys(row).map((item, index) => (
                      <StyledTableCell key={index}>{row[item]}</StyledTableCell>
                    ))}
                  </StyledTableRow>
                ))}
              </TableBody>
            </MuiTable>
          </TableContainer>
          <Box mt={3} display="flex" justifyContent="center">
            <Pagination
              count={data?.length > 10 ? Math.ceil(data?.length / 10) : 1}
              variant="outlined"
              shape="rounded"
              onChange={handlePagination}
              page={page}
              siblingCount={0}
              boundaryCount={1}
            />
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <EmptyScreen heading={"No Students in this part of Journey"} />
        </Box>
      )}
    </div>
  );
}

const Action = ({ id }) => {
  const history = useHistory();
  const handleClick = () => {
    history.push(`/registrations/details/student-journey?userId=${id}`);
  };
  return (
    <Button onClick={handleClick} size="small" variant="contained">
      Journey
    </Button>
  );
};

export default JourneyAnalyticsTable;
