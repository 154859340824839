import { Box, Button, Typography } from "@material-ui/core";

export const UniversityCard = ({
  id,
  name,
  city,
  country,
  logoUrl,
  slug,
  selectedId,
}) => {
  return (
    <Box
      sx={{
        fontWeight: 400,
        // boxShadow: "0px 3px 15px #273b8014",

        boxShadow:
          selectedId && selectedId === id
            ? "0px 3px 15px #273B80"
            : "0px 3px 15px #273b8014",

        display: "flex",
        padding: "20px",
        margin: "16px",
        borderRadius: "20px",
        cursor: "pointer",
        // [theme.breakpoints.down("sm")]: {
        //   flexDirection: "column",
        //   color: "red",
        // },
        gap: "1rem",
        "@media (max-width:600px)": {
          flexDirection: "column",
        },
      }}
    >
      <Box>
        <img src={`${logoUrl}`} style={{ width: "140px" }} />
      </Box>
      <Box>
        <Box sx={{ alignItems: "center" }}>
          <Typography
            variant="h5"
            style={{
              fontSize: "1rem"
            }}
            color="primary"
          >
            {name}
          </Typography>
        </Box>
        <Box>
          <Typography
            style={{ fontSize: "0.8rem", color: "rgba(0, 0, 0, 0.6)" }}
          >
            {city + ", " + country}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
