import React from "react";
import { Route, Switch } from "react-router-dom";
import { profileStore } from ".";
import AdminUsers from "../pages/admin-users";
import { CreateAdmin } from "../pages/admin-users/CreateAdmin";
import AreasOfStudy from "../pages/areasofstudy";
import CreateAreaOfStudy from "../pages/areasofstudy/CreateAreaOfStudy";
import EditAreaOfStudy from "../pages/areasofstudy/EditAreaOfStudy";
import Attributes from "../pages/attributes";
import AttributesEdit from "../pages/attributes/AttributesEdit";
import ConsultantPanel from "../pages/consultantPanel";
import Consultants from "../pages/consultants";
import { CreateConsultant } from "../pages/consultants/CreateConsultant";
import Consultations from "../pages/consultations";
import Countries from "../pages/countries";
import AddCountry from "../pages/countries/AddCountry";
import EditCountry from "../pages/countries/EditCountry";
import Dashboard from "../pages/dashboard";
import Exams from "../pages/exams";
import ExamsForm from "../pages/exams/ExamsForm";
import ImportData from "../pages/importdata";
import Languages from "../pages/languages";
import LanguagesForm from "../pages/languages/LanguagesForm";
import Leads from "../pages/leads";
import LevelOfStudy from "../pages/level-of-study";
import LOSForm from "../pages/level-of-study/Form";
import News from "../pages/news";
import AddNews from "../pages/news/AddNews";
import UserDetailsLayout from "../pages/registration";
import RegistrationHome from "../pages/registration/home";
import Scholarships from "../pages/scholarships";
import ScholarshipsForm from "../pages/scholarships/ScholarshipsForm";
import ShortlistUniversities from "../pages/universities/ShortlistUniversities";
import ShortlistedUniversitiesAndCourses from "../pages/universities/ShortlistedUniversitiesAndCourses";
import Universities from "../pages/universities/Universities";
import UniversityInfo from "../pages/universities/UniversityInfo";
import Webinars from "../pages/webinars";
import WebinarForm from "../pages/webinars/WebinarForm";
import SOPandLORstatus from "../components/SOPandLOR/SOPandLORstatus";
import AppliedUniversities from "../components/AppliedUniversities/AppliedUniversities";
import ViewAppliedUniversities from "../components/AppliedUniversities/ViewAppliedUniversities";
import UniversityApprovals from "../components/UniversityApprovals/UniversityApprovals";
import ViewApprovedUniversities from "../components/UniversityApprovals/ViewUniversityApprovals";
import StudentsChoice from "../components/StudentsChoice/StudentsChoice";
import ViewStudentsChoice from "../components/StudentsChoice/ViewStudentsChoice";
import ViewUniversityApprovals from "../components/UniversityApprovals/ViewUniversityApprovals";
import PreferredUnivs from "../pages/dashboard/PreferredUnivs";
import AreaOfStudy from "../pages/dashboard/AreaofStudy";
import LevelsOfStudy from "../pages/dashboard/LevelsOfStudy";
import PreferredExams from "../pages/dashboard/PreferredExams";
import PreferredLanguages from "../pages/dashboard/PreferredLanguages";
import PreferredCountries from "../pages/dashboard/PreferredCountries";
import MentorConsultations from "../pages/dashboard/MentorConsultations";
import MentorAssigned from "../pages/dashboard/MentorAssigned";
import ProfileAndPreferences from "../pages/dashboard/ProfileAndPreferences";
import ProPurchases from "../pages/dashboard/ProPurchases";
import Documents from "../pages/dashboard/Documents";
import ShortlistedUnivs from "../pages/dashboard/ShortlistedUnivs";
import SOPandLORServices from "../pages/dashboard/SOPandLORServices";
import SOPandLORDocuments from "../pages/dashboard/SOPandLORDocuments";
import FinancialAssistance from "../pages/dashboard/FinancialAssistance";
import StudentsOfScholarship from "../pages/dashboard/StudentsOfScholarship";
import UniversityApplied from "../pages/dashboard/UniversityApplied";
import UnivsApprovals from "../pages/dashboard/UniversityApprovals";
import StudntsChoice from "../pages/dashboard/StudntsChoice";
import VisaSlotBook from "../pages/dashboard/VisaSlotBook";
import VisaApproval from "../pages/dashboard/VisaApproval";
import TravelAssistance from "../pages/dashboard/TravelAssistance";
import FlightDetails from "../pages/dashboard/FlightDetails";
import Testimonials from "../pages/dashboard/Testimonials";
import UniversityJoining from "../pages/dashboard/UniversityJoining";

function Routes() {
  const roles = profileStore.useState((s) => s.roles.map((role) => role.name));

  const authenticate = roles[0] == "ADMIN" || roles[1] == "ADMIN";
  return (
    <>
      {authenticate && (
        <>
          <Switch>
            <Route path={"/shortlisted-universities"}>
              <ShortlistUniversities />
            </Route>
            <Route path="/preferred-universities">
              <PreferredUnivs />
            </Route>
            <Route path="/preferred-areas-of-study">
              <AreaOfStudy />
            </Route>
            <Route path="/preferred-levels-of-study">
              <LevelsOfStudy />
            </Route>
            <Route path="/preferred-exams">
              <PreferredExams />
            </Route>
            <Route path="/preferred-countries">
              <PreferredCountries />
            </Route>
            <Route path="/preferred-languages">
              <PreferredLanguages />
            </Route>
            <Route path="/mentor-consultation">
              <MentorConsultations />
            </Route>
            <Route path="/mentor-assigned">
              <MentorAssigned />
            </Route>
            <Route path="/profile-preferences">
              <ProfileAndPreferences />
            </Route>
            <Route path="/pro-purchases">
              <ProPurchases />
            </Route>
            <Route path="/documents">
              <Documents />
            </Route>
            <Route path="/shortlisted-univs">
              <ShortlistedUnivs />
            </Route>
            <Route path="/sop-lor-services">
              <SOPandLORServices />
            </Route>
            <Route path="/sop-lor-documents">
              <SOPandLORDocuments />
            </Route>
            <Route path="/financial-assistance">
              <FinancialAssistance />
            </Route>
            <Route path="/students-of-scholarship">
              <StudentsOfScholarship />
            </Route>
            <Route path="/university-applications">
              <UniversityApplied />
            </Route>
            <Route path="/univ-approvals">
              <UnivsApprovals />
            </Route>
            <Route path="/choice-of-student">
              <StudntsChoice />
            </Route>
            <Route path="/visa-slot-booking">
              <VisaSlotBook />
            </Route>
            <Route path="/visa-approval">
              <VisaApproval />
            </Route>
            <Route path="/travel-assistance">
              <TravelAssistance />
            </Route>
            <Route path="/flight-details">
              <FlightDetails />
            </Route>
            <Route path="/testimonial">
              <Testimonials />
            </Route>
            <Route path="/university-joining">
              <UniversityJoining />
            </Route>
            <Route path={"/view-shortlisted-universities"}>
              <ShortlistedUniversitiesAndCourses />
            </Route>
            <Route path={"/university-approvals"}>
              <UniversityApprovals />
            </Route>
            <Route path={"/view-university-approvals"}>
              <ViewUniversityApprovals />
            </Route>
            <Route path={"/view-approved-universities"}>
              <ViewApprovedUniversities />
            </Route>
            <Route path={"/sop-lor-status"}>
              <SOPandLORstatus />
            </Route>
            <Route path={"/applied-universities"}>
              <AppliedUniversities />
            </Route>
            <Route path={"/view-applied-universities"}>
              <ViewAppliedUniversities />
            </Route>
            <Route path={"/students-choice"}>
              <StudentsChoice />
            </Route>
            <Route path={"/view-students-choice"}>
              <ViewStudentsChoice />
            </Route>
            <Route path="/webinars/form">
              <WebinarForm />
            </Route>
            <Route path="/webinars">
              <Webinars />
            </Route>
            <Route path="/scholarships/form">
              <ScholarshipsForm />
            </Route>
            <Route path="/scholarships">
              <Scholarships />
            </Route>
            <Route path="/exams/form">
              <ExamsForm />
            </Route>
            <Route path="/exams">
              <Exams />
            </Route>
            <Route path="/languages/form">
              <LanguagesForm />
            </Route>
            <Route path="/languages">
              <Languages />
            </Route>
            <Route path="/level-of-study/form">
              <LOSForm />
            </Route>
            <Route path="/level-of-study">
              <LevelOfStudy />
            </Route>
            <Route path="/registrations/details">
              <UserDetailsLayout />
            </Route>
            <Route exact path="/registrations">
              <RegistrationHome />
            </Route>
            <Route path="/attributes/:id">
              <AttributesEdit />
            </Route>
            <Route path="/attributes">
              <Attributes />
            </Route>
            <Route path="/countries/add">
              <AddCountry />
            </Route>
            <Route path="/countries/:id">
              <EditCountry />
            </Route>
            <Route path="/countries">
              <Countries />
            </Route>
            <Route path="/areas-of-study/add">
              <CreateAreaOfStudy />
            </Route>
            <Route path="/areas-of-study/:id/edit">
              <EditAreaOfStudy />
            </Route>
            <Route path="/areas-of-study">
              <AreasOfStudy />
            </Route>
            <Route path="/news/add">
              <AddNews />
            </Route>
            <Route path="/news">
              <News />
            </Route>
            <Route path="/admin-users/registration">
              <CreateAdmin />
            </Route>
            <Route path="/admin-users">
              <AdminUsers />
            </Route>
            <Route path="/consultations">
              <Consultations />
            </Route>
            <Route path="/consultants/registration">
              <CreateConsultant />
            </Route>
            <Route path="/consultants">
              <Consultants />
            </Route>
            <Route path="/leads">
              <Leads />
            </Route>
            <Route path="/master-import">
              <ImportData />
            </Route>
            <Route path="/dashboard">
              <Dashboard />
            </Route>
            <Route path="/">
              <Dashboard />
            </Route>
          </Switch>
        </>
      )}
      {!authenticate && (
        <>
          <Switch>
            <Route path="/universities/:id">
              <UniversityInfo />
            </Route>
            <Route path="/universities">
              <Universities />
            </Route>
            <Route path={"/shortlisted-universities"}>
              <ShortlistUniversities />
            </Route>
            <Route path={"/view-shortlisted-universities"}>
              <ShortlistedUniversitiesAndCourses />
            </Route>
            <Route path={"/university-approvals"}>
              <UniversityApprovals />
            </Route>
            <Route path={"/view-university-approvals"}>
              <ViewUniversityApprovals />
            </Route>
            <Route path={"/view-approved-universities"}>
              <ViewApprovedUniversities />
            </Route>
            <Route path={"/sop-lor-status"}>
              <SOPandLORstatus />
            </Route>
            <Route path={"/applied-universities"}>
              <AppliedUniversities />
            </Route>
            <Route path={"/view-applied-universities"}>
              <ViewAppliedUniversities />
            </Route>
            <Route path={"/students-choice"}>
              <StudentsChoice />
            </Route>
            <Route path={"/view-students-choice"}>
              <ViewStudentsChoice />
            </Route>
            <Route path="/registrations/details">
              <UserDetailsLayout />
            </Route>
            <Route path="/registrations">
              <RegistrationHome />
            </Route>
            {/* <Route path="/registrations">
            <ConsultantUsers />
          </Route> */}
            <Route path="/dashboard">
              <ConsultantPanel />
            </Route>
          </Switch>
        </>
      )}
    </>
  );
}

export default Routes;
