import { combineReducers, configureStore } from '@reduxjs/toolkit'

import eavFormReducer from './reducers/eavFormSlice'

const store = configureStore({
	reducer: {
		eavForm: eavFormReducer,
	},
})

export default store
