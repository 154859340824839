import { Box, CircularProgress, Typography } from "@material-ui/core";
import PublishOutlinedIcon from "@material-ui/icons/PublishOutlined";
import { useState } from "react";

function CustomDataUpload({ name, onChange, ...props }) {
  let [fileName, setFileName] = useState(null);

  function handleChange(e) {
    if (e.target.files[0]) {
      setFileName(e.target.files[0].name);
      onChange(e.target.files[0]);
    }
  }

  return (
    <>
      <label htmlFor={name}>
        <Box
          borderRadius={8}
          textAlign="center"
          style={{ cursor: "pointer" }}
          py={2}
          width="100%"
          border="1px dotted rgba(0,0,0,0.4)"
        >
          <PublishOutlinedIcon color="disabled" />
          <Typography variant="body2">
            {fileName ? fileName : "Tap to upload"}
          </Typography>
        </Box>
      </label>
      <input
        onChange={handleChange}
        type="file"
        style={{ display: "none" }}
        id={name}
        {...props}
      />
    </>
  );
}

export default CustomDataUpload;
