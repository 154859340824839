import { styled, Typography } from "@material-ui/core";

export const BoldTypo = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  fontSize: "1rem",
}));

// export const BoldTypo = styled(Typography)(
//   ({ theme }, variant = "h5") => (
//     {
//       fontWeight: "bold",
//       fontSize: "1rem",
//     },
//     [variant]
//   )
// );
