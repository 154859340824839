export const TOPICS = [
	{ name: "Current Affair" },
	{ name: "World Affairs" },
	{ name: "Country Affair" },
	{ name: "Advices" },
	{ name: "Warnings" },
];

export const DOCUMENTS = [
	"LOR",
	"SOP",
	"10th Class",
	"10+2",
	"UG",
	"Transcripts",
	"Passport",
];
