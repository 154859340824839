import React from "react";
import JourneyAnalyticsTable from "../../components/JourneyAnalyticsTable";

function FlightDetails() {
  return (
    <JourneyAnalyticsTable status={"FLIGHT_DETAILS"} title={"Flight Details"} />
  );
}

export default FlightDetails;
