import { Box, IconButton, makeStyles, useTheme } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { http } from "../../services/http";
import {
  CardsContainer,
  CourseCard,
  FlexBox,
  ScoreCards,
  Stats,
} from "../container";
import SearchField from "../universities/SearchField";
import CourseActions from "./CourseActions";
import ShortlistDialog from "./DialogComponent";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { BoldTypo } from "../Typography/BoldTypo";
import { LightTypo } from "../Typography/LightTypo";
import { useQuery } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { useQueryClient } from "@tanstack/react-query";
import NoData from "../NoData";
import EmptyScreen from "../EmptyScreen";
import { CircularProgress } from "@mui/material";
const initState = {
  universityName: "",
  courseName: "",
  intake: [],
  status: "SHORT_LISTED_UNIVERSITIES",
  approvalType: "",
  chancesOfApproval: null,
  comments: null,
};
export default function ShortlistedCourses({ univId, userId, type }) {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [shortlistBody, setShortlistBody] = useState(initState);

  let { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const theme = useTheme();
  const classes = useStyles();

  const handleClickOpen = (obj) => {
    console.log(obj);

    setShortlistBody({ ...shortlistBody, ...obj });
    setOpen(true);
    console.log(shortlistBody);
  };

  const handleChange = (field, value) => {
    setShortlistBody({ ...shortlistBody, [field]: value });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const addToShortlist = () => {
    if (
      !shortlistBody?.approvalType ||
      !shortlistBody?.chancesOfApproval ||
      shortlistBody?.chancesOfApproval === 0
    ) {
      setOpen(true);
      enqueueSnackbar("Please fill the required fields", { variant: "error" });
    } else {
      http
        .put(`/shortlist/add-to-shortlist/user/${userId}`, {
          ...shortlistBody,
          userId,
        })
        .then((res) => {
          queryClient.invalidateQueries("shortlisted");
          setShortlistBody(initState);
        })
        .catch((err) =>
          enqueueSnackbar(err?.response?.data?.message, {
            variant: "error",
          })
        );
    }
  };

  const onDelete = (id) => {
    http.delete(`/shortlist/${id}`).then((res) => {
      enqueueSnackbar(res?.data?.message, {
        variant: "success",
      });
      queryClient.invalidateQueries("shortlisted");
    });
  };

  // useEffect(() => {
  //   getShortlistedCourses();
  // }, []);

  // async function getShortlistedCourses() {
  //   try {
  //     await ;
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }

  const { data: shortlist, isLoading } = useQuery(
    ["shortlisted"],
    () =>
      http.get(`/shortlist/user/${userId}`).then((res) => {
        console.log(res);
        return res?.data;
      }),
    {
      onSuccess: (data) => {
        console.log(data);
        setData(data);
      },
    }
  );

  console.log(data);
  if (isLoading) {
    return (
      <Box mt={10} textAlign="center">
        <CircularProgress />
      </Box>
    );
  }
  return (
    <>
      <CardsContainer
        color={theme.palette.primary.main}
        title={"Shortlisted Courses"}
      >
        <>
          {shortlist?.length ? (
            shortlist?.map((univ, index) => {
              return (
                <>
                  <FlexBox style={{ margin: "1rem" }}>
                    <img
                      src={univ?.university?.logoUrl}
                      style={{
                        width: "100px",
                        borderRadius: "10px",
                        boxShadow: "0px 3px 5px #273B801A",
                      }}
                      alt={univ?.university?.name}
                    />
                    <Box style={{ padding: "1rem" }}>
                      <BoldTypo color="primary">
                        {univ?.university?.name}
                      </BoldTypo>
                      <LightTypo>
                        {univ?.university?.city +
                          ", " +
                          univ?.university?.country}{" "}
                      </LightTypo>
                    </Box>
                  </FlexBox>
                  {univ?.shortlistCourses?.map((course, index) => {
                    return (
                      <CourseCard>
                        <CourseActions courseName={course?.course?.name}>
                          {/* <IconButton onClick={handleClickOpen}> */}
                          {type !== "viewShortlisted" && (
                            <>
                              <EditOutlinedIcon
                                onClick={() =>
                                  handleClickOpen({
                                    universityName: univ.universityName,
                                    universityId: univ.universityId,
                                    courseName: course.course.name,
                                    courseId: course.course.id,
                                    intake: course.course.intake.split(", "),
                                    approvalType: course.approvalType,
                                    chancesOfApproval: course.chancesOfApproval,
                                    comments: course.comments,
                                  })
                                }
                                style={{
                                  ":hover": {
                                    color: "red",
                                  },
                                  cursor: "pointer",
                                }}
                                className={classes?.icon}
                                color="secondary"
                              />
                              <DeleteOutlinedIcon
                                onClick={() => onDelete(course?.id)}
                                style={{
                                  cursor: "pointer",
                                }}
                                color="secondary"
                              />
                            </>
                          )}
                        </CourseActions>

                        <FlexBox
                          style={{ marginBottom: "1rem", gap: "2.5rem" }}
                        >
                          <Stats
                            title="Duration"
                            value={`${course?.course?.duration} ${course?.course?.duration == 1 ? "Year" : "Years"}`}
                          />
                          <Stats
                            title="Approximate fees"
                            value={`${course?.course?.tutionFee} ${univ?.university?.currency}`}
                          />
                          <Stats
                            title="Approval Type"
                            value={course?.approvalType}
                          />
                          <Stats
                            title="Chances of Approval"
                            value={`${course?.chancesOfApproval} %`}
                          />
                        </FlexBox>
                        <Box>
                          <LightTypo>Exam Scores Accepted</LightTypo>
                          <FlexBox>
                            <ScoreCards
                              title="GRE"
                              score={course?.course?.gre}
                            />
                            <ScoreCards
                              title="IELTS"
                              score={course?.course?.ielts}
                            />
                            <ScoreCards
                              title="TOEFL"
                              score={course?.course?.toefl}
                            />
                          </FlexBox>
                        </Box>
                        <Stats
                          title="Comments"
                          value={course?.comments ? course?.comments : "NA"}
                        />
                      </CourseCard>
                    );
                  })}
                </>
              );
            })
          ) : (
            <Box sx={{ marginTop: "8rem" }}>
              <EmptyScreen heading="No Shortlisted Courses" />
            </Box>
          )}
        </>
        <ShortlistDialog
          addToShortlist={addToShortlist}
          handleClose={handleClose}
          open={open}
          handleChange={handleChange}
          shortlistBody={shortlistBody}
        />
      </CardsContainer>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  icon: {
    "&:hover": {
      color: theme.palette.secondary.main,
      backgoundColor: "#FFFF00",
    },
  },
}));
