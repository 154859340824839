import React, { useState } from "react";
import { useTheme, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { useQueryClient } from "@tanstack/react-query";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  NativeSelect,
  Select,
  TextField,
} from "@material-ui/core";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <Button
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          style={{ fontSize: "16px" }}
        >
          Cancel
        </Button>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const initState = {
  approvalType: "",
  chancesOfApproval: "",
  comments: null,
};
export default function ShortlistDialog({
  courseId,
  univId,
  handleClose,
  open,
  handleChange,
  addToShortlist,
  shortlistBody,
}) {
  const [state, setState] = useState(shortlistBody);
  const queryClient = useQueryClient();
  console.log(shortlistBody);
  const handleUpdate = (field, value) => {
    setState({ ...state, [field]: value });
    handleChange(field, value);
    console.log(field, value);
  };
  console.log(state);
  const handleDialogClose = () => {
    handleClose();
    setState(initState);
  };
  const handleSubmit = () => {
    handleClose();
    addToShortlist();
    setState({});
  };
  return (
    <div>
      <Dialog
        onClose={handleDialogClose}
        aria-labelledby="simple-dialog-title"
        open={open}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle id="simple-dialog-title" onClose={handleDialogClose}>
          Shortlist Details
        </DialogTitle>
        <DialogContent dividers>
          <Box style={{ display: "grid", gap: "1rem" }}>
            <Box style={{ display: "grid", gap: "0.5rem" }}>
              <InputLabel htmlFor="">
                Select Approval Type<span style={{ color: "red" }}>*</span>
              </InputLabel>
              <FormControl
                variant="filled"
                size="small"
                style={{ width: "100%" }}
              >
                <Select
                  variant="outlined"
                  id="approval"
                  value={shortlistBody?.approvalType}
                  displayEmpty
                  defaultValue={"Select"}
                  required
                  onChange={(e) => handleUpdate("approvalType", e.target.value)}
                >
                  <MenuItem value="Select" disabled selected>
                    Select
                  </MenuItem>

                  <MenuItem value="SAFE">Safe</MenuItem>
                  <MenuItem value="MODERATE">Moderate</MenuItem>
                  <MenuItem value="AMBIGUOUS">Ambiguous</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ display: "grid", gap: "0.5rem" }}>
              <InputLabel htmlFor="select">
                Enter Chances of Approval<span style={{ color: "red" }}>*</span>
              </InputLabel>
              <TextField
                variant="outlined"
                size="small"
                value={shortlistBody?.chancesOfApproval}
                required
                type="number"
                inputProps={{ min: 0 }}
                onChange={(e) =>
                  handleUpdate("chancesOfApproval", e.target.value)
                }
              ></TextField>
            </Box>
            <Box>
              <TextField
                variant="outlined"
                multiline
                value={shortlistBody?.comments}
                onChange={(e) => handleUpdate("comments", e.target.value)}
                rows={4}
                style={{ width: "100%" }}
                placeholder={"Write your comments..."}
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleSubmit}
            color="secondary"
            variant="contained"
            style={{ margin: "auto" }}
          >
            Save changes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
