import { Box, IconButton, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import GetAppIcon from "@material-ui/icons/GetApp";
import React from "react";
import Accordian from "../../../components/Accordian";
const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
}));

export default function AppliedDocs({ data, id }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");
  function handleClick(id) {
    console.log(id);
  }
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMaxWidthChange = (event) => {
    setMaxWidth(event.target.value);
  };

  const handleFullWidthChange = (event) => {
    setFullWidth(event.target.checked);
  };

  return (
    <React.Fragment>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => {
          handleClickOpen();
          handleClick(id);
        }}
        style={{ position: "absolute", top: "80px", left: "85%" }}
      >
        User Docs
      </Button>
      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">Optional sizes</DialogTitle>
        <DialogContent>
          <div
            style={{
              display: "flex",
              gap: "2rem",
              flexDirection: "column",
            }}
          >
            {Documents.map((item) => {
              return (
                <>
                  <Accordian>
                    <Accordian.Header>{item.title}</Accordian.Header>
                    <Accordian.Body>
                      <Box>
                        {item.type == "array" ? (
                          data[item.doc].map((file, i) => {
                            return <File name={file} url={data[item.url][i]} />;
                          })
                        ) : (
                          <File name={data[item.doc]} url={data[item.url]} />
                        )}
                      </Box>
                    </Accordian.Body>
                  </Accordian>
                  {/* <Accordian></Accordian> */}
                </>
              );
            })}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

const Documents = [
  {
    title: "GRE Score",
    doc: "greScore",
    url: "greScoreUrl",
    type: "string",
  },
  {
    title: "LOR",
    doc: "lors",
    url: "lorsUrl",
    type: "array",
  },
  {
    title: "SOP",
    doc: "sops",
    url: "sopsUrl",
    type: "array",
  },
];

const File = ({ name, index, url }) => {
  return (
    <Box
      sx={{
        display: "flex",
        paddingLeft: "10px",
        flexGrow: "1",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <DescriptionOutlinedIcon />
        <Typography variant="subtitle2">{name}</Typography>
      </Box>
      <Box
        sx={{
          //   border: "1px solid black",
          display: "flex",
          alignItems: "end",
        }}
      >
        <a href={url} download={name}>
          <IconButton>
            <GetAppIcon style={{ color: "#D3264C" }} />
          </IconButton>
        </a>

        {/* <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          //   onClick={handleClick}
        >
          <DeleteIcon style={{ color: "#D3264C" }} />
        </IconButton> */}
      </Box>
    </Box>
  );
};
