import { Box, IconButton, Typography } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import GetAppIcon from "@material-ui/icons/GetApp";
import React from "react";
import { documentStore } from ".";
import { http } from "../../../services/http";
export const SingleDoc = ({ name, url, value, docs, type }) => {
  const docsObject = documentStore.useState((s) => s.docsObject);
  const state = documentStore.useState((s) => s.state);
  const userId = documentStore.useState((s) => s.userId);
  const getDocsData = documentStore.useState((s) => s.getDocsData);
  function handleDelete() {
    let bodyObj = {};
    for (var i = 0; i < docsObject.length; i++) {
      bodyObj[docsObject[i].docs] = state[docsObject[i].docs];
    }
    if (type == "array") {
      let newArr = [...bodyObj[docs]];
      // console.log(newArr, "new Arr");
      newArr = newArr.filter((doc) => {
        if (!(doc == name)) {
          return doc;
        }
      });
      // console.log(name, "name");
      // console.log("filtered new Array", newArr);
      bodyObj[docs] = newArr;
      // console.log("copied body Obj", bodyObj);
    } else {
      bodyObj[docs] = null;
    }

    // console.log(bodyObj[docs], "bodyObj   " + name);
    // console.log(bodyObj);

    documentStore.update((s) => {
      getDocsData();
      s.state = bodyObj;
    });

    http
      .post(`/user-documents/${userId}`, {
        id: userId,
        ...bodyObj,
      })
      .then((res) => {
        getDocsData();
        console.log(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  return (
    <Box
      sx={{
        display: "flex",
        paddingLeft: "10px",
        // border: "1px solid black",
        flexGrow: "1",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <DescriptionOutlinedIcon />
        <Typography variant="subtitle2">{name}</Typography>
      </Box>
      <Box
        sx={{
          //   border: "1px solid black",
          display: "flex",
          alignItems: "end",
        }}
      >
        <a href={url} download={name}>
          <IconButton>
            <GetAppIcon style={{ color: "#D3264C" }} />
          </IconButton>
        </a>

        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleDelete}
        >
          <DeleteIcon style={{ color: "#D3264C" }} />
        </IconButton>
      </Box>
    </Box>
  );
};
