import { Box, Button, CircularProgress } from '@material-ui/core'
import MaterialTable from 'material-table'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { http } from '../../services/http'

const Languages = () => {
	const history = useHistory()
	let { enqueueSnackbar } = useSnackbar()

	const [data, setData] = useState(null)
	const [loading, setLoading] = useState(false)

	useEffect(() => {

		getData()
	}, [])

	const getData = async () => {
		setLoading(true)
		try {
			const res = await http.get('/language')
			setData(res.data)
			setLoading(false)
		} catch (err) {
			enqueueSnackbar('Error Loading data', { variant: 'error' })
		}
	}

	const handleEdit = (rowData) => {
		console.log(rowData)
		history.push({ pathname: `/languages/form`, state: rowData })
	}
	const handleDelete = (id) => {
		let isConfirm = window.confirm('Are you sure about Deleting it?')
		isConfirm && deleteColumn(id)
		return
	}

	const deleteColumn = async (id) => {
		try {
			const res = await http.delete(`/language/${id}`)
			if (res.status === 200) {
				enqueueSnackbar('Deleted Successfully', { variant: 'success' })
				getData()
			}
		} catch (err) {
			enqueueSnackbar('Error Deleting', { variant: 'error' })
		}
	}

	if (loading) return <CircularProgress />
	return (
		<div>
			<Box>
				<Box mb={2} textAlign='right'>
					<Button
						onClick={() => history.push('/languages/form')}
						color='secondary'
						variant='contained'
					>
						Add
					</Button>
				</Box>
				<MaterialTable
					title='Languages'
					options={{
						actionsColumnIndex: -1,
					}}
					actions={[
						{
							icon: 'edit',
							onClick: (event, rowData) => {
								console.log(rowData)
								handleEdit(rowData)
							},
						},
						{
							icon: 'delete',
							onClick: (event, rowData) => {
								console.log(rowData)
								handleDelete(rowData.id)
							},
						},
					]}
					data={data}
					columns={[
						{ title: 'Name', field: 'name' },
						{
							title: 'Date',
							field: 'createdAt',
							render: (rowData) =>
								moment(rowData.createdAt).format('YYYY-MM-DD'),
						},
					]}
				/>
			</Box>
		</div>
	)
}

export default Languages
