import {LinearProgress, Box} from '@material-ui/core'
import {useEffect, useState} from 'react'
import Accordian from '../../../../components/Accordian'
import {http} from '../../../../services/http'
import Form from './Form'

const EavFormList = () => {
	const userId = new URLSearchParams(window.location.search).get('userId')
	const [data, setData] = useState(null)
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		setLoading(true)
		getData()
	}, [])

	const getData = async () => {
		const url = userId ? `/eav/${userId}/type-form` : `/eav?type=form`
		try {
			const res = await http.get(url)
			setData(res.data)
			setLoading(false)
		} catch (err) {
			setLoading(false)
		}
	}

	if (loading) return <LinearProgress />

	return (
		<div>
			{data?.map((form, eavFormIndex) => (
				<Box mb={3}>
					<Accordian>
						<Accordian.Header>{form?.name}</Accordian.Header>
						<Accordian.Body>
							<Form form={form} key={eavFormIndex} userId={userId} />
						</Accordian.Body>
					</Accordian>
				</Box>
			))}
		</div>
	)
}

export default EavFormList
