import { Box, Button, Paper } from "@mui/material";
import { ArrowRightAlt } from "@mui/icons-material";
import UnderLineHeading from "../../components/UnderLineHeading";
import { useRouter } from "react-router-dom";
import { LightTypo } from "../../components/Typography/LightTypo";
import { BoldTypo } from "../../components/Typography/BoldTypo";

const LanguageCard = ({ data }) => {
  let results;
  let countries = [];
  const spokenCommonly = data?.countriesData.map((item) => {
    return item?.name;
  });

  if (spokenCommonly.length > 3) {
    const numsPerGroup = Math.ceil(spokenCommonly.length / 5);
    // Create array based on number of groups
    results = new Array(5)
      // Make sure each element isn't empty
      .fill("")
      // For each group, grab the right `slice` of the input array
      .map((_, i) =>
        spokenCommonly.slice(i * numsPerGroup, (i + 1) * numsPerGroup)
      );

    for (let i = 0; i < results.length; i++) {
      countries.push(results[i].join(","));
    }
  } else {
    countries.push(spokenCommonly.join(","));
  }

  // const countries = spokenCommonly.join(",");
  // console.log(countries.length);

  return (
    <Paper
      p={2}
      minHeight="100px"
      elevation={2}
      style={{
        fontWeight: "bold",
        borderRadius: "15px",
        padding: "20px",
      }}
    >
      <Box>
        <BoldTypo>{data?.name}</BoldTypo>
        <UnderLineHeading />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "-0.6rem",
          }}
        >
          <Box>
            <Box>
              <LightTypo>National language of</LightTypo>
              <BoldTypo>{data?.countryName || "---"}</BoldTypo>
            </Box>
            <Box sx={{ marginTop: "1rem" }}>
              <LightTypo>Number of students</LightTypo>
              <BoldTypo>{data?.studentCount}</BoldTypo>
            </Box>
          </Box>
          <Box sx={{ marginTop: "-3rem" }}>
            <LightTypo>Commonly spoken in</LightTypo>
            {countries.length > 1 ? (
              countries.map((item) => {
                return <BoldTypo>{item}</BoldTypo>;
              })
            ) : (
              <BoldTypo>{countries}</BoldTypo>
            )}
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

export default LanguageCard;
