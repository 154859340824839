import React from "react";
import JourneyAnalyticsTable from "../../components/JourneyAnalyticsTable";

function StudntsChoice() {
  return (
    <JourneyAnalyticsTable
      status={"STUDENTS_CHOICE"}
      title={"Student's Choice"}
    />
  );
}

export default StudntsChoice;
