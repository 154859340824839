import {
  Box,
  CircularProgress,
  IconButton,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { http } from "../../services/http";
import {
  CardsContainer,
  CourseCard,
  FlexBox,
  ScoreCards,
  Stats,
} from "../container";
import SearchField from "../universities/SearchField";
import SOPLORDialog from "./DialogComponent";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { BoldTypo } from "../Typography/BoldTypo";
import { LightTypo } from "../Typography/LightTypo";
import { useQuery } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { useQueryClient } from "@tanstack/react-query";
import { OutlinedButton } from "../OutlinedButton";
import EmptyScreen from "../EmptyScreen";
const initState = {
  shortlistCourseId: null,
  sopDoc: false,
  lorDoc: false,
  status: "",
};
export default function SOPandLORstatus() {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [shortlistBody, setShortlistBody] = useState(initState);
  const userId = new URLSearchParams(window.location.search).get("userId");

  let { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const theme = useTheme();
  const classes = useStyles();

  const handleClickOpen = (obj) => {
    console.log(obj);

    setShortlistBody({ ...shortlistBody, ...obj });
    setOpen(true);
    console.log(shortlistBody);
  };

  const handleChange = (field, value) => {
    setShortlistBody({ ...shortlistBody, [field]: value });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const updateSOPLORStatus = () => {
    http
      .put(`/shortlist/sop-lor-status/user/${userId}`, {
        ...shortlistBody,
        userId,
      })
      .then((res) => {
        queryClient.invalidateQueries("shortlisted");
      });
  };

  // useEffect(() => {
  //   getShortlistedCourses();
  // }, []);

  // async function getShortlistedCourses() {
  //   try {
  //     await ;
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }

  const { data: shortlist, isLoading } = useQuery(
    ["shortlisted"],
    () =>
      http.get(`/shortlist/user/${userId}`).then((res) => {
        console.log(res);
        return res?.data;
      }),
    {
      onSuccess: (data) => {
        setData(data);
      },
    }
  );
  console.log(data);
  if (isLoading) {
    return (
      <Box mt={10} textAlign="center">
        <CircularProgress />
      </Box>
    );
  }
  return (
    <>
      <CardsContainer
        color={theme.palette.primary.main}
        title={"SOP & LOR Document Status"}
      >
        <>
          {shortlist?.length ? (
            shortlist?.map((univ, index) => {
              return (
                <>
                  <FlexBox style={{ margin: "1rem" }}>
                    <img
                      src={univ?.university?.logoUrl}
                      style={{
                        width: "100px",
                        borderRadius: "10px",
                        boxShadow: "0px 3px 5px #273B801A",
                      }}
                      alt={univ?.university?.name}
                    />
                    <Box style={{ padding: "1rem" }}>
                      <BoldTypo color="primary">
                        {univ?.university?.name}
                      </BoldTypo>
                      <LightTypo>
                        {univ?.university?.city +
                          ", " +
                          univ?.university?.country}{" "}
                      </LightTypo>
                    </Box>
                  </FlexBox>
                  {univ?.shortlistCourses?.map((course, index) => {
                    return (
                      <CourseCard>
                        <FlexBox style={{ justifyContent: "space-between" }}>
                          <Box>
                            <BoldTypo color="primary">
                              {course.courseName}
                            </BoldTypo>
                          </Box>
                          <Box>
                            {
                              <OutlinedButton
                                onClick={() =>
                                  handleClickOpen({
                                    shortlistCourseId: course?.id,
                                    status: "SOP_LOR_DOCS",
                                    sopDoc: course?.isSopDoc,
                                    lorDoc: course?.isLorDoc,
                                  })
                                }
                                // style={{ height: "fit-content", margin: "auto" }}
                              >
                                {"Update SOP & LOR status"}
                              </OutlinedButton>
                            }
                          </Box>
                        </FlexBox>

                        <FlexBox
                          style={{
                            marginBottom: "1rem",
                            gap: "2.5rem",
                            marginTop: "1rem",
                          }}
                        >
                          <Stats
                            title="Duration"
                            value={`${course?.course?.duration} ${course?.course?.duration == 1 ? "Year" : "Years"}`}
                          />
                          <Stats
                            title="Approximate fees"
                            value={`${course?.course?.tutionFee} ${univ?.university?.currency}`}
                          />
                          <Stats
                            title="Approval Type"
                            value={course?.approvalType}
                          />
                          <Stats
                            title="Chances of Approval"
                            value={`${course?.chancesOfApproval} %`}
                          />
                          <Stats
                            title="SOP Document Status"
                            value={`${
                              course?.isSopDoc ? "Collected" : "Not Yet"
                            } `}
                          />
                          <Stats
                            title="LOR Document Status"
                            value={`${
                              course?.isLorDoc ? "Collected" : "Not Yet"
                            } `}
                          />
                        </FlexBox>
                        <Box>
                          <LightTypo>Exam Scores Accepted</LightTypo>
                          <FlexBox>
                            <ScoreCards
                              title="GRE"
                              score={course?.course?.gre}
                            />
                            <ScoreCards
                              title="IELTS"
                              score={course?.course?.ielts}
                            />
                            <ScoreCards
                              title="TOEFL"
                              score={course?.course?.toefl}
                            />
                          </FlexBox>
                        </Box>
                        <Stats
                          title="Comments"
                          value={course?.comments ? course?.comments : "NA"}
                        />
                      </CourseCard>
                    );
                  })}
                </>
              );
            })
          ) : (
            <EmptyScreen heading={"No records found"} />
          )}
        </>
        <SOPLORDialog
          updateSOPLORStatus={updateSOPLORStatus}
          handleClose={handleClose}
          open={open}
          handleChange={handleChange}
          shortlistBody={shortlistBody}
        />
      </CardsContainer>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  icon: {
    "&:hover": {
      color: theme.palette.secondary.main,
      backgoundColor: "#FFFF00",
    },
  },
}));
