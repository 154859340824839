import React, { useState } from "react";
import { useTheme, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { useQueryClient } from "@tanstack/react-query";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  NativeSelect,
  Select,
  TextField,
} from "@material-ui/core";
import { InputAdornment } from "@mui/material";
import moment from "moment";
import { useHistory } from "react-router-dom";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <Button
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          style={{ fontSize: "16px" }}
        >
          Cancel
        </Button>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const initState = {
  appliedCourseId: null,
  studentsChoice: false,
  intake: [],
  status: "",
};
export default function StudentsChoiceDialog({
  courseId,
  univId,
  handleClose,
  open,
  handleChange,
  updateStudentsChoice,
  shortlistBody,
  isUpdate,
}) {
  const [state, setState] = useState(shortlistBody);
  const queryClient = useQueryClient();
  const router = useHistory();
  const userId = new URLSearchParams(window.location.search).get("userId");

  const handleUpdate = (field, value) => {
    setState({ ...state, [field]: value });
    handleChange(field, value);
    console.log(field, value);
  };
  console.log(state);
  const handleDialogClose = () => {
    handleClose();
    setState(initState);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    handleClose();
    updateStudentsChoice();
    router.push(`/view-students-choice?userId=${userId}`);
  };
  return (
    <div>
      <Dialog
        onClose={handleDialogClose}
        aria-labelledby="simple-dialog-title"
        open={open}
        maxWidth="xs"
        fullWidth
      >
        <form onSubmit={handleSubmit}>
          <DialogTitle id="simple-dialog-title" onClose={handleDialogClose}>
            Update student’s choice
          </DialogTitle>
          <DialogContent dividers>
            <Box style={{ display: "grid", gap: "1rem" }}>
              <Box style={{ display: "grid", gap: "0.5rem" }}>
                <InputLabel htmlFor="">Is this student’s choice?</InputLabel>
                <FormControl
                  variant="filled"
                  size="small"
                  style={{ width: "100%" }}
                >
                  <Select
                    variant="outlined"
                    id="adDecision"
                    value={state?.studentsChoice}
                    onChange={(e) =>
                      handleUpdate("studentsChoice", e.target.value)
                    }
                  >
                    <MenuItem value="Select" disabled selected>
                      Select
                    </MenuItem>
                    <MenuItem value={true}>Yes</MenuItem>
                    <MenuItem value={false}>No</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box style={{ display: "grid", gap: "0.5rem" }}>
                <InputLabel htmlFor="">
                  Select Intake<span style={{ color: "red" }}>*</span>
                </InputLabel>
                <FormControl
                  variant="filled"
                  size="small"
                  style={{ width: "100%" }}
                >
                  <Select
                    variant="outlined"
                    id="adDecision"
                    value={state?.intake}
                    onChange={(e) => handleUpdate("intake", e.target.value)}
                    required
                  >
                    <MenuItem value="Select" disabled selected>
                      Select
                    </MenuItem>
                    <MenuItem value={"Spring"}>Spring</MenuItem>
                    <MenuItem value={"Fall"}>Fall</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={handleSubmit}
              color="secondary"
              variant="contained"
              style={{ margin: "auto" }}
            >
              {isUpdate ? "Update" : "Submit"}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
