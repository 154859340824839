import {useEffect, useState} from 'react'
import {
	Box,
	Button,
	Checkbox,
	Dialog,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	IconButton,
	MenuItem,
	Select,
	TextField,
	Typography,
	useTheme,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import {http} from '../../services/http'

const attributeTypes = [
	{
		id: 1,
		label: 'Text Field',
		key: 'varchar',
		hasOptions: false,
		frontEndType: 'text',
	},
	{
		id: 2,
		label: 'Multiline Text Field',
		key: 'text',
		hasOptions: false,
		frontEndType: 'text_multiline',
	},
	{
		id: 3,
		label: 'Drop Down',
		key: 'int',
		hasOptions: true,
		frontEndType: 'select',
	},
	{
		id: 4,
		label: 'File Upload',
		key: 'text',
		hasOptions: false,
		frontEndType: 'file_upload',
	},
]

const AddAttribute = ({formData, open, setOpen, refresh}) => {
	const theme = useTheme()

	const [state, setState] = useState({
		attributeType: 'varchar',
		hasOptions: false,
		parent: formData?.id,
		name: '',
		isUnique: false,
		isRequired: false,
		label: '',
		frontEndType: 'text',
		upload: false,
		mobileVisibility: false,
	})

	const [options, setOptions] = useState([])

	useEffect(() => {
		setState((prev) => ({...prev, parent: formData?.id}))
	}, [formData])

	const handleChange = (e) => {
		setState((prev) => ({
			...prev,
			[e.target.name]: e.target.value,
		}))
	}

	const handleSubmit = (e) => {
		e.preventDefault()
		const postBody = [
			{
				name: state.label,
				type: state.attributeType,
				isRequired: state.isRequired,
				isUnique: state.isUnique,
				hasOptions: state.hasOptions,
				entityTypeId: state.parent,
				upload: state.upload,
				frontEndType: state.frontEndType,
				options,
			},
		]
		postData(postBody)
	}

	const postData = async (data) => {
		try {
			const res = await http.post('/eav/attribute', data)
			refresh()
			res && setOpen(false)
		} catch (error) {}
	}

	return (
		<Dialog
			open={open}
			onClose={() => setOpen(false)}
			fullWidth
			style={{minHeight: '60vh'}}
		>
			<DialogTitle
				style={{background: theme.palette.primary.main, color: 'white'}}
			>
				Add Attribute
			</DialogTitle>
			<DialogContent>
				<form onSubmit={handleSubmit}>
					<Box display='flex' gridGap={20} flexDirection='column' p={2}>
						<TextField
							value={state.label}
							onChange={handleChange}
							name='label'
							placeholder='Attribute Title'
							size='small'
							fullWidth
							variant='outlined'
							required
						/>
						<Box>
							<Select
								fullWidth
								variant='outlined'
								name='attributeType'
								value={state.frontEndType}
								onChange={(e) => {
									setState((prev) => ({
										...prev,
										attributeType: attributeTypes.find(
											(item) => item.frontEndType === e.target.value
										).key,

										frontEndType: e.target.value,
										hasOptions: e.target.value === 'select',
										upload: e.target.value === 'file_upload',
									}))
								}}
							>
								{attributeTypes.map((item, index) => (
									<MenuItem value={item.frontEndType} key={index}>
										{item.label}
									</MenuItem>
								))}
							</Select>
						</Box>

						<Box>
							<FormControlLabel
								control={
									<Checkbox
										checked={state.mobileVisibility}
										onChange={(e) =>
											setState((prev) => ({
												...prev,
												mobileVisibility: e.target.checked,
											}))
										}
										name='mobileVisibility'
										color='primary'
									/>
								}
								label='Mobile Visibility'
							/>
						</Box>

						<Box>
							<FormControlLabel
								control={
									<Checkbox
										checked={state.isRequired}
										onChange={(e) =>
											setState((prev) => ({
												...prev,
												isRequired: e.target.checked,
											}))
										}
										name='isRequired'
										color='primary'
									/>
								}
								label='Required Field'
							/>
						</Box>
						<Box>
							<FormControlLabel
								control={
									<Checkbox
										checked={state.isUnique}
										name='isUnique'
										color='primary'
										onChange={(e) =>
											setState((prev) => ({
												...prev,
												isUnique: e.target.checked,
											}))
										}
									/>
								}
								label='Unique Field'
							/>
						</Box>

						<Box>
							{state.frontEndType === 'select' && (
								<FormControlLabel
									control={
										<Checkbox
											checked={state.hasOptions}
											name='hasOptions'
											color='primary'
										/>
									}
									label='Has Options'
								/>
							)}
						</Box>

						<Box>
							{state.frontEndType === 'file_upload' && (
								<FormControlLabel
									control={
										<Checkbox
											checked={state.upload}
											name='hasOptions'
											color='primary'
										/>
									}
									label='File Upload'
								/>
							)}
						</Box>
						<Box>
							{state.hasOptions && (
								<Options options={options} setOptions={setOptions} />
							)}
						</Box>
						<Box>
							<Button variant='contained' color='secondary' type='submit'>
								Save Attribute
							</Button>
						</Box>
					</Box>
				</form>
			</DialogContent>
		</Dialog>
	)
}

export default AddAttribute

const Options = ({options, setOptions}) => {
	const [option, setOption] = useState('')

	const handleAddNewOption = (e) => {
		e.preventDefault()

		const newOption = {
			value: option,
		}
		option && setOptions((prev) => [...prev, newOption])
		setOption('')
	}

	const handleDeleteOption = (index) => {
		setOptions((prev) => prev.filter((item) => item !== prev[index]))
	}

	return (
		<Box style={{overflowY: 'auto'}}>
			<Box my={2} display='flex' gridGap={20} alignItems='center'>
				<TextField
					value={option}
					fullWidth
					onChange={(e) => setOption(e.target.value)}
					size='small'
					variant='outlined'
					placeholder='Option Title'
				/>
				<Button
					variant='contained'
					onClick={handleAddNewOption}
					color='primary'
					size='small'
				>
					Save
				</Button>
			</Box>
			<ol style={{margin: 0, padding: 0}}>
				{options.length > 0 &&
					options?.map((option, index) => (
						<li
							style={{
								border: '1px solid rgba(0, 0, 0, 0.2)',
								borderRadius: '8px',
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center',
								padding: '3px 1rem',
								marginBottom: '5px',
							}}
						>
							<Typography>{option?.value}</Typography>
							<IconButton
								size='small'
								onClick={() => handleDeleteOption(index)}
							>
								<CloseIcon fontSize='small' />
							</IconButton>
						</li>
					))}
			</ol>
		</Box>
	)
}
