import { Box, Button, FormControl, styled } from "@material-ui/core";

export const SmallBox = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(3,1fr)",
  gap: "1.5rem",
}));

export const WideBox = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "2fr 1fr",
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  border: "2px dashed",
  width: "100%",
  height: "80px",
  opacity: " 30%",
  borderRadius: "0.625rem",
}));

export const GroupCard = styled(SmallBox)(({ theme }) => ({
  boxShadow: "0px 3px 15px #273B8014",
  padding: "1rem",
}));

export const StyledFormControl = styled((props) => (
  <FormControl size="small" variant="outlined" {...props} />
))(({ theme }) => ({
  minWidth: "100%",
}));

export const ItemCard = styled(Box)(({ theme }) => ({
  border: "1px solid #c4c4c4",
  width: "fit-content",
  borderRadius: "0.625rem",
  padding: "0.5rem",
}));
