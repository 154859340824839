import React from "react";
import JourneyAnalyticsTable from "../../components/JourneyAnalyticsTable";

function ProPurchases() {
  return (
    <JourneyAnalyticsTable status={"PRO_PURCHASE"} title={"Pro Purchases"} />
  );
}

export default ProPurchases;
