import {
  Box,
  CircularProgress,
  TextField,
  Typography,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import React, { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { http } from "../../services/http";
import UniversityOverview from "./UniversityOverview";

const initState = {
  details: {},
  webinars: {},
  courses: {},
  id: "",
};

const courseParamsInitState = {
  search: "",
  offset: 0,
  category: "",
  page: 1,
};
export default function UniversityInfo() {
  const router = useHistory();
  const match = useRouteMatch();
  const [data, setData] = useState(initState);
  const slug = match.params.id;
  const [courseParams, setCourseParams] = useState(courseParamsInitState);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (data.id) {
      getCourses();
    }
  }, [courseParams]);
  async function getData() {
    setLoading(true);
    try {
      const details = await http
        .get(`/universities/${slug}`)
        .then((res) => res.data);

      const courses = await http
        .get(`/universities/courses/${details.id}`, {
          params: {
            ...courseParams,
          },
        })
        .then((res) => res.data);
      const webinars = await http
        .get(`/webinars/university/${details.id}/join`)
        .then((res) => res.data);
      setData({ details, courses, webinars, id: details.id });
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  }

  function getCourses() {
    http
      .get(`/universities/courses/${data.id}`, {
        params: {
          ...courseParams,
        },
      })
      .then((res) => {
        setData({ ...data, courses: res.data });
      });
  }
  function handleProgramsSearch(e) {
    setCourseParams({ ...courseParams, search: e.target.value });
  }

  function handlePagination(e, v) {
    setCourseParams({ ...courseParams, page: v, offset: (v - 1) * 5 });
  }

  if (loading) {
    return <CircularProgress></CircularProgress>;
  }
  return (
    <>
      <UniversityOverview {...data?.details} />
      <Box>
        <Typography>Programs</Typography>
        <TextField
          placeholder="Search for a Program"
          variant="outlined"
          size="small"
          fullWidth
          value={courseParams.search}
          onChange={handleProgramsSearch}
          style={{ marginBottom: "1rem" }}
        />
        {data?.courses?.results?.map((course) => (
          <CourseCard {...course} />
        ))}
        <Pagination
          count={Math.ceil(data?.courses?.count / 5)}
          onChange={handlePagination}
          color="secondary"
          page={courseParams.page}
        />
      </Box>
    </>
  );
}

function CourseCard({ name, duration, intake, tutionFee, ielts }) {
  const theme = useTheme();
  return (
    <>
      <Box
        sx={{
          boxShadow: "0px 3px 15px #273b8014",
          padding: "20px",
          borderRadius: "20px",
          cursor: "pointer",
          my: "1rem",
        }}
      >
        <Box>
          <Typography
            color="primary"
            style={{ fontSize: "20px", fontWeight: 400 }}
          >
            {name}
          </Typography>
        </Box>
        <Box
          display={"flex"}
          sx={{
            flexGrow: 1,
            [theme.breakpoints.down("sm")]: {
              display: "grid",
              gridTemplateColumns: "50% 50%",
              // color: "red",
            },
          }}
        >
          <StyledSubHeading title={"Duration"} value={duration} />
          <StyledSubHeading title={"Intakes"} value={intake} />
          <StyledSubHeading title={"Tuition Fees"} value={tutionFee} />
          <StyledSubHeading title={"Score accepted"} value={ielts} />
        </Box>
      </Box>
    </>
  );
}

const StyledSubHeading = ({ title, value }) => {
  return (
    <Box style={{ flexGrow: 1 }}>
      <Typography style={{ fontSize: 12, color: "rgba(0, 0, 0, 0.6)" }}>
        {title}
      </Typography>
      <Typography style={{ fontSize: "14px", fontWeight: 700 }}>
        {value}
      </Typography>
    </Box>
  );
};
//border: "1px solid black"
