import {Box, CircularProgress, Typography} from '@material-ui/core'
import {useEffect, useState} from 'react'
import {http} from '../../services/http'
import MaterialTable from 'material-table'
import {Link} from 'react-router-dom'
import AddAttribute from './AddAttribute'

const Attributes = () => {
	const [data, setData] = useState(null)
	const [loading, setLoading] = useState(false)
	const [selectedForm, setSelectedForm] = useState(null)
	const [open, setOpen] = useState(false)

	useEffect(() => {
		setLoading(false)
		getData()
	}, [])

	const getData = async () => {
		try {
			const res = await http.get('/eav')
			setData(res.data)
			setLoading(false)
		} catch (err) {
			console.log(err)
		}
	}

	const handleAddAttribute = (e, rowData) => {
		setOpen(true)
		setSelectedForm(rowData)
	}

	if (loading || !data) return <CircularProgress />

	return (
		<div>
			<Typography variant='h5' color='primary'>
				Attributes
			</Typography>
			<Box mt={2}>
				<MaterialTable
					title='Forms'
					actions={[
						{
							icon: 'add',
							onClick: (event, rowData) => handleAddAttribute(event, rowData),
						},
					]}
					options={{
						actionsColumnIndex: -1,
					}}
					data={data}
					columns={[
						{
							title: 'Name',
							field: 'name',
							render: (rowData) => (
								<Link to={`/attributes/${rowData.uniqueName}`}>
									{rowData.name}
								</Link>
							),
						},
						{
							title: 'Attributes',
							field: 'attributes',
							render: (rowData) => (
								<div>
									{rowData.attributes.map((item, index, arr) => {
										return (
											<span key={index}>
												{item.name + `${index + 1 < arr.length ? ', ' : ''}`}
											</span>
										)
									})}
								</div>
							),
						},
					]}
				/>
			</Box>
			<AddAttribute
				open={open}
				setOpen={setOpen}
				formData={selectedForm}
				refresh={getData}
			/>
		</div>
	)
}

export default Attributes
