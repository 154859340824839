import { Box, MenuItem, Select, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import moment from "moment";

const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-input": {
      padding: "10px 15px",
    },
  },
});

const FormControl = ({ label, value, name, handleChange, ...props }) => {
  return (
    <Box mb={2}>
      <span>{label}</span>
      <TextField
        variant="outlined"
        size="small"
        value={value}
        name={name}
        type={props?.type}
        required
        onChange={handleChange}
        fullWidth
        style={{ background: "#fafafa" }}
      />
    </Box>
  );
};

export default FormControl;

FormControl.Select = function FormSelect({
  label,
  value,
  name,
  handleChange,
  options,
  ...props
}) {
  const classes = useStyles();
  return (
    <Box mb={2}>
      <span>{label}</span>
      <Select
        className={classes.root}
        variant="outlined"
        value={value}
        name={name}
        required
        fullWidth
        onChange={handleChange}
      >
        {options.map((item, index) => (
          <MenuItem key={index} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

FormControl.Date = function FormControlDate({ label, value, ...props }) {
  const classes = useStyles();
  // const [selectedDate, setSelectedDate] = useState(new Date())

  // const handleDateChange = (e) => {
  // 	setSelectedDate(e.target.value)
  // }

  return (
    <Box mb={2}>
      <span>{label}</span>
      <TextField
        value={moment(value).format("YYYY-MM-DD")}
        className={classes.root}
        variant="outlined"
        fullWidth
        type="date"
        {...props}
      />
    </Box>
  );
};
