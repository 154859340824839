import { Box, MenuItem, Select, Typography } from "@material-ui/core";
import React from "react";
import { LightTypo } from "../Typography/LightTypo";
import {
  GroupCard,
  StyledButton,
  StyledFormControl,
  ItemCard,
} from "./components";
import DeleteIcon from "@material-ui/icons/Delete";

const types = [
  { label: "Country", value: "country" },
  { label: "University", value: "university" },
  { label: "Area of Study", value: "areaOfStudy" },
  { label: "Exam", value: "exam" },
  { label: "Language", value: "language" },
];

export default function SelectGroupCard({
  handleChange,
  setOpen,
  value,
  required,
  typeId,
  typeData,
}) {
  return (
    <Box>
      <Typography color="primary" style={{ marginBottom: "1rem" }}>
        Select the group to post your webinar
      </Typography>
      <GroupCard>
        <Box>
          <Box>
            <LightTypo>
              Group Type{required && <span style={{ color: "red" }}>*</span>}
            </LightTypo>
            <StyledFormControl>
              <Select
                value={value}
                style={{ borderRadius: "0.625rem" }}
                onChange={(e) => {
                  handleChange("type", e.target.value);
                }}
                required={required}
              >
                {types.map((type) => (
                  <MenuItem key={type.value} value={type.value}>
                    {type.label}
                  </MenuItem>
                ))}
              </Select>
            </StyledFormControl>
          </Box>
          <Box>
            <LightTypo>
              Groups{required && <span style={{ color: "red" }}>*</span>}
            </LightTypo>
            {typeData?.name && (
              <>
                <ItemCard>{typeData?.name}</ItemCard>
                <br />
              </>
            )}
            <StyledButton variant="outlined" onClick={() => setOpen(true)}>
              + Select Group
            </StyledButton>
          </Box>
        </Box>
      </GroupCard>
    </Box>
  );
}
