import { Collapse, IconButton } from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { styled } from "@material-ui/styles";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { profileStore } from ".";
import { menu } from "./menu";
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  toolbar: theme.mixins.toolbar,
}));

function LayoutDrawer() {
  const classes = useStyles();
  const roles = profileStore.useState((s) => s.roles.map((role) => role.name));
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");

  console.log();
  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
      // anchor="left"
    >
      <div className={classes.toolbar} />
      {/* <Divider /> */}
      <List>
        {menu
          .filter((item) => {
            if (roles[0] == "ADMIN" || roles[1] == "ADMIN") {
              if (
                item.title != "My Panel" &&
                item.title != "My Registrants" &&
                item.title != "Universities"
              ) {
                return item;
              }
            } else if (
              item.title == "My Panel" ||
              item.title == "My Registrants" ||
              item.title == "Universities"
            ) {
              return item;
            }
          })
          .map((item, index) => (
            <>
              <MenuItem
                item={item}
                selectedItem={selectedItem}
                setSelectedItem={setSelectedItem}
              />
            </>
          ))}
      </List>
    </Drawer>
  );
}

export default LayoutDrawer;

const MenuItem = ({ item, selectedItem, setSelectedItem }) => {
  if (item?.children?.length) {
    return (
      <>
        <ListItem
          button
          onClick={() => {
            // setOpen(!open);
            if (selectedItem == item.title) {
              // selectedItem!=""
              setSelectedItem("");
            } else {
              setSelectedItem(item.title);
            }
          }}
        >
          <img src={item.icon} />
          <ListItemText primary={item.title} />
          {selectedItem === item.title ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={selectedItem === item.title} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {item.children.map((child, index) => (
              <MenuItem item={child} />
            ))}
          </List>
        </Collapse>
      </>
    );
  }
  return (
    <Link key={item.path} to={item.path}>
      <ListItem button key={`${item.path}`}>
        {typeof item.icon == "string" ? (
          <Image src={item.icon} />
        ) : (
          <IconButton style={{ width: "24px", marginRight: "10px" }}>
            {item.icon}
          </IconButton>
        )}
        <ListItemText style={{ color: "black" }} primary={`${item.title}`} />
      </ListItem>
    </Link>
  );
};

const Image = styled("img")(({ theme }) => ({
  width: "24px",
  marginRight: "10px",
}));
