import { Box, Divider, Paper, Typography } from "@mui/material";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import { Link } from "react-router-dom";
import UnderLineHeading from "../../components/UnderLineHeading";
import { BoldTypo } from "../../components/Typography/BoldTypo";
import { LightTypo } from "../../components/Typography/LightTypo";

function ExamCard({ data }) {
  return (
    <Paper
      p={2}
      minHeight="100px"
      elevation={2}
      style={{
        fontWeight: "bold",
        borderRadius: "15px",
        padding: "20px",
      }}
    >
      <BoldTypo color="primary">{data?.name}</BoldTypo>
      <UnderLineHeading title={data?.title} color={"#909090"} />
      <Box style={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <LightTypo>Conducted by</LightTypo>
          <BoldTypo>{data?.conductedBy}</BoldTypo>
        </Box>
        <Box sx={{ marginTop: "0.3rem" }}>
          <LightTypo>Number of Students</LightTypo>
          <BoldTypo>{data?.studentCount}</BoldTypo>
        </Box>
      </Box>
      <div
        style={{
          backgroundColor: "#EEEEEE",
          padding: "12px",
          borderRadius: "15px",
          marginTop: "0.5rem",
        }}
      >
        <BoldTypo color="#273C80">
          {data?.validity ? `Valid for ${data?.validity} Years` : "---"}
        </BoldTypo>
      </div>
    </Paper>
  );
}

export default ExamCard;
