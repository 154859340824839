import { Box, MenuItem, TextField, Typography } from '@material-ui/core'

const Field = ({ data, onChange }) => {
	const { name, type, options, value, isRequired } = data
	return (
		<Box>
			<Typography variant='caption'>{name}</Typography>
			<Typography variant='caption' style={{ color: 'red' }}>
				{isRequired ? ' *' : ''}
			</Typography>
			<RenderInputField
				attributeType={type}
				onChange={(e) => onChange(e.target.value)}
				options={options}
				value={value}
				isRequired={isRequired}
			/>
		</Box>
	)
}

export default Field

export const RenderInputField = ({
	onChange,
	attributeType,
	options,
	value,
	isRequired,
}) => {
	if (attributeType === 'int') {
		return (
			<TextField
				select
				size='small'
				onChange={onChange}
				value={parseInt(value?.value) || -1}
				variant='outlined'
				fullWidth
				required={isRequired}
			>
				{options?.map((option, index) => (
					<MenuItem key={index} value={option.id}>
						{option.value}
					</MenuItem>
				))}
			</TextField>
		)
	}
	if (attributeType !== 'int') {
		return (
			<TextField
				value={value?.value || ''}
				variant='outlined'
				fullWidth
				size='small'
				onChange={onChange}
				required={isRequired}
			/>
		)
	}
}
