import { Box, Button, CircularProgress } from "@material-ui/core";
import MaterialTable from "material-table";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import EditAttributesIcon from "@material-ui/icons/EditAttributes";
import { http } from "../../services/http";
import { useSnackbar } from "notistack";
import { updateCallStatus } from "../../api";

function Leads() {
  const router = useHistory();
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  let { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setLoading(true);
    getData();
  }, []);

  const getData = () => {
    http
      .get("/schedule")
      .then((res) => {
        console.log(res.data);
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  if (loading) return <CircularProgress />;

  return (
    <Box>
      <MaterialTable
        title='Leads'
        options={{
          actionsColumnIndex: -1,
        }}
        data={data}
        columns={[
          { title: "Name", field: "name" },
          { title: "Mobile", field: "mobile" },
          { title: "Email", field: "email" },
          { title: "UG/PG", field: "levelOfStudy" },
          { title: "country", field: "country" },
          { title: "Field Of Study", field: "fieldOfStudy" },
        ]}
      />
    </Box>
  );
}

export default Leads;
