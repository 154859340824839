import { Box, CircularProgress, IconButton } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import { useEffect, useRef, useState } from "react";
import { http } from "../../services/http";

const ImageWithUploadButton = ({ src, name, state, setState, field }) => {
  const inputRef = useRef();
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    setImageUrl(src);
  }, [src]);

  function handleChange(e) {
    if (e.target.files[0]) {
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      setLoading(true);
      http
        .post("/common/upload", formData)
        .then((res) => {
          console.log(res)
          setState({ ...state, [field]: res?.data?.key }); 
          setImageUrl(res.data.Location);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  }

  const handleClick = () => {
    if (inputRef) {
      inputRef.current.click();
    }
  };

  return (
    <Box width="100%">
      <Box
        style={{ position: "relative", maxWidth: "500px", margin: "0 auto" }}
      >
        <Box height="240px" width="auto" margin="auto">
          {loading ? (
            <CircularProgress />
          ) : (
            <img
              style={{ objectFit: "contain", width: "100%", height: "100%" }}
              src={imageUrl}
            />
          )}
        </Box>

        <IconButton
          color="secondary"
          onClick={handleClick}
          style={{
            position: "absolute",
            right: 0,
            top: 0,
            zIndex: 50,
            display: "block",
          }}
        >
          <Edit />
        </IconButton>
      </Box>
      <input ref={inputRef} onChange={handleChange} type="file" id={name} />
    </Box>
  );
};

export default ImageWithUploadButton;
